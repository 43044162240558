// FeaturedCars.js

import React, { useState } from 'react';
import { Grid, Paper, Typography, Button, Box, CircularProgress } from '@mui/material';
import useStyles from './HomePageStyles'; // Reuse styles from HomePageStyles
import CarDetailsModal from '../modals/CarDetailsModal'; // Import the modal
import useFeaturedCars from '../../hooks/useFeaturedCars'; // Import the custom hook

const FeaturedCars = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);

  // Use the custom hook to get the featured cars
  const { cars, loading, error } = useFeaturedCars();

  const handleOpen = (car) => {
    setSelectedCar(car);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  return (
    <Box>
      <Typography variant="h4" className={classes.neonText}>
        Featured Cars
      </Typography>

      {/* Display loading state */}
      {loading ? (
        <Box textAlign="center" marginTop="40px">
          <CircularProgress color="inherit" className={classes.spinner} />
          <Typography variant="h6" className={classes.loadingText}>
            Loading featured cars...
          </Typography>
        </Box>
      ) : error ? (
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      ) : (
        <Grid container spacing={4} style={{ marginTop: '20px' }}>
          {cars.map((car) => (
            <Grid item xs={12} md={4} key={car.id}>
              <Paper sx={{ backgroundColor: 'rgba(17, 17, 17, 0.7)' }} className={classes.featuredCarCard} onClick={() => handleOpen(car)}>
                <img src={car.imageUrls?.[0] || `${process.env.PUBLIC_URL}/logo.png`} alt={car.name} className={classes.carImage} />
                <Typography variant="h6" className={classes.neonText}>
                  {car.make} {car.model}
                </Typography>
                <Typography className={classes.priceText}>{car.pricePerDay ? `$${car.pricePerDay}/day` : 'Price not available'}</Typography>
                <Button className={`${classes.buttonNeon} ${classes.pulsatingButton}`} size="small">
                  Rent Now
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}

      {/* Modal */}
      <CarDetailsModal open={open} handleClose={handleClose} selectedCar={selectedCar} />
    </Box>
  );
};

export default FeaturedCars;


// import React, { useState } from 'react';
// import { Grid, Paper, Typography, Button } from '@mui/material';
// import useStyles from './HomePageStyles'; // Reuse styles from HomePageStyles
// import CarDetailsModal from '../modals/CarDetailsModal'; // Import the modal
// import useFeaturedCars from '../../hooks/useFeaturedCars'; // Import the custom hook

// const FeaturedCars = () => {
//   const classes = useStyles();
//   const [open, setOpen] = useState(false);
//   const [selectedCar, setSelectedCar] = useState(null);

//   // Use the custom hook to get the featured cars
//   const { cars } = useFeaturedCars();

//   const handleOpen = (car) => {
//     setSelectedCar(car);
//     setOpen(true);
//   };

//   const handleClose = () => setOpen(false);

//   return (
//     <div>
//       <Typography variant="h4" className={classes.neonText}>
//         Featured Cars
//       </Typography>
//       <Grid container spacing={4} style={{ marginTop: '20px' }}>
//         {cars.map((car) => (
//           <Grid item xs={12} md={4} key={car.id}>
//             <Paper className={classes.featuredCarCard} onClick={() => handleOpen(car)}>
//               <img src={car.image} alt={car.name} className={classes.carImage} />
//               <Typography variant="h6" className={classes.neonText}>
//                 {car.name}
//               </Typography>
//               <Typography className={classes.priceText}>{car.price}</Typography>
//               <Button className={`${classes.buttonNeon} ${classes.pulsatingButton}`} size="small">
//                 Rent Now
//               </Button>
//             </Paper>
//           </Grid>
//         ))}
//       </Grid>

//       {/* Modal */}
//       <CarDetailsModal open={open} handleClose={handleClose} selectedCar={selectedCar} />
//     </div>
//   );
// };

// export default FeaturedCars;

// BlogPageStyles.js
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  blogContainer: {
    backgroundColor: '#000',
    padding: '40px 20px',
    minHeight: '100vh',
    color: '#fff',
    textAlign: 'center',
  },
  blogTitle: {
    fontSize: '3.5rem',
    color: '#f72585',
    textShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    marginBottom: '30px',
    fontFamily: '"Orbitron", sans-serif',
  },
  blogSubtitle: {
    fontSize: '1.5rem',
    color: '#23d5ab',
    textShadow: '0 0 15px #23d5ab, 0 0 25px #f72585',
    marginBottom: '30px',
    fontFamily: '"Orbitron", sans-serif',
  },
  blogGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // 2 columns per row
    gap: '20px', // Further reduce the gap between the cards to bring them closer
    justifyContent: 'center', // Center the grid
    alignItems: 'center', // Vertically align the items in the grid
    padding: '0 20px',
  },
  blogCard: {
    backgroundColor: '#555',
    borderRadius: '12px',
    width: '350px', // Slightly decrease the card width
    height: '550px', // Slightly decrease the card height
    padding: '10px',
    border: '1px solid #23d5ab',
    boxShadow: '0 0 10px #f72585, 0 0 15px #23d5ab',
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    margin: '0 auto', // Ensure the card is centered within the grid cell
    '&:hover': {
      transform: 'perspective(1000px) rotateY(15deg)',
      boxShadow: '0 0 30px #f72585, 0 0 30px #23d5ab',
    },
  },
  blogImage: {
    width: '100%',
    borderRadius: '8px',
    marginBottom: '8px', // Reduced margin between image and content
    boxShadow: '0 0 10px #23d5ab',
    border: '1px solid #f72585',
  },
  cardTitle: {
    fontSize: '1.0rem !important',
    color: '#23d5ab',
    textShadow: '0 0 8px #23d5ab, 0 0 15px #f72585',
    marginBottom: '6px', // Reduced margin for better spacing
    fontFamily: '"Orbitron", sans-serif',
  },
  cardDescription: {
    fontSize: '0.85rem', // Increased font size for better readability
    color: '#ccc', // Lightened text color for better contrast
    marginBottom: '8px',
    fontFamily: '"Poppins", sans-serif',
  },
  cardDate: {
    color: '#f72585',
    fontSize: '0.8rem', // Slightly increased date font size
    fontStyle: 'italic',
    textShadow: '0 0 6px #f72585',
    marginBottom: '6px',
  },
  readMoreButton: {
    backgroundColor: '#23d5ab',
    color: '#000',
    padding: '6px 12px',
    boxShadow: '0 0 10px #23d5ab',
    fontFamily: '"Orbitron", sans-serif',
    '&:hover': {
      backgroundColor: '#f72585',
      color: '#fff',
      boxShadow: '0 0 20px #f72585',
    },
  },
}));

export default useStyles;

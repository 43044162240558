// useCarListings.js


import { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { realtimeDb } from '../firebase'; // Import Firebase Realtime Database

const useCarListings = () => {
  const [carListings, setCarListings] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchListings = async () => {
      try {
        const listingsRef = ref(realtimeDb, 'listings'); // Reference to the 'listings' in the database
        const snapshot = await get(listingsRef);

        if (snapshot.exists()) {
          const listingsData = snapshot.val();
          // Flattening the listings from the users' keys into one array
          const listings = Object.keys(listingsData).reduce((acc, userId) => {
            const userListings = Object.keys(listingsData[userId]).map(listingId => ({
              id: listingId,
              ...listingsData[userId][listingId],
            }));
            return [...acc, ...userListings];
          }, []);
          
          setCarListings(listings);
        } else {
          setError('No car listings available');
        }

        setLoading(false);
      } catch (err) {
        setError('Failed to load car listings');
        setLoading(false);
      }
    };

    fetchListings();
  }, []);

  return { carListings, loading, error };
};

export default useCarListings;


// import { useState, useEffect } from 'react';
// import cars from '../components/data/listings.json'; // Import the local JSON for now

// const useCarListings = () => {
//   const [carListings, setCarListings] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchListings = async () => {
//       try {
//         // Simulating an async call. Later, this can be replaced with a real API call.
//         await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
//         setCarListings(cars); // Replace with API call later
//         setLoading(false);
//       } catch (err) {
//         setError('Failed to load car listings');
//         setLoading(false);
//       }
//     };

//     fetchListings();
//   }, []);

//   return { carListings, loading, error };
// };

// export default useCarListings;

// App.jsx

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import HomePage from './components/home/HomePage';
import ExploreCarsPage from './components/explorecars/ExploreCarsPage';
import AuthPage from './components/auth/AuthPage';
import Dashboard from './components/dashboard/DashBoard';
import VehicleListingPage from './components/listings/VehicleListingPage';
import ThankYouPage from './components/thankyou/ThankYouPage';
import CustomerInfoPage from './components/customerinfo/CustomerInfoPage';
import TermsAndConditionsPage from './components/terms/TermsAndConditionsPage';
import PurchasePage from './components/purchase/PurchasePage';
import AboutUsScreen from './components/about/AboutUsScreen';
import HowWeWorkScreen from './components/about/HowWeWorkScreen';
import BlogPage from './components/blog/BlogPage';
import SinglePostPage from './components/blog/SinglePostPage';

import PWAInstallButton from './utils/PWAInstallButton';
import { AuthProvider, useAuth } from './hooks/useAuth';
import { CartProvider } from './context/CartContext'; // Import the CartProvider

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: '#23d5ab',
    },
    secondary: {
      main: '#f72585',
    },
    background: {
      default: '#000',
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
  },
});

const AppRoutes = () => {
  const { currentUser } = useAuth(); // Access currentUser from the hook

  if (!currentUser) {
    return (
      <Routes>
        <Route path="/login" element={<AuthPage />} />
        <Route path="/" element={<HomePage />} />
        <Route path="/explore-cars" element={<ExploreCarsPage />} />
        <Route path="/vehicle/:id" element={<VehicleListingPage />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/customer-info" element={<CustomerInfoPage />} />
        <Route path="/purchase" element={<PurchasePage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<SinglePostPage />} />
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/explore-cars" element={<ExploreCarsPage />} />
      <Route path="/dashboard" element={<Dashboard userType={currentUser.userType} />} />
      <Route path="/vehicle/:id" element={<VehicleListingPage />} />
      <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
      <Route path="/thank-you" element={<ThankYouPage />} />
      <Route path="/customer-info" element={<CustomerInfoPage />} />
      <Route path="/purchase" element={<PurchasePage />} />
      <Route path="/about-us" element={<AboutUsScreen />} />
      <Route path="/how-we-work" element={<HowWeWorkScreen />} />
    </Routes>
  );
};

const App = () => {
  return (
    <AuthProvider>
      <CartProvider> {/* Wrap with CartProvider */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Router>
            <AppRoutes />
          </Router>
          <PWAInstallButton />
        </ThemeProvider>
      </CartProvider>
    </AuthProvider>
  );
};

export default App;




// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { ThemeProvider, createTheme } from '@mui/material/styles';
// import CssBaseline from '@mui/material/CssBaseline';

// import HomePage from './components/home/HomePage';
// import ExploreCarsPage from './components/explorecars/ExploreCarsPage';
// import AuthPage from './components/auth/AuthPage';
// import Dashboard from './components/dashboard/DashBoard';
// import VehicleListingPage from './components/listings/VehicleListingPage';
// import ThankYouPage from './components/thankyou/ThankYouPage';
// import CustomerInfoPage from './components/customerinfo/CustomerInfoPage';
// import TermsAndConditionsPage from './components/terms/TermsAndConditionsPage';
// import PurchasePage from './components/purchase/PurchasePage';
// import AboutUsScreen from './components/about/AboutUsScreen';
// import HowWeWorkScreen from './components/about/HowWeWorkScreen';

// import PWAInstallButton from './utils/PWAInstallButton';
// import { AuthProvider, useAuth } from './hooks/useAuth';

// // Create a custom theme
// const theme = createTheme({
//   palette: {
//     primary: {
//       main: '#23d5ab',
//     },
//     secondary: {
//       main: '#f72585',
//     },
//     background: {
//       default: '#000',
//     },
//   },
//   typography: {
//     fontFamily: 'Roboto, sans-serif',
//   },
// });

// const AppRoutes = () => {
//   const { currentUser } = useAuth(); // Access currentUser from the hook

//   if (!currentUser) {
//     return (
//       <Routes>
//         <Route path="/login" element={<AuthPage />} />
//         <Route path="/" element={<HomePage />} />
//         <Route path="/explore-cars" element={<ExploreCarsPage />} />
//         <Route path="/vehicle/:id" element={<VehicleListingPage />} />
//         <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
//         <Route path="/thank-you" element={<ThankYouPage />} />
//         <Route path="/customer-info" element={<CustomerInfoPage />} />
//         <Route path="/purchase" element={<PurchasePage />} />
//       </Routes>
//     );
//   }

//   return (
//     <Routes>
//       <Route path="/" element={<HomePage />} />
//       <Route path="/explore-cars" element={<ExploreCarsPage />} />
//       <Route path="/dashboard" element={<Dashboard userType={currentUser.userType} />} />
//       <Route path="/vehicle/:id" element={<VehicleListingPage />} />
//       <Route path="/terms-and-conditions" element={<TermsAndConditionsPage />} />
//       <Route path="/thank-you" element={<ThankYouPage />} />
//       <Route path="/customer-info" element={<CustomerInfoPage />} />
//       <Route path="/purchase" element={<PurchasePage />} />
//       <Route path="/about-us" element={<AboutUsScreen />} />
//       <Route path="/how-we-work" element={<HowWeWorkScreen />} />
//     </Routes>
//   );
// };

// const App = () => {
//   return (
//     <AuthProvider>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <Router>
//           <AppRoutes />
//         </Router>
//         <PWAInstallButton />
//       </ThemeProvider>
//     </AuthProvider>
//   );
// };

// export default App;


import { useState } from 'react';
import { ref as dbRef, push, set } from 'firebase/database'; // Correct usage of `push` for generating unique keys
import { ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, getDoc } from 'firebase/firestore'; // For Firestore
import { realtimeDb, storage, auth, db } from '../firebase'; // Include Firestore config as db

const useCreateListing = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const createListing = async (listingData, selectedImages) => {
    setLoading(true);
    setError(null);

    try {
      // Get current user ID from Firebase Auth
      const currentUser = auth.currentUser;
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
      const uid = currentUser.uid;

      // Fetch the user's city and state from Firestore
      const userDocRef = doc(db, 'users', uid); // Get the user document reference
      const userDoc = await getDoc(userDocRef); // Fetch the user document
      if (!userDoc.exists()) {
        throw new Error('User data not found');
      }
      const userData = userDoc.data();
      const userCity = userData.address?.city || '';
      const userState = userData.address?.state || '';

      // Prepare listing with user ID, city, and state
      const listingRef = push(dbRef(realtimeDb, `listings/${uid}`)); // Correctly generate unique key using `push()`
      const listingId = listingRef.key; // Get the generated listing ID

      // Upload images to Firebase Storage and get their URLs
      const imageUrls = [];
      for (const image of selectedImages) {
        const imageRef = storageRef(storage, `listings/${uid}/${listingId}/${image.name}`);
        await uploadBytes(imageRef, image);
        const imageUrl = await getDownloadURL(imageRef);
        imageUrls.push(imageUrl);
      }

      // Add image URLs, user city, and state to listing data
      const newListingData = {
        ...listingData,
        uid, // Attach user ID
        city: userCity, // Attach user's city
        state: userState, // Attach user's state
        imageUrls, // Attach image URLs
      };

      // Save the listing in Firebase Realtime Database
      await set(listingRef, newListingData); // Save data at the newly generated reference

      setLoading(false);
      return true; // Success
    } catch (err) {
      console.error('Error creating listing:', err);
      setError(err.message);
      setLoading(false);
      return false; // Failure
    }
  };

  return { createListing, loading, error };
};

export default useCreateListing;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000',
    minHeight: '100vh',
    padding: '40px 20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  neonText: {
    color: '#f72585',  // Ensure the neon text color stays consistent
    textShadow: '0 0 15px #f72585, 0 0 25px #23d5ab',  // Glow effect for readability
    fontWeight: 800,
    fontSize: '3rem',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',  // Darker background overlay for contrast
    padding: '10px',
    borderRadius: '10px',
    display: 'inline-block',
    '&:hover': {
      color: '#f72585',  // Ensure neon text stays the same color on hover
      textShadow: '0 0 20px #f72585, 0 0 30px #23d5ab',  // Stronger glow on hover
    },
  },
  
  searchContainer: {
    marginBottom: '30px',
    padding: '0 20px',
    width: '100%',
    maxWidth: '1200px',
  },
  inputField: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-outlined': {
      color: '#23d5ab',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#23d5ab',
    },
  },
  searchButtonContainer: {
    textAlign: 'center',
    marginBottom: '20px',
  },
  buttonNeon: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 5px #23d5ab, 0 0 15px #23d5ab',
    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',  // Ensure button text is white on hover
      boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    },
    transition: 'box-shadow 0.4s ease, transform 0.4s ease',
  },
  
  spinner: {
    color: '#23d5ab',
    width: '80px !important',
    height: '80px !important',
  },
  resultsContainer: {
    marginTop: '40px',
    width: '100%',
    maxWidth: '1200px',
  },
  card: {
    backgroundColor: 'rgba(27, 27, 27, 0.7)',  // Semi-transparent dark background
    border: '2px solid #23d5ab',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585, inset 0 0 10px rgba(255, 255, 255, 0.2)',  // Outer and inner glow
    transition: 'transform 0.4s ease, box-shadow 0.4s ease, color 0.4s ease',  // Add transition for smooth text color change
    backdropFilter: 'blur(10px)',  // Add a glassy blur effect
    borderRadius: '15px',
    cursor: 'pointer',
    minHeight: '350px',
    '&:hover': {
      transform: 'scale(1.1)',  // More dramatic hover effect
      boxShadow: '0 0 60px #23d5ab, 0 0 90px #f72585, inset 0 0 20px rgba(255, 255, 255, 0.3)',  // Stronger inner glow on hover
      backgroundColor: 'rgba(27, 27, 27, 0.9)',  // Higher opacity for better contrast
      '& $cardTitle, & $cardDescription': {
        color: '#fff',  // Change text color to white when the card is hovered
        textShadow: '0 0 12px #23d5ab, 0 0 20px #f72585',  // Enhanced text shadow on hover
      },
    },
  },
  
  cardTitle: {
    marginBottom: '10px',
    color: '#fff',  // Text is black by default (when not hovered)
    fontWeight: 700,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',  // Background overlay for contrast
    padding: '5px 10px',
    borderRadius: '8px',
    display: 'inline-block',
    textShadow: '0 0 8px rgba(0, 0, 0, 0.5)',  // Subtle shadow for black text
    transition: 'color 0.3s ease, text-shadow 0.3s ease',  // Smooth transition for hover
    '&:hover': {
      color: '#fff',  // Text turns white on hover
      textShadow: '0 0 12px #23d5ab, 0 0 20px #f72585',  // Enhanced text shadow on hover
    },
  },
  
  
  cardImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '12px',  // Rounded corners
    boxShadow: '0 0 20px #23d5ab, 0 0 30px #f72585',  // Add glow around the image
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    '&:hover': {
      transform: 'scale(1.08)',  // Slight zoom on hover
      boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585',  // Stronger glow on hover
    },
  },

  cardDescription: {
    color: '#fff',  // Text is black by default (when not hovered)
    fontWeight: 400,
    padding: '5px 10px',
    borderRadius: '5px',
    textShadow: '0 0 5px rgba(0, 0, 0, 0.5)',  // Subtle shadow for black text
    transition: 'color 0.3s ease, text-shadow 0.3s ease',  // Smooth transition for hover
    '&:hover': {
      color: '#fff',  // Text turns white on hover
      textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',  // Stronger text shadow on hover
    },
  },
  
  
  noResultsText: {
    color: '#f72585',
    textAlign: 'center',
    marginTop: '20px',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },
  loadingText: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginTop: '10px',
  },
  '@keyframes glow': {
    '0%': {
      textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    },
  },
}));

export default useStyles;

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000',
    color: '#fff',
    minHeight: '100vh',
    padding: '60px 30px',
    textAlign: 'center',
    animation: '$fadeIn 1.5s ease',
  },
  neonText: {
    color: '#f72585',
    textShadow: '0 0 20px #f72585, 0 0 30px #23d5ab',
    fontWeight: 800,
    fontSize: '3.8rem',
    marginBottom: '40px',
    animation: '$glow 2s ease-in-out infinite alternate',
  },
  introText: {
    fontSize: '1.6rem',
    margin: '20px 0',
    color: '#d0d0d0',
    textShadow: '0 0 10px #23d5ab',
  },
  contentContainer: {
    marginTop: '50px',
    padding: '0 20px',
  },
  section: {
    padding: '50px 30px',
    margin: '20px 0',
    background: 'linear-gradient(135deg, rgba(17, 17, 17, 0.9), rgba(0, 0, 0, 0.95))',
    border: '2px solid #23d5ab',
    borderRadius: '20px',
    boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 80px #23d5ab, 0 0 100px #f72585',
    },
  },
  sectionTitle: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '2.4rem',
    marginBottom: '20px',
    textShadow: '0 0 20px #f72585, 0 0 30px #23d5ab',
  },
  sectionText: {
    color: '#c0c0c0',
    fontSize: '1.5rem',
    lineHeight: '1.8',
    textShadow: '0 0 8px #23d5ab',
  },
  sectionSubText: {
    marginTop: '15px',
    color: '#23d5ab',
    fontSize: '1.2rem',
    textShadow: '0 0 10px #f72585',
  },
  icon: {
    fontSize: '3.5rem',
    marginBottom: '20px',
    color: '#f72585',
  },
  '@keyframes glow': {
    '0%': {
      textShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
    },
  },
  '@keyframes fadeIn': {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  imageContainer: {
    margin: '20px auto',
    textAlign: 'center',
  },
  image: {
    maxWidth: '20%',
    height: 'auto',
    borderRadius: '10px',
    boxShadow: '0 0 20px rgba(255, 255, 255, 0.2)',
  },
}));

export default useStyles;

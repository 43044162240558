import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#111',
    padding: '50px',
    borderRadius: '15px',
    boxShadow: '0 0 60px #f72585, 0 0 80px #23d5ab',
    textAlign: 'center',
    animation: '$fadeInUp 0.8s ease-in-out',
    maxWidth: '1200px',
    margin: 'auto',
    color: '#fff',
  },
  title: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '2.5rem',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab, 0 0 30px #23d5ab',
    marginBottom: '30px',
    animation: '$glowText 2s infinite alternate',
  },
  tableContainer: {
    backgroundColor: '#222', // Dark background for the table container
    borderRadius: '8px',
    boxShadow: '0 0 30px #23d5ab, 0 0 50px #f72585',
  },
  tableHeader: {
    color: '#23d5ab',
    fontWeight: 600,
    fontSize: '1.1rem',
    borderBottom: '1px solid #f72585',
    backgroundColor: '#111', // Set background of header cells
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#333',
    },
    '& td': {
      color: '#fff',
      borderBottom: '1px solid #23d5ab',
      backgroundColor: '#1a1a1a', // Background for table rows
      padding: '15px 10px',
    },
  },
  '@keyframes glowText': {
    '0%': {
      textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 30px #f72585, 0 0 50px #23d5ab',
    },
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(50px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
}));

export default useStyles;

// HomePageStyles.js



import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: '#000', // Black background
    minHeight: '100vh',
    color: '#fff',
  },
  pulsatingButton: {
    animation: '$pulse 2s infinite', // Pulsating button effect
  },
  hero: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    position: 'relative',
    padding: '150px 0',
    background: `
      linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.95) 0%,    /* Strong fade at the top to blend with site */
        rgba(0, 0, 0, 0.8) 15%,   /* Smooth fade transition */
        rgba(0, 0, 0, 0.6) 50%,   /* Transparent center */
        rgba(0, 0, 0, 0.8) 85%,   /* Darkening towards the bottom */
        rgba(0, 0, 0, 0.95) 100%  /* Full black at the bottom to blend with the rest of the site */
      ),
      url("/background.webp")`,
    backgroundSize: 'cover',
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundBlendMode: 'overlay',
    minHeight: '90vh',
    zIndex: 1,
    animation: '$gradientShimmer 8s ease-in-out infinite',
    overflow: 'hidden',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.85)', // Dark transparent overlay
    zIndex: 2,
  },
  content: {
    position: 'relative',
    zIndex: 3, // Ensure text and buttons appear on top
    color: '#fff',
    padding: '20px',
  },

  card: {
    backgroundColor: '#111',
    border: '2px solid #f72585',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    boxShadow: '0 0 20px #23d5ab',
    transition: 'transform 0.3s ease',
    '&:hover': {
      transform: 'scale(1.05)', // Interactive hover effect
    },
    cursor: 'pointer',
  },
  footer: {
    backgroundColor: '#111',
    padding: '20px 0',
    textAlign: 'center',
    color: '#23d5ab',
    borderTop: '2px solid #f72585',
  },

  typingEffect: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    borderRight: '3px solid #f72585',
    width: '34ch',  // Adjust to 34 characters to fit "Own the Road, Rule the Moment"
    animation: '$typing 3s steps(34), $blink 0.75s step-end infinite alternate',
  },
  
  '@keyframes typing': {
    'from': { width: '0' },
    'to': { width: '24ch' }
  },
  '@keyframes blink': {
    '50%': { borderColor: 'transparent' }
  },
  '@keyframes glow': {
    '0%': {
      textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab, 0 0 30px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 20px #f72585, 0 0 40px #23d5ab, 0 0 60px #23d5ab',
    },
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 10px #23d5ab, 0 0 20px #23d5ab',
    },
    '50%': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 20px #f72585, 0 0 40px #f72585',
    },
    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 10px #23d5ab, 0 0 20px #23d5ab',
    },
  },
  '@keyframes gradientShimmer': {
    '0%': {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
    '50%': {
      backgroundSize: '105%', // Slight zoom effect for dynamism
    },
    '100%': {
      backgroundPosition: 'center',
      backgroundSize: 'cover',
    },
  },
  featuredCarCard: {
    backgroundColor: 'rgba(17, 17, 17, 0.7)',  // Semi-transparent dark background
    border: '2px solid #23d5ab',
    padding: '20px',
    textAlign: 'center',
    color: '#fff',
    boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585, inset 0 0 10px rgba(255, 255, 255, 0.2)',  // Outer and inner glow
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    backdropFilter: 'blur(10px)',  // Blur effect for a glassy, futuristic look
    borderRadius: '15px',  // Slightly more rounded edges
    overflow: 'hidden',
    '&:hover': {
      transform: 'scale(1.1)',  // More dramatic hover effect
      boxShadow: '0 0 60px #23d5ab, 0 0 90px #f72585, inset 0 0 20px rgba(255, 255, 255, 0.3)',  // Stronger glow on hover
      backgroundColor: '#556',  // Slightly less transparency on hover
    },
  },
  

  carImage: {
    width: '100%',    
    height: '220px',  // Increase image height for a more cinematic look
    objectFit: 'cover',  // Ensure image covers the area without stretching
    borderRadius: '12px',  // Rounded edges
    boxShadow: '0 0 20px #23d5ab, 0 0 30px #f72585',  // Add glow around the image
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    '&:hover': {
      transform: 'scale(1.08)',  // Slight zoom on hover
      boxShadow: '0 0 40px #23d5ab, 0 0 60px #f72585',  // Stronger glow on hover
    },
  },

  neonText: {
    color: '#f72585',
    textShadow: '0 0 15px #f72585, 0 0 25px #23d5ab',  // Enhance the neon glow
    fontWeight: 800,  // Make the text bolder for a more striking appearance
    fontSize: '3rem',
  },

  priceText: {
    color: '#23d5ab',
    fontSize: '1.5rem',  // Increase size for a more prominent display
    fontWeight: 700,
    marginBottom: '15px',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',  // Add neon glow to the price text
  },

  buttonNeon: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',
      boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab',  // Enhance button glow on hover
    },
    transition: 'transform 0.4s ease, box-shadow 0.4s ease',
    marginTop: '15px',
  },

  spinner: {
    color: '#23d5ab',
    width: '80px !important',
    height: '80px !important',
    boxShadow: '0 0 30px #23d5ab, 0 0 50px #f72585',
  },

  loadingText: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginTop: '10px',
    fontWeight: 700,
  },


 ' @keyframes slideIn': {
    from: {
      transform: 'translateY(-100%)',
    },
    to: {
      transform:' translateY(0)',
    }
  },
  '@keyframes slideDown': {
    from: {
      top: '-100px',
    },
    to: {
      top: '50%',
    }
  }
  
 

}));

export default useStyles;

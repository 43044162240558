import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './TermsAndConditionsPageStyles'; // Import your custom styles
import logo from '../../assets/logo.png'; // Import your logo

const TermsAndConditionsPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const handleAccept = () => {
    console.log("Terms Accepted");
    navigate('/');
  };

  return (
    <Box className={classes.pageContainer}>
      {/* Logo at the top */}
      <img src={logo} alt="Vice City VIP Logo" className={classes.logo} />

      <Typography variant="h2" className={classes.title}>
        Terms & Conditions
      </Typography>

      <Typography variant="body1" className={classes.paragraph}>
        Welcome to Vice City VIP! By accessing or using our platform, you agree to the following terms and conditions in full. If you do not agree with these terms, please refrain from using our services.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Trademark and Intellectual Property
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        "Vice City VIP" is a registered trademark. All intellectual property rights related to this platform, including content, trademarks, and logos, are owned by us or licensed to us. You are permitted to use the site for personal purposes only, and you must not republish, distribute, or reproduce any materials without our express written permission.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Services Provided
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP acts as a connection platform between individuals and companies renting exotic cars, yachts, luxury homes, and other assets to interested renters. We do not participate in, nor are we responsible for, any transactions, agreements, or disputes between parties. Our role is solely to provide lead data to facilitate connections between users.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Lead Data Collection and Usage
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP collects user data for the purpose of generating leads that are sold to vendors. By using the platform, you consent to the collection of personal information such as your name, email address, phone number, and rental preferences. This data is used solely to connect you with the appropriate vendors. We do not share your information with third parties beyond those providing rental services.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        No Guarantees or Endorsements
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP does not guarantee the quality, availability, or legality of any service, product, or vendor listed on the platform. We provide no endorsement or recommendation of any vendor, and it is the user's responsibility to perform due diligence before engaging in any rental agreements or transactions.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Payment and Transactions
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP does not process payments between renters and vendors. All financial transactions are made directly between the parties involved. We are not responsible for payment disputes, chargebacks, or any monetary disagreements between the renter and the vendor.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Limitation of Liability
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP is not responsible for any outcomes resulting from interactions between users and vendors. We do not guarantee the accuracy or reliability of information provided by users or vendors. All transactions and interactions are conducted at your own risk. Vice City VIP disclaims all liability for any damages, claims, or losses that may arise.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        User Responsibilities
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        As a user, you agree to:
        <ul className={classes.list}>
          <li>Provide accurate and truthful information when using the platform</li>
          <li>Act in accordance with all applicable laws and regulations</li>
          <li>Not use the platform for any unlawful, fraudulent, or harmful activities</li>
          <li>Acknowledge that all arrangements with vendors are between you and the vendor, and Vice City VIP is not a party to any rental agreements</li>
        </ul>
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Vendor Obligations
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vendors listed on Vice City VIP are required to provide accurate descriptions of their services, products, and pricing. Vendors are solely responsible for ensuring their listings comply with all applicable laws and regulations. Vice City VIP is not liable for any false advertising or misrepresentation by vendors.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Disclaimers
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        We make no representations or warranties regarding the quality, safety, or legality of any rentals listed on the platform. It is your responsibility to verify the credentials and reliability of vendors and assets before engaging in any transaction.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Data Privacy and Security
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP values your privacy and takes reasonable steps to protect your personal information. However, we cannot guarantee absolute security. By using the platform, you acknowledge that any data transmitted over the internet may be subject to unauthorized access or breaches.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Changes to the Terms
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        Vice City VIP reserves the right to modify or update these terms at any time. Continued use of our platform after changes are made indicates acceptance of the new terms.
      </Typography>

      <Typography variant="h4" className={classes.subheading}>
        Governing Law
      </Typography>
      <Typography variant="body1" className={classes.paragraph}>
        These terms shall be governed by and construed in accordance with the laws of the jurisdiction in which Vice City VIP operates. Any disputes will be handled in the appropriate legal venue.
      </Typography>

      <Box className={classes.buttonContainer}>
        <Button className={classes.acceptButton} size="large" onClick={handleAccept}>
          Accept Terms
        </Button>
      </Box>
    </Box>
  );
};

export default TermsAndConditionsPage;

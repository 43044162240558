// CreateListingStyles.js


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#111',
    padding: '50px',
    borderRadius: '15px',
    boxShadow: '0 0 60px #f72585, 0 0 80px #23d5ab',
    textAlign: 'center',
    animation: '$fadeInUp 0.8s ease-in-out',
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '50px',
  },
  neonText: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '2.5rem',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab, 0 0 30px #23d5ab',
    marginBottom: '30px',
    animation: '$glowText 2s infinite alternate',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
    marginTop: '20px',
  },
  textField: {
    backgroundColor: '#222',
    borderRadius: '8px',
    '& .MuiOutlinedInput-root': {
      color: '#fff',
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
  },
  inputText: {
    color: '#fff', // Ensure text inside the input fields is visible
  },
  label: {
    color: '#23d5ab',
    '&.Mui-focused': {
      color: '#f72585',
    },
  },
  uploadContainer: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
    flexWrap: 'wrap',
    marginTop: '20px',
  },
  uploadLabel: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  fileInput: {
    display: 'none',
  },
  uploadButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontWeight: 700,
    padding: '12px 15px',
    borderRadius: '8px',
    boxShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    marginBottom: '10px',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
      transform: 'scale(1.05)',
    },
  },
  submitButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontWeight: 700,
    padding: '12px 25px',
    borderRadius: '8px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 40px #23d5ab, 0 0 80px #f72585',
      transform: 'scale(1.05)',
    },
    '&.Mui-disabled': {
      backgroundColor: '#666',
      color: '#999',
    },
  },
  
  pulsatingButton: {
    animation: '$pulse 2s infinite',
  },

  // Pulsating keyframes animation
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    },
    '50%': {
      transform: 'scale(1.05)',
      boxShadow: '0 0 40px #23d5ab, 0 0 80px #f72585',
    },
    '100%': {
      transform: 'scale(1)',
      boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    },
  },
  checkbox: {
    color: '#23d5ab',
    '&.Mui-checked': {
      color: '#f72585',
    },
  },
  checkboxLabel: {
    color: '#23d5ab',
    '& .MuiFormControlLabel-label': {
      color: '#fff',
    },
  },
  '@keyframes glowText': {
    '0%': {
      textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 30px #f72585, 0 0 50px #23d5ab',
    },
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(50px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  successModal: {
    backgroundColor: '#111', // Dark background
    borderRadius: '15px',
    padding: '20px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585', // Neon glow
    animation: '$fadeInUp 0.5s ease-in-out', // Fade-in effect
  },
  
  modalTitle: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    fontWeight: 700,
    fontSize: '1.8rem',
    textAlign: 'center',
    marginBottom: '20px',
  },
  
  modalText: {
    color: '#23d5ab',
    textAlign: 'center',
    fontSize: '1.2rem',
    marginBottom: '20px',
    textShadow: '0 0 5px #23d5ab, 0 0 10px #f72585',
  },
  
  modalButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontWeight: 700,
    borderRadius: '8px',
    padding: '10px 20px',
    boxShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    },
  },
  
}));

export default useStyles;

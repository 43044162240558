import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  listingContainer: {
    backgroundColor: '#000', // Dark background
    color: '#fff', // White text
    padding: '50px',
    textAlign: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  neonText: {
    color: '#f72585', // Neon pink
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab', // Neon glow
    fontWeight: 700,
    fontSize: '3rem',
    marginBottom: '20px',
  },
  vehicleImage: {
    width: '100%',
    maxWidth: '800px',
    height: 'auto',
    border: '2px solid #23d5ab', // Cyan border
    borderRadius: '10px',
    marginBottom: '30px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585', // Neon shadow effect
  },
  vehicleDescription: {
    fontSize: '1.2rem',
    marginBottom: '20px',
    color: '#23d5ab',
  },
  vehicleDetails: {
    fontSize: '1rem',
    marginBottom: '10px',
    color: '#fff',
  },
  buttonContainer: {
    marginTop: '30px',
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  rentNowButton: {
    backgroundColor: '#f72585', // Neon pink
    color: '#fff',
    fontSize: '1.2rem',
    padding: '10px 30px',
    borderRadius: '5px',
    boxShadow: '0 0 10px #f72585, 0 0 20px #f72585',
    '&:hover': {
      backgroundColor: '#d61c73', // Darker neon pink
    },
  },
  goBackButton: {
    backgroundColor: '#23d5ab', // Neon green
    color: '#fff',
    fontSize: '1.2rem',
    padding: '10px 30px',
    borderRadius: '5px',
    boxShadow: '0 0 10px #23d5ab, 0 0 20px #23d5ab',
    '&:hover': {
      backgroundColor: '#1cb993', // Darker neon green
    },
  },
}));

export default useStyles;

import { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { realtimeDb } from '../firebase'; // Import your configured Firebase Realtime Database

const useFeaturedCars = () => {
  const [cars, setCars] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchFeaturedCars = async () => {
      setLoading(true);
      try {
        const carsRef = ref(realtimeDb, 'listings');
        const snapshot = await get(carsRef);
        if (snapshot.exists()) {
          const carData = snapshot.val();

          // Flatten the data into an array of car objects
          const carArray = Object.keys(carData).reduce((acc, userId) => {
            const userListings = Object.keys(carData[userId]).map(listingId => ({
              id: listingId,
              ...carData[userId][listingId],
            }));
            return [...acc, ...userListings];
          }, []);

          // Randomly select 3 featured cars
          const shuffledCars = carArray.sort(() => 0.5 - Math.random());
          const featuredCars = shuffledCars.slice(0, 3);

          setCars(featuredCars);
        } else {
          setError('No featured cars found');
        }
      } catch (err) {
        setError('Failed to fetch featured cars');
      } finally {
        setLoading(false);
      }
    };

    fetchFeaturedCars();
  }, []);

  return { cars, loading, error };
};

export default useFeaturedCars;

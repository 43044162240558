
import React, { useState } from 'react';
import { Box, Typography, Button, CircularProgress, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import PurchaseModal from './PurchaseModal';
import useStyles from './PurchasePageStyles';
import Disclaimer from './Disclaimer';

// Load your Stripe public key
const stripePromise = loadStripe('your-publishable-key-here');

const PurchasePage = () => {
  const classes = useStyles();
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const location = useLocation();
  const { cartItems = [], totalPrice } = location.state || {}; // Use cartItems and totalPrice from Cart.js
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    name: '',
    billingZip: '',
  });

  const handleBillingDetailsChange = (e) => {
    const { name, value } = e.target;
    setBillingDetails({ ...billingDetails, [name]: value });
  };

  const handleConfirmPurchase = async () => {
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    // Call your backend to create a PaymentIntent
    const response = await fetch('/create-payment-intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ amount: totalPrice * 100 }) // Send amount in cents
    });

    const { clientSecret } = await response.json();

    const cardElement = elements.getElement(CardElement);

    // Confirm the card payment
    const { error, paymentIntent } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: cardElement,
        billing_details: {
          name: billingDetails.name,
          address: {
            postal_code: billingDetails.billingZip,
          },
        },
      },
    });

    if (error) {
      console.error('Payment failed:', error);
      setLoading(false);
    } else {
      setLoading(false);
      setModalOpen(true);
      console.log('Payment successful:', paymentIntent);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    navigate('/dashboard');
  };

  const handleCopyToClipboard = () => {
    const leadInfo = cartItems.map((item) => `
      Customer: ${item.customerName}
      Car: ${item.name}
      Rental Period: ${item.fromDate} to ${item.toDate}
      Total Days: ${item.numberOfDays}
      Renting In: ${item.rentingCity}, ${item.rentingState}
      Lead Cost: $${item.price.toFixed(2)}
    `).join('\n');
    navigator.clipboard.writeText(leadInfo);
    setCopied(true);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" gutterBottom className={classes.title}>
        Confirm Your Purchase
      </Typography>

      {/* Display Cart Items */}
      {cartItems.map((item, index) => (
        <Box key={index} mb={2}>
          <Typography variant="body1" gutterBottom>
            Customer: {item.customerName}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Car: {item.name}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Rental Period: {item.fromDate} to {item.toDate}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Total Days: {item.numberOfDays}
          </Typography>
          <Typography variant="body1" gutterBottom>
            Renting In: {item.rentingCity}, {item.rentingState}
          </Typography>
          <Typography variant="h6" gutterBottom className={classes.leadCost}>
            Lead Cost: ${item.price.toFixed(2)}
          </Typography>
        </Box>
      ))}

      <Typography variant="h5" gutterBottom className={classes.totalPrice}>
        Total Price: ${totalPrice.toFixed(2)}
      </Typography>

      {/* Billing Info */}
      <TextField
        label="Name on Card"
        name="name"
        value={billingDetails.name}
        onChange={handleBillingDetailsChange}
        fullWidth
        required
        className={classes.textField}
        style={{ marginBottom: '16px' }}
      />
      <TextField
        label="Billing Zip"
        name="billingZip"
        value={billingDetails.billingZip}
        onChange={handleBillingDetailsChange}
        fullWidth
        required
        className={classes.textField}
        style={{ marginBottom: '16px' }}
      />

      {/* Card Payment Form */}
      <Box className={classes.cardForm}>
        <CardElement 
          className={classes.textField}
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#fff',
                backgroundColor: '#222',
                '::placeholder': {
                  color: '#23d5ab',
                },
              },
              invalid: {
                color: '#f72585',
              },
            },
          }}
        />
      </Box>

      {loading ? (
        <CircularProgress className={classes.loader} />
      ) : (
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmPurchase}
          className={classes.confirmButton}
          disabled={!stripe || loading}
        >
          Confirm Purchase
        </Button>
      )}

      <Disclaimer />

      <PurchaseModal
        modalOpen={modalOpen}
        handleModalClose={handleModalClose}
        cartItems={cartItems}
        totalPrice={totalPrice}
        copied={copied}
        handleCopyToClipboard={handleCopyToClipboard}
      />
    </Box>
  );
};

// Wrap PurchasePage in Stripe's Elements provider
const WrappedPurchasePage = () => (
  <Elements stripe={stripePromise}>
    <PurchasePage />
  </Elements>
);

export default WrappedPurchasePage;

// CreateListing.js



import React, { useState, useEffect } from 'react';
import { Box, Typography, TextField, Button, Autocomplete, InputAdornment, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import useStyles from './CreateListingStyles'; // Import custom styles
import carsData from './../data/cars.json'; // Import the JSON file
import useCreateListing from '../../hooks/useCreateListing'; // Import the custom hook for creating listings

const CreateListing = () => {
  const classes = useStyles();

  const [selectedMake, setSelectedMake] = useState("");
  const [selectedModel, setSelectedModel] = useState("");
  const [models, setModels] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [carPrice, setCarPrice] = useState("");
  const [deliveryOffered, setDeliveryOffered] = useState(false);
  const [description, setDescription] = useState("");
  const [openSuccessModal, setOpenSuccessModal] = useState(false); // State for controlling the modal

  const { createListing, loading, error } = useCreateListing(); // Use the createListing hook

  useEffect(() => {
    const carMake = carsData.makes.find((make) => make.make === selectedMake);
    setModels(carMake ? carMake.models : []);
  }, [selectedMake]);

  const handleSubmit = async () => {
    if (!selectedMake || !selectedModel || !carPrice) {
      alert("Please fill in all required fields.");
      return;
    }

    const listingData = {
      make: selectedMake,
      model: selectedModel,
      pricePerDay: carPrice,
      deliveryOffered,
      description,
    };

    const success = await createListing(listingData, selectedImages);
    if (success) {
      setOpenSuccessModal(true); // Open the success modal on successful creation
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    setSelectedImages(files.slice(0, 3)); // Limit to 3 images
  };

  const handlePriceChange = (e) => {
    const value = e.target.value;
    setCarPrice(value);
  };

  const handlePriceBlur = () => {
    const numericValue = carPrice.replace(/\D/g, "");
    setCarPrice(numericValue ? numericValue : "");
  };

  const handleModalClose = () => {
    setOpenSuccessModal(false);
    
    // Reset the form fields
    setSelectedMake("");
    setSelectedModel("");
    setModels([]);
    setSelectedImages([]);
    setCarPrice("");
    setDeliveryOffered(false);
    setDescription("");
  };
  

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.neonText}>
        Create a New Listing
      </Typography>

      <form className={classes.form}>
        <Autocomplete
          options={carsData.makes.map((make) => make.make)}
          value={selectedMake}
          onChange={(event, newValue) => setSelectedMake(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Car Make"
              variant="outlined"
              fullWidth
              className={classes.textField}
              InputLabelProps={{ className: classes.label }}
            />
          )}
        />

        <Autocomplete
          options={models}
          value={selectedModel}
          onChange={(event, newValue) => setSelectedModel(newValue)}
          disabled={!selectedMake}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Car Model"
              variant="outlined"
              fullWidth
              className={classes.textField}
              InputLabelProps={{ className: classes.label }}
            />
          )}
        />

        <TextField
          label="Car Price per Day"
          variant="outlined"
          fullWidth
          value={carPrice}
          onChange={handlePriceChange}
          onBlur={handlePriceBlur}
          className={classes.textField}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
            endAdornment: <InputAdornment position="end">/day</InputAdornment>,
            inputMode: "numeric",
            className: classes.inputText,
          }}
          InputLabelProps={{ className: classes.label }}
        />

        <TextField
          label="Description"
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className={classes.textField}
          InputProps={{ className: classes.inputText }}
          InputLabelProps={{ className: classes.label }}
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={deliveryOffered}
              onChange={(e) => setDeliveryOffered(e.target.checked)}
              color="primary"
              className={classes.checkbox}
            />
          }
          label="Delivery Offered"
          className={classes.checkboxLabel}
        />

        <Box className={classes.uploadContainer}>
          <input
            accept="image/*"
            className={classes.fileInput}
            id="upload-button-file"
            type="file"
            multiple
            onChange={handleImageChange}
          />
          <label htmlFor="upload-button-file">
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.uploadButton}
              startIcon={<CloudUploadIcon />}
            >
              {selectedImages.length > 0
                ? `${selectedImages.length} Image${
                    selectedImages.length > 1 ? "s" : ""
                  } Uploaded`
                : "Upload Images (Max 3)"}
            </Button>
          </label>
        </Box>

        <Button
          variant="contained"
          onClick={handleSubmit}
          className={`${classes.submitButton} ${
            loading ? classes.pulsatingButton : ""
          }`}
          disabled={loading}
          startIcon={
            loading ? (
              <CircularProgress size={24} sx={{ color: "#fff" }} />
            ) : null
          }
        >
          {loading ? "Submitting..." : "Submit Listing"}
        </Button>

        {error && <Typography color="error">{error}</Typography>}
      </form>

      {/* Success Modal */}
      <Dialog
        open={openSuccessModal}
        onClose={handleModalClose}
        classes={{ paper: classes.successModal }}
      >
        <DialogTitle className={classes.modalTitle}>
          Listing Created Successfully!
        </DialogTitle>
        <DialogContent>
          <Typography className={classes.modalText}>
            Your vehicle listing for {selectedMake} {selectedModel} has been
            successfully created.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose} className={classes.modalButton}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateListing;



// import React, { useState, useEffect } from 'react';
// import { Box, Typography, TextField, Button, Autocomplete, InputAdornment, Checkbox, FormControlLabel } from '@mui/material';
// import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
// import useStyles from './CreateListingStyles'; // Import custom styles
// import carsData from './../data/cars.json'; // Import the JSON file

// const CreateListing = () => {
//   const classes = useStyles();

//   const [selectedMake, setSelectedMake] = useState('');
//   const [selectedModel, setSelectedModel] = useState('');
//   const [models, setModels] = useState([]);
//   const [selectedImages, setSelectedImages] = useState([]);
//   const [carPrice, setCarPrice] = useState('');
//   const [deliveryOffered, setDeliveryOffered] = useState(false);

//   useEffect(() => {
//     // Update the available models when a make is selected
//     const carMake = carsData.makes.find(make => make.make === selectedMake);
//     setModels(carMake ? carMake.models : []);
//   }, [selectedMake]);

//   const handleSubmit = () => {
//     alert(`Listing Created for ${selectedMake} ${selectedModel} at ${carPrice} per day. Delivery Offered: ${deliveryOffered ? 'Yes' : 'No'}`);
//   };

//   const handleImageChange = (e) => {
//     const files = Array.from(e.target.files);
//     setSelectedImages(files.slice(0, 3)); // Limit to 3 images
//   };

//   const handlePriceChange = (e) => {
//     const value = e.target.value;
//     setCarPrice(value);
//   };

//   const handlePriceBlur = () => {
//     const numericValue = carPrice.replace(/\D/g, '');
//     setCarPrice(numericValue ? numericValue : '');
//   };

//   return (
//     <Box className={classes.container}>
//       <Typography variant="h4" className={classes.neonText}>
//         Create a New Listing
//       </Typography>

//       <form className={classes.form}>
//         {/* Car Make Auto-complete */}
//         <Autocomplete
//           options={carsData.makes.map(make => make.make)}
//           value={selectedMake}
//           onChange={(event, newValue) => setSelectedMake(newValue)}
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label="Car Make"
//               variant="outlined"
//               fullWidth
//               className={classes.textField}
//               InputLabelProps={{ className: classes.label }}
//             />
//           )}
//         />

//         {/* Car Model Auto-complete */}
//         <Autocomplete
//           options={models}
//           value={selectedModel}
//           onChange={(event, newValue) => setSelectedModel(newValue)}
//           disabled={!selectedMake} // Disable until a make is selected
//           renderInput={(params) => (
//             <TextField
//               {...params}
//               label="Car Model"
//               variant="outlined"
//               fullWidth
//               className={classes.textField}
//               InputLabelProps={{ className: classes.label }}
//             />
//           )}
//         />

//         {/* Car Price with 'per day' label */}
//         <TextField
//           label="Car Price per Day"
//           variant="outlined"
//           fullWidth
//           value={carPrice}
//           onChange={handlePriceChange}
//           onBlur={handlePriceBlur}
//           className={classes.textField}
//           InputProps={{
//             startAdornment: <InputAdornment position="start">$</InputAdornment>,
//             endAdornment: <InputAdornment position="end">/day</InputAdornment>,
//             inputMode: 'numeric',
//             className: classes.inputText, // Ensure text is visible
//           }}
//           InputLabelProps={{ className: classes.label }}
//         />

//         <TextField
//           label="Description"
//           variant="outlined"
//           multiline
//           rows={4}
//           fullWidth
//           className={classes.textField}
//           InputProps={{
//             className: classes.inputText,
//           }}
//           InputLabelProps={{ className: classes.label }}
//         />

//         {/* Delivery Offered Checkbox */}
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={deliveryOffered}
//               onChange={(e) => setDeliveryOffered(e.target.checked)}
//               color="primary"
//               className={classes.checkbox}
//             />
//           }
//           label="Delivery Offered"
//           className={classes.checkboxLabel}
//         />

//         {/* Upload Images */}
//         <Box className={classes.uploadContainer}>
//           <input
//             accept="image/*"
//             className={classes.fileInput}
//             id="upload-button-file"
//             type="file"
//             multiple
//             onChange={handleImageChange}
//           />
//           <label htmlFor="upload-button-file">
//             <Button
//               variant="contained"
//               color="primary"
//               component="span"
//               className={classes.uploadButton}
//               startIcon={<CloudUploadIcon />}
//             >
//               {selectedImages.length > 0
//                 ? `${selectedImages.length} Image${selectedImages.length > 1 ? 's' : ''} Uploaded`
//                 : 'Upload Images (Max 3)'}
//             </Button>
//           </label>
//         </Box>

//         <Button
//           variant="contained"
//           onClick={handleSubmit}
//           className={`${classes.submitButton} ${classes.pulsatingButton}`}
//         >
//           Submit Listing
//         </Button>
//       </form>
//     </Box>
//   );
// };

// export default CreateListing;

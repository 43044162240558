// Header.jsx
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import useStyles from './HomePageStyles';
import { useAuth } from '../../hooks/useAuth';

const Header = () => {
  const classes = useStyles();
  const { currentUser } = useAuth(); // Get currentUser from useAuth
  const [openDialog, setOpenDialog] = useState(false); // State to control the popup

  const handleOpenDialog = () => {
    setOpenDialog(true); // Open the popup
  };

  const handleCloseDialog = () => {
    setOpenDialog(false); // Close the popup
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#000', boxShadow: '0 0 15px #f72585' }}>
      <Toolbar>
        {/* Replace "VICE CITY" text with logo */}
        <img
          src="/logo.png" // Access logo from the public directory
          alt="Company Logo"
          style={{ height: '70px', marginRight: 'auto' }} // Adjust height if necessary
        />
        <Button component={Link} to="/explore-cars" color="inherit" className={classes.buttonNeon}>
          Explore Cars
        </Button>
        {currentUser ? (
          // If the user is logged in, show the Dashboard button
          <Button component={Link} to="/dashboard" color="inherit" className={classes.buttonNeon}>
            Dashboard
          </Button>
        ) : (
          // If the user is not logged in, show the Login button, but trigger the dialog popup
          <Button onClick={handleOpenDialog} color="inherit" className={classes.buttonNeon}>
            Login
          </Button>
        )}
      </Toolbar>

      {/* Dialog Popup for "Coming Soon" */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>{"Coming Soon"}</DialogTitle>
        <DialogContent>
          <Typography>
            Coming soon! Stay tuned for updates.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
};

export default Header;





// import React from 'react';
// import { AppBar, Toolbar, Button } from '@mui/material';
// import { Link } from 'react-router-dom';
// import useStyles from './HomePageStyles';
// import { useAuth } from '../../hooks/useAuth';

// const Header = () => {
//   const classes = useStyles();
//   const { currentUser } = useAuth(); // Get currentUser from useAuth

//   return (
//     <AppBar position="static" style={{ backgroundColor: '#000', boxShadow: '0 0 15px #f72585' }}>
//       <Toolbar>
//         {/* Replace "VICE CITY" text with logo */}
//         <img
//           src="/logo.png" // Access logo from the public directory
//           alt="Company Logo"
//           style={{ height: '70px', marginRight: 'auto' }} // Adjust height if necessary
//         />
//         <Button component={Link} to="/explore-cars" color="inherit" className={classes.buttonNeon}>
//           Explore Cars
//         </Button>
//         {currentUser ? (
//           // If the user is logged in, show the Dashboard button
//           <Button component={Link} to="/dashboard" color="inherit" className={classes.buttonNeon}>
//             Dashboard
//           </Button>
//         ) : (
//           // If the user is not logged in, show the Login button
//           <Button component={Link} to="/login" color="inherit" className={classes.buttonNeon}>
//             Login
//           </Button>
//         )}
//       </Toolbar>
//     </AppBar>
//   );
// };

// export default Header;



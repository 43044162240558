// firebase.js
import { initializeApp, getApps, getApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getDatabase } from 'firebase/database';





// Main Firebase config
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL, // Main Realtime Database URL
};

// Vice City VIP Realtime Database config
const viceCityConfig = {
  apiKey: process.env.REACT_APP_VICE_CITY_API_KEY,
  authDomain: process.env.REACT_APP_VICE_CITY_AUTH_DOMAIN,
  databaseURL: 'https://vice-city-vip-leads.firebaseio.com/', // Vice City VIP Realtime Database URL
  projectId: process.env.REACT_APP_VICE_CITY_PROJECT_ID,
  storageBucket: process.env.REACT_APP_VICE_CITY_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_VICE_CITY_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_VICE_CITY_APP_ID,
};

// Third Firebase Database config (Additional DB)
const thirdDatabaseConfig = {
  apiKey: process.env.REACT_APP_THIRD_API_KEY,
  authDomain: process.env.REACT_APP_THIRD_AUTH_DOMAIN,
  databaseURL: 'https://vice-city-vip-blog.firebaseio.com/', // Third Database URL
  projectId: process.env.REACT_APP_THIRD_PROJECT_ID,
  storageBucket: process.env.REACT_APP_THIRD_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_THIRD_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_THIRD_APP_ID,
};

// Initialize the main Firebase app
const app = !getApps().length ? initializeApp(firebaseConfig) : getApp();
const auth = getAuth(app);
const db = getFirestore(app); // Firestore for the main database
const storage = getStorage(app); // Firebase Storage for the main app
const realtimeDb = getDatabase(app); // Firebase Realtime Database for the main app

// Initialize the Vice City VIP Firebase app
const viceCityApp = initializeApp(viceCityConfig, 'viceCityVIP');
const thirdApp = initializeApp(thirdDatabaseConfig, 'thirdDatabase');

const viceCityRealtimeDb = getDatabase(viceCityApp); // Vice City VIP Realtime Database
const thirdRealtimeDb = getDatabase(thirdApp); // Third Realtime Database


export { auth, db, storage, realtimeDb, viceCityRealtimeDb, thirdRealtimeDb };

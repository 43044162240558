import React, { useState } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery, Modal, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useStyles from './RentalHistoryStyles'; // Custom styles

// Sample data for rental history
const rentalData = [
  {
    name: 'John Doe',
    phone: '+1 123 456 7890',
    address: '123 Main St, Los Angeles, CA',
    email: 'john.doe@example.com',
    car: 'Lamborghini Aventador',
    duration: '3 days',
  },
  {
    name: 'Jane Smith',
    phone: '+1 987 654 3210',
    address: '456 Sunset Blvd, Miami, FL',
    email: 'jane.smith@example.com',
    car: 'Ferrari 488 GTB',
    duration: '5 days',
  },
  {
    name: 'Michael Johnson',
    phone: '+44 7890 123456',
    address: '789 Oxford St, London, UK',
    email: 'm.johnson@example.co.uk',
    car: 'McLaren 720S',
    duration: '2 days',
  },
];

const RentalHistory = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if the screen size is mobile
  const [selectedRental, setSelectedRental] = useState(null); // State for selected rental
  const [openModal, setOpenModal] = useState(false); // State for modal visibility

  const handleOpenModal = (rental) => {
    setSelectedRental(rental); // Set the selected rental data
    setOpenModal(true); // Open the modal
  };

  const handleCloseModal = () => {
    setOpenModal(false); // Close the modal
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Approved Leads
      </Typography>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHeader}>Customer Name</TableCell>
              {!isMobile && <TableCell className={classes.tableHeader}>Phone</TableCell>}
              {!isMobile && <TableCell className={classes.tableHeader}>Address</TableCell>}
              {!isMobile && <TableCell className={classes.tableHeader}>Email</TableCell>}
              <TableCell className={classes.tableHeader}>Car</TableCell>
              <TableCell className={classes.tableHeader}>Duration</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rentalData.map((rental, index) => (
              <TableRow 
                key={index} 
                className={classes.tableRow} 
                onClick={() => isMobile && handleOpenModal(rental)} // Only allow clicking on mobile
                style={{ cursor: isMobile ? 'pointer' : 'default' }} // Add cursor for mobile
              >
                <TableCell>{rental.name}</TableCell>
                {!isMobile && <TableCell>{rental.phone}</TableCell>}
                {!isMobile && <TableCell>{rental.address}</TableCell>}
                {!isMobile && <TableCell>{rental.email}</TableCell>}
                <TableCell>{rental.car}</TableCell>
                <TableCell>{rental.duration}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Modal for mobile full view */}
      <Modal open={openModal} onClose={handleCloseModal}>
        <Box className={classes.modalContent}>
          {selectedRental && (
            <>
              <Typography variant="h6" gutterBottom>
                Full Lead Details
              </Typography>
              <Typography variant="body1">
                Name: {selectedRental.name}
              </Typography>
              <Typography variant="body1">
                Phone: {selectedRental.phone}
              </Typography>
              <Typography variant="body1">
                Address: {selectedRental.address}
              </Typography>
              <Typography variant="body1">
                Email: {selectedRental.email}
              </Typography>
              <Typography variant="body1">
                Car: {selectedRental.car}
              </Typography>
              <Typography variant="body1">
                Duration: {selectedRental.duration}
              </Typography>
              <Button onClick={handleCloseModal} className={classes.closeButton}>
                Close
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RentalHistory;

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './ThankYouPageStyles'; // Custom styles
import logo from '../../assets/logo.png'; // Import the logo image

const ThankYouPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  // Handle navigating back to car listings or other pages
  const handleExploreCars = () => {
    navigate('/explore-cars'); // Assuming you have a route for exploring cars
  };

  return (
    <Box className={classes.container}>
      {/* Logo at the top */}
      <img src={logo} alt="Company Logo" className={classes.logo} />

      <Typography variant="h3" className={classes.neonText}>
        Thank You!
      </Typography>
      <Typography variant="h6" className={classes.message}>
        Your request has been submitted. You should hear back from the vendor soon.
      </Typography>
      <Typography variant="body1" className={classes.message}>
        Make sure to submit a request for every car you're interested in to ensure the best deal and a faster response.
      </Typography>
      <Button
        variant="contained"
        color="primary"
        size="large"
        className={classes.exploreButton}
        onClick={handleExploreCars}
      >
        Explore More Cars
      </Button>
    </Box>
  );
};

export default ThankYouPage;

import React from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import { Box, Typography, Grid } from '@mui/material';
import useStyles from './HowWeWorkStyles'; // Custom styles for the screen
import { AiOutlineCrown, AiOutlineRadarChart, AiOutlineLineChart } from 'react-icons/ai'; // Illustrative icons

const HowWeWorkScreen = () => {
  const classes = useStyles();

  return (
    <>
      {/* Helmet for SEO and metadata */}
      <Helmet>
        <title>How We Work | Vice City VIP</title>
        <meta name="description" content="Learn how Vice City VIP combines technology and luxury to offer a premium rental experience with AI-powered lead ranking, exclusive bidding, and seamless management." />
        <link rel="canonical" href="https://vicecityvip.com/how-we-work" />
      </Helmet>

      <Box className={classes.root}>
        {/* Page Header */}
        <Typography variant="h3" className={classes.neonText}>
          How We Work
        </Typography>

        <Typography variant="h6" className={classes.introText}>
          At Vice City VIP, we bring technology and luxury together. Our processes are designed to ensure a premium experience for clients and renters alike. Here’s how we work.
        </Typography>

        {/* Illustrative Image */}
        <Box className={classes.imageContainer}>
          <img
            src="/leads.webp"
            alt="Leads Workflow"
            className={classes.image}
            loading="lazy" // Optimized for lazy loading
          />
        </Box>

        <Grid container spacing={4} className={classes.contentContainer}>
          {/* Lead Ranking */}
          <Grid item xs={12} md={4}>
            <Box className={classes.section}>
              <AiOutlineRadarChart className={classes.icon} />
              <Typography variant="h5" className={classes.sectionTitle}>
                Lead Ranking and Evaluation
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                We use cutting-edge AI technology, powered by Hive Forensics, to evaluate and rank leads. By analyzing key data points, we ensure that only the most valuable leads are passed on, maximizing business success for our partners.
              </Typography>
              <Typography variant="body2" className={classes.sectionSubText}>
                Example: A luxury car is more likely to attract serious clients with high spending power. Our AI identifies those prospects, saving you time and effort.
              </Typography>
            </Box>
          </Grid>

          {/* Exclusive Lead Bidding */}
          <Grid item xs={12} md={4}>
            <Box className={classes.section}>
              <AiOutlineCrown className={classes.icon} />
              <Typography variant="h5" className={classes.sectionTitle}>
                Exclusive Lead Bidding
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                With our direct bidding system, agents have a 72-hour exclusive window to respond to high-quality leads for exotic cars, yachts, or properties. This ensures flexibility, control, and exclusivity.
              </Typography>
              <Typography variant="body2" className={classes.sectionSubText}>
                Exclusive Window: You’ll have first dibs on leads without the rush. If the lead isn’t accepted within 72 hours, it’s passed to other renters, but you get priority.
              </Typography>
            </Box>
          </Grid>

          {/* Seamless Experience */}
          <Grid item xs={12} md={4}>
            <Box className={classes.section}>
              <AiOutlineLineChart className={classes.icon} />
              <Typography variant="h5" className={classes.sectionTitle}>
                A Seamless Experience
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Our user-friendly platform simplifies the process of managing luxury rentals. From intuitive dashboards to AI-driven analytics, we ensure a premium service that makes life easier for both clients and business owners.
              </Typography>
              <Typography variant="body2" className={classes.sectionSubText}>
                Effortless Management: Our platform keeps you updated on all aspects of your rentals, from lead engagement to successful transactions.
              </Typography>
            </Box>
          </Grid>

          {/* Powered by AI */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Powered by AI
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Our platform is powered by AI technology that continuously learns and adapts. We leverage data analytics to predict trends, allowing us to offer tailored solutions and insights to maximize your business potential.
              </Typography>
              <Typography variant="body2" className={classes.sectionSubText}>
                Smart Analytics: By analyzing user behavior and market conditions, our AI can make recommendations to optimize your lead generation and improve client satisfaction.
              </Typography>
            </Box>
          </Grid>

          {/* Dedicated Client Support */}
          <Grid item xs={12} md={6}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Dedicated Client Support
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                At Vice City VIP, our commitment to you doesn’t stop once the transaction is complete. Our dedicated client support team is always on hand to provide assistance, answer any questions, and help you grow your luxury rental business.
              </Typography>
              <Typography variant="body2" className={classes.sectionSubText}>
                24/7 Support: Our team is available around the clock to ensure that you receive the best service, whenever you need it.
              </Typography>
            </Box>
          </Grid>
        </Grid>

        {/* Additional Section */}
        <Grid container spacing={4} className={classes.contentContainer}>
          <Grid item xs={12}>
            <Box className={classes.section}>
              <Typography variant="h5" className={classes.sectionTitle}>
                Custom Solutions for Your Business
              </Typography>
              <Typography variant="body1" className={classes.sectionText}>
                Vice City VIP offers tailored solutions to suit your unique business needs. Whether you're a luxury car rental, yacht charter service, or high-end property agency, we provide you with the tools and analytics to thrive.
              </Typography>
              <Typography variant="body2" className={classes.sectionSubText}>
                Personalized Approach: We believe in customizing our services to match the scale and objectives of your business, ensuring maximum efficiency and success.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default HowWeWorkScreen;

// PendingRentals.js



import React, { useState } from "react";
import {
  Typography,
  List,
  ListItemText,
  Box,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  StyledBox,
  StyledListItem,
  StyledDivider,
  NeonText,
  SubText,
  ModalContent,
  LeadQualityBadge,
} from "./PendingRentalsStyles";
import { useNavigate } from "react-router-dom";
import { useCart } from "../../context/CartContext"; // Import from the global CartContext
import calculateLeadCost from "../../utils/leadCostCalculator"; // Import lead cost calculator
import useFetchLeads from "../../hooks/useFetchLeads"; // Import the custom hook

const formatCustomerData = (name, state, phone) => {
  const firstName = name.split(" ")[0];
  const lastTwoDigitsOfPhone = phone.slice(-2);
  return `${firstName} from ${state}, Phone ending in ${lastTwoDigitsOfPhone}`;
};

// Function to categorize the lead quality score
const getLeadQualityCategory = (score) => {
  if (score >= 70) return "High";
  if (score >= 40) return "Medium";
  return "Low";
};

const PendingRentals = () => {
  const [selectedRental, setSelectedRental] = useState(null);
  const navigate = useNavigate();
  const { addToCart } = useCart();

  const { leads: pendingRentalsData, loading, error } = useFetchLeads(); // Use the hook to fetch leads

  const handleOpen = (rental) => {
    setSelectedRental(rental);
  };

  const handleClose = () => {
    setSelectedRental(null);
  };

  const handleApprove = () => {
    const leadCost = calculateLeadCost(
      selectedRental.dailyRate,
      selectedRental.numberOfDays,
      1, // Location factor
      selectedRental.unlockedCount, // Pass unlockedCount
      selectedRental.leadQualityScore,
      selectedRental.createdAt // Pass createdAt
    );

    const updatedRental = {
      ...selectedRental,
      price: leadCost,
    };

    navigate("/purchase", {
      state: {
        cartItems: [updatedRental],
        totalPrice: leadCost,
      },
    });

    handleClose();
  };

  const handleAddToCart = (rental) => {
    const leadCost = calculateLeadCost(
      rental.dailyRate,
      rental.numberOfDays,
      1, // Location factor
      rental.unlockedCount, // Pass unlockedCount correctly
      rental.leadQualityScore,
      rental.createdAt // Pass createdAt
    );

    const rentalItem = {
      id: rental.id,
      name: `${rental.brand} ${rental.model}`,
      dailyRate: rental.dailyRate,
      numberOfDays: rental.numberOfDays,
      isExclusive: rental.unlockedCount === 0,
      unlockedCount: rental.unlockedCount,
      leadQualityScore: rental.leadQualityScore,
      price: leadCost, // Store the calculated lead cost directly here
      rentingCity: rental.rentingCity,
      rentingState: rental.rentingState,
    };

    addToCart(rentalItem); // Add the rental item to the cart
  };

  if (loading) return <Typography>Loading leads...</Typography>;
  if (error)
    return <Typography>Error loading leads: {error.message}</Typography>;

  const now = new Date();
  const exclusiveLeads = pendingRentalsData.filter((rental) => {
    const timeDiff = now - new Date(rental.createdAt);
    return timeDiff <= 72 * 60 * 60 * 1000 && rental.unlockedCount === 0; // Exclusive leads (within 72 hours and not sold)
  });

  const openLeads = pendingRentalsData.filter((rental) => {
    const timeDiff = now - new Date(rental.createdAt);
    return timeDiff > 72 * 60 * 60 * 1000 || rental.unlockedCount > 0; // Open leads (after 72 hours or sold)
  });

  return (
    <StyledBox>
      <NeonText variant="h4">Pending Leads</NeonText>
      <SubText variant="subtitle1">
        These are your rental leads awaiting confirmation:
      </SubText>

      <Typography variant="h6" sx={{ color: "#23d5ab", marginBottom: "10px" }}>
        Exclusive Leads (72 hours):
      </Typography>
      <List>
        {exclusiveLeads.map((rental) => {
          const leadCost = calculateLeadCost(
            rental.dailyRate,
            rental.numberOfDays,
            1,
            rental.unlockedCount,
            rental.leadQualityScore,
            rental.createdAt
          );

          const leadQualityCategory = getLeadQualityCategory(
            rental.leadQualityScore
          );

          return (
            <React.Fragment key={rental.id}>
              <StyledListItem>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                <Box onClick={() => handleOpen(rental)} flexGrow={1}>
  <ListItemText
    primary={formatCustomerData(
      rental.customerName,
      rental.state,
      rental.phone
    )}
    secondary={
      <>
        <LeadQualityBadge quality={leadQualityCategory}>
          {`Lead Quality: ${leadQualityCategory}`}
        </LeadQualityBadge>
        <span
          style={{
            color: "#23d5ab",
            fontWeight: "bold",
            marginLeft: "10px",
          }}
        >
          Exclusive
        </span>
        <span style={{ display: "block", color: "#23d5ab" }}>
          {`Renting in: ${rental.rentingCity}, ${rental.rentingState}`}
        </span>
      </>
    }
    primaryTypographyProps={{
      style: { color: "#fff", fontWeight: "bold" },
    }}
  />
</Box>

                  <Typography
                    variant="body2"
                    sx={{
                      color: "#23d5ab",
                      fontWeight: "bold",
                      marginRight: "15px",
                    }}
                  >
                    {`Cost: $${leadCost.toFixed(2)}`}
                  </Typography>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(rental);
                    }}
                    style={{ color: "#23d5ab" }}
                  >
                    <ShoppingCartIcon />
                  </IconButton>
                </Box>
              </StyledListItem>
              <StyledDivider />
            </React.Fragment>
          );
        })}
      </List>

      <Typography
        variant="h6"
        sx={{ color: "#f72585", marginBottom: "10px", marginTop: "20px" }}
      >
        Open Leads:
      </Typography>
      <List>
        {openLeads.map((rental) => {
          const leadCost = calculateLeadCost(
            rental.dailyRate,
            rental.numberOfDays,
            1,
            rental.unlockedCount,
            rental.leadQualityScore,
            rental.createdAt
          );

          const leadQualityCategory = getLeadQualityCategory(
            rental.leadQualityScore
          );

          return (
            <React.Fragment key={rental.id}>
              <StyledListItem>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                >
                  <Box onClick={() => handleOpen(rental)} flexGrow={1}>
                    <ListItemText
                      primary={formatCustomerData(
                        rental.customerName,
                        rental.state,
                        rental.phone
                      )}
                      secondary={
                        <>
                          <span
                            style={{ color: "#f72585", fontWeight: "bold" }}
                          >
                            {`Lead Quality: ${leadQualityCategory}`}
                          </span>
                          <span style={{ display: "block", color: "#f72585" }}>
                            {`Renting in: ${rental.rentingCity}, ${rental.rentingState}`}
                          </span>
                        </>
                      }
                      primaryTypographyProps={{
                        style: { color: "#fff", fontWeight: "bold" },
                      }}
                    />
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      color: "#f72585",
                      fontWeight: "bold",
                      marginRight: "15px",
                    }}
                  >
                    {`Cost: $${leadCost.toFixed(2)}`}
                  </Typography>
                  <IconButton
                    edge="end"
                    color="inherit"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddToCart(rental);
                    }}
                    style={{ color: "#f72585" }}
                  >
                    <ShoppingCartIcon />
                  </IconButton>
                </Box>
              </StyledListItem>
              <StyledDivider />
            </React.Fragment>
          );
        })}
      </List>

      <Modal open={Boolean(selectedRental)} onClose={handleClose}>
        <ModalContent>
          {selectedRental && (
            <>
              <Typography variant="h6" gutterBottom>
                Approve or Deny Rental
              </Typography>
              <Typography variant="body1" gutterBottom>
                {formatCustomerData(
                  selectedRental.customerName,
                  selectedRental.state,
                  selectedRental.phone
                )}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Car: {selectedRental.brand} {selectedRental.model}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Rental Period: {selectedRental.fromDate} to{" "}
                {selectedRental.toDate}
              </Typography>
              <Typography variant="body2" gutterBottom>
                Number of Days: {selectedRental.numberOfDays}
              </Typography>
              <Box mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleApprove}
                  style={{
                    marginRight: "10px",
                    backgroundColor: "#23d5ab",
                    boxShadow: "0 0 10px #23d5ab",
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClose}
                  style={{
                    backgroundColor: "#f72585",
                    boxShadow: "0 0 10px #f72585",
                  }}
                >
                  Deny
                </Button>
              </Box>
            </>
          )}
        </ModalContent>
      </Modal>
    </StyledBox>
  );
};

export default PendingRentals;



// import React, { useState } from 'react';
// import { Typography, List, ListItemText, Box, Modal, Button, IconButton } from '@mui/material';
// import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { StyledBox, StyledListItem, StyledDivider, NeonText, SubText, ModalContent } from './PendingRentalsStyles';
// import { useNavigate } from 'react-router-dom';
// import { useCart } from '../../context/CartContext'; // Import from the global CartContext
// import calculateLeadCost from '../../utils/leadCostCalculator'; // Import lead cost calculator

// const formatCustomerData = (name, state, phone) => {
//   const firstName = name.split(' ')[0];
//   const lastTwoDigitsOfPhone = phone.slice(-2);
//   return `${firstName} from ${state}, Phone ending in ${lastTwoDigitsOfPhone}`;
// };

// const PendingRentals = () => {
//   const [selectedRental, setSelectedRental] = useState(null);
//   const navigate = useNavigate();
//   const { pendingRentalsData = [], addToCart } = useCart();

//   const handleOpen = (rental) => {
//     setSelectedRental(rental);
//   };

//   const handleClose = () => {
//     setSelectedRental(null);
//   };
//   const handleApprove = () => {
//     // Calculate lead cost for the selected rental
//     const leadCost = calculateLeadCost(
//       selectedRental.dailyRate,  // Use dailyRate
//       selectedRental.numberOfDays,  // Use numberOfDays
//       1,  // Location factor
//       selectedRental.isExclusive,
//       selectedRental.unlockedCount,
//       selectedRental.leadQualityScore
//     );
  
//     // Create a "cart-like" structure for the selected rental
//     const updatedRental = {
//       ...selectedRental,
//       price: leadCost, // Assign calculated lead cost to price
//     };
  
//     // Navigate to the purchase page with this single rental as if it were a "cart"
//     navigate('/purchase', { 
//       state: { 
//         cartItems: [updatedRental],  // Pass the single rental as a cart item
//         totalPrice: leadCost // Total price is just the lead cost of this single rental
//       }
//     });
  
//     handleClose(); // Close the modal
//   };
  
  

//   const handleAddToCart = (rental) => {
//     const leadCost = calculateLeadCost(
//       rental.dailyRate,
//       rental.numberOfDays,
//       1, // Location factor
//       rental.isExclusive,
//       rental.unlockedCount,
//       rental.leadQualityScore
//     );

//     const rentalItem = {
//       id: rental.id,
//       name: `${rental.brand} ${rental.model}`,
//       dailyRate: rental.dailyRate,
//       numberOfDays: rental.numberOfDays,
//       isExclusive: rental.unlockedCount === 0,
//       unlockedCount: rental.unlockedCount,
//       leadQualityScore: rental.leadQualityScore,
//       price: leadCost, // Store the calculated lead cost
//       rentingCity: rental.rentingCity, // Include renting city
//       rentingState: rental.rentingState // Include renting state
//     };
//     addToCart(rentalItem); // Add the rental item to the cart
//   };

//   const now = new Date();
//   const exclusiveLeads = pendingRentalsData.filter((rental) => {
//     const timeDiff = now - new Date(rental.createdAt);
//     return timeDiff <= 72 * 60 * 60 * 1000; // Within 72 hours
//   });

//   const openLeads = pendingRentalsData.filter((rental) => {
//     const timeDiff = now - new Date(rental.createdAt);
//     return timeDiff > 72 * 60 * 60 * 1000; // After 72 hours
//   });

//   return (
//     <StyledBox>
//       <NeonText variant="h4">Pending Leads</NeonText>
//       <SubText variant="subtitle1">These are your rental leads awaiting confirmation:</SubText>

//       <Typography variant="h6" sx={{ color: '#23d5ab', marginBottom: '10px' }}>
//         Exclusive Leads (72 hours):
//       </Typography>
//       <List>
//         {exclusiveLeads.map((rental) => {
//           const leadCost = calculateLeadCost(
//             rental.dailyRate,
//             rental.numberOfDays,
//             1,
//             rental.unlockedCount === 0,
//             rental.unlockedCount,
//             rental.leadQualityScore
//           );

//           return (
//             <React.Fragment key={rental.id}>
//               <StyledListItem>
//                 <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
//                   <Box onClick={() => handleOpen(rental)} flexGrow={1}>
//                     <ListItemText
//                       primary={formatCustomerData(rental.customerName, rental.state, rental.phone)}
//                       secondary={
//                         <>
//                           <span style={{ color: '#23d5ab', fontWeight: 'bold' }}>
//                             {`Lead Quality Score: ${rental.leadQualityScore}`}
//                           </span>
//                           <span style={{ color: '#23d5ab', fontWeight: 'bold', marginLeft: '10px' }}>
//                             Exclusive
//                           </span>
//                           <span style={{ display: 'block', color: '#23d5ab' }}>
//                             {`Renting in: ${rental.rentingCity}, ${rental.rentingState}`}
//                           </span>
//                         </>
//                       }
//                       primaryTypographyProps={{ style: { color: '#fff', fontWeight: 'bold' } }}
//                     />
//                   </Box>
//                   <Typography variant="body2" sx={{ color: '#23d5ab', fontWeight: 'bold', marginRight: '15px' }}>
//                     {`Cost: $${leadCost.toFixed(2)}`}
//                   </Typography>
//                   <IconButton
//                     edge="end"
//                     color="inherit"
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       handleAddToCart(rental);
//                     }}
//                     style={{ color: '#23d5ab' }}
//                   >
//                     <ShoppingCartIcon />
//                   </IconButton>
//                 </Box>
//               </StyledListItem>
//               <StyledDivider />
//             </React.Fragment>
//           );
//         })}
//       </List>

//       <Typography variant="h6" sx={{ color: '#f72585', marginBottom: '10px', marginTop: '20px' }}>
//         Open Leads:
//       </Typography>
//       <List>
//         {openLeads.map((rental) => {
//           const leadCost = calculateLeadCost(
//             rental.dailyRate,
//             rental.numberOfDays,
//             1,
//             rental.unlockedCount === 0,
//             rental.unlockedCount,
//             rental.leadQualityScore
//           );

//           return (
//             <React.Fragment key={rental.id}>
//               <StyledListItem>
//                 <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
//                   <Box onClick={() => handleOpen(rental)} flexGrow={1}>
//                     <ListItemText
//                       primary={formatCustomerData(rental.customerName, rental.state, rental.phone)}
//                       secondary={
//                         <>
//                           <span style={{ color: '#f72585', fontWeight: 'bold' }}>
//                             {`Lead Quality Score: ${rental.leadQualityScore}`}
//                           </span>
//                           <span style={{ display: 'block', color: '#f72585' }}>
//                             {`Renting in: ${rental.rentingCity}, ${rental.rentingState}`}
//                           </span>
//                         </>
//                       }
//                       primaryTypographyProps={{ style: { color: '#fff', fontWeight: 'bold' } }}
//                     />
//                   </Box>
//                   <Typography variant="body2" sx={{ color: '#f72585', fontWeight: 'bold', marginRight: '15px' }}>
//                     {`Cost: $${leadCost.toFixed(2)}`}
//                   </Typography>
//                   <IconButton
//                     edge="end"
//                     color="inherit"
//                     onClick={(e) => {
//                       e.stopPropagation();
//                       handleAddToCart(rental);
//                     }}
//                     style={{ color: '#f72585' }}
//                   >
//                     <ShoppingCartIcon />
//                   </IconButton>
//                 </Box>
//               </StyledListItem>
//               <StyledDivider />
//             </React.Fragment>
//           );
//         })}
//       </List>

//       <Modal open={Boolean(selectedRental)} onClose={handleClose}>
//         <ModalContent>
//           {selectedRental && (
//             <>
//               <Typography variant="h6" gutterBottom>
//                 Approve or Deny Rental
//               </Typography>
//               <Typography variant="body1" gutterBottom>
//                 {formatCustomerData(selectedRental.customerName, selectedRental.state, selectedRental.phone)}
//               </Typography>
//               <Typography variant="body2" gutterBottom>
//                 Car: {selectedRental.brand} {selectedRental.model}
//               </Typography>
//               <Typography variant="body2" gutterBottom>
//                 Rental Period: {selectedRental.fromDate} to {selectedRental.toDate}
//               </Typography>
//               <Typography variant="body2" gutterBottom>
//                 Number of Days: {selectedRental.numberOfDays}
//               </Typography>
//               <Box mt={3}>
//                 <Button
//                   variant="contained"
//                   color="primary"
//                   onClick={handleApprove}
//                   style={{ marginRight: '10px', backgroundColor: '#23d5ab', boxShadow: '0 0 10px #23d5ab' }}
//                 >
//                   Approve
//                 </Button>
//                 <Button
//                   variant="contained"
//                   color="secondary"
//                   onClick={handleClose}
//                   style={{ backgroundColor: '#f72585', boxShadow: '0 0 10px #f72585' }}
//                 >
//                   Deny
//                 </Button>
//               </Box>
//             </>
//           )}
//         </ModalContent>
//       </Modal>
//     </StyledBox>
//   );
// };

// export default PendingRentals;




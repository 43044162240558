import React from 'react';
import { Box, Typography, Button, Modal } from '@mui/material';
import useStyles from './PurchasePageStyles';

const PurchaseModal = ({ modalOpen, handleModalClose, cartItems, totalPrice, copied, handleCopyToClipboard }) => {
  const classes = useStyles();

  return (
    <Modal open={modalOpen} onClose={handleModalClose}>
      <Box className={classes.modalBox}>
        <Typography variant="h5" gutterBottom className={classes.modalTitle}>
          Purchase Successful!
        </Typography>
        
        {/* Loop through the cart items and display their details */}
        {cartItems.map((item, index) => (
          <Box key={index} mb={2}>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Customer: {item.customerName}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Car: {item.name}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Rental Period: {item.fromDate} to {item.toDate}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Total Days: {item.numberOfDays}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Lead Quality Score: {item.leadQualityScore}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Lead Type: {item.isExclusive ? 'Exclusive' : 'Non-Exclusive'}
            </Typography>
            <Typography variant="body1" gutterBottom className={classes.modalText}>
              Renting In: {item.rentingCity}, {item.rentingState}
            </Typography>
            {/* Display individual lead cost */}
            <Typography variant="h6" gutterBottom className={classes.modalLeadCost}>
              Lead Cost: ${item.price.toFixed(2)}
            </Typography>
          </Box>
        ))}

        {/* Display the total price passed from the Cart */}
        <Typography variant="h5" gutterBottom className={classes.totalCost}>
          Total Price: ${totalPrice.toFixed(2)}
        </Typography>

        <Button
          variant="contained"
          onClick={handleCopyToClipboard}
          className={classes.copyButton}
        >
          {copied ? 'Copied!' : 'Copy to Clipboard'}
        </Button>
        <Button
          variant="contained"
          onClick={handleModalClose}
          className={classes.closeButton}
        >
          Close
        </Button>
      </Box>
    </Modal>
  );
};

export default PurchaseModal;




// import React from 'react';
// import { Box, Typography, Button, Modal } from '@mui/material';
// import useStyles from './PurchasePageStyles';

// const PurchaseModal = ({ modalOpen, handleModalClose, rentalData, leadCost, copied, handleCopyToClipboard, isExclusive }) => {
//   const classes = useStyles();

//   return (
//     <Modal open={modalOpen} onClose={handleModalClose}>
//       <Box className={classes.modalBox}>
//         <Typography variant="h5" gutterBottom className={classes.modalTitle}>
//           Purchase Successful!
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Customer: {rentalData.customerName}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Address: {rentalData.customerAddress || 'N/A'}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Phone: {rentalData.customerPhone || 'N/A'}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Email: {rentalData.customerEmail || 'N/A'}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Insurance: {rentalData.hasInsurance ? 'Yes' : 'No'}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Valid Driver’s License: {rentalData.validLicense ? 'Yes' : 'No'}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Rental Period: {rentalData.fromDate} to {rentalData.toDate}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Total Days: {rentalData.numberOfDays}
//         </Typography>
//         <Typography variant="body1" gutterBottom className={classes.modalText}>
//           Lead Type: {isExclusive ? 'Exclusive' : 'Non-Exclusive'}
//         </Typography>
//         <Typography variant="h6" gutterBottom className={classes.modalLeadCost}>
//           Lead Cost: ${leadCost.toFixed(2)}
//         </Typography>

//         <Button
//           variant="contained"
//           onClick={handleCopyToClipboard}
//           className={classes.copyButton}
//         >
//           {copied ? 'Copied!' : 'Copy to Clipboard'}
//         </Button>
//         <Button
//           variant="contained"
//           onClick={handleModalClose}
//           className={classes.closeButton}
//         >
//           Close
//         </Button>
//       </Box>
//     </Modal>
//   );
// };

// export default PurchaseModal;

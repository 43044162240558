// Helper function to check if a date is within a range
// Helper function to check if a date is within a range
const isDateInRange = (date, startDate, endDate) => {
  return date >= startDate && date <= endDate;
};

const checkIfPeakSeason = () => {
  const currentDate = new Date();
  const month = currentDate.getMonth();

  const summerStart = new Date(currentDate.getFullYear(), 5, 1); // June 1st
  const summerEnd = new Date(currentDate.getFullYear(), 8, 30);  // September 30th
  const winterStart = new Date(currentDate.getFullYear(), 11, 1); // December 1st
  const winterEnd = new Date(currentDate.getFullYear() + 1, 1, 28); // February 28th (next year)

  if (isDateInRange(currentDate, summerStart, summerEnd) || isDateInRange(currentDate, winterStart, winterEnd)) {
    return true;
  }

  const holidays = [
    new Date(currentDate.getFullYear(), 11, 25), // Christmas
    new Date(currentDate.getFullYear(), 0, 1),   // New Year's
    new Date(currentDate.getFullYear(), 10, 24), // Thanksgiving
  ];

  return holidays.some(holiday => currentDate.getTime() === holiday.getTime());
};

const calculateLeadCost = (price, numberOfDays, locationFactor = 1, unlockedCount = 0, leadQualityScore = 50, createdAt) => {
  if (price && numberOfDays) {
    const pricePerDay = typeof price === 'number' ? price : parseFloat(price.replace(/[^0-9.-]+/g, ""));
    if (!isNaN(pricePerDay)) {
      let dailyCost = 0;

      if (pricePerDay < 500) dailyCost = 5;
      else if (pricePerDay >= 500 && pricePerDay < 1500) dailyCost = 10;
      else if (pricePerDay >= 1500 && pricePerDay < 2500) dailyCost = 15;
      else if (pricePerDay >= 2500 && pricePerDay < 5000) dailyCost = 25;
      else dailyCost = 50;

      let totalLeadCost = dailyCost * numberOfDays;

      if (numberOfDays > 30) totalLeadCost *= 0.85;
      else if (numberOfDays > 14) totalLeadCost *= 0.90;
      else if (numberOfDays > 7) totalLeadCost *= 0.95;

      if (checkIfPeakSeason()) totalLeadCost *= 1.10;

      const currentTime = new Date();
      const createdTime = new Date(createdAt);
      const timeDiff = currentTime - createdTime;
      const hoursPassed = timeDiff / (1000 * 60 * 60);

      if (hoursPassed <= 72 && unlockedCount === 0) {
        totalLeadCost *= 1.20; // Exclusive leads within 72 hours and unlockedCount is 0
      } else if (hoursPassed > 72) {
        totalLeadCost *= 0.85; // Reduce price for older leads (after 72 hours)
      }

      if (unlockedCount > 0) {
        let discount = 0.25; // First time it's sold: 25% off
        if (unlockedCount > 1) discount += 0.10 * (unlockedCount - 1); // Additional 10% off for each subsequent sale
        discount = Math.min(discount, 0.50); // Max discount of 50%
        totalLeadCost *= 1 - discount;
      }

      totalLeadCost *= locationFactor;

      const qualityAdjustmentFactor = (leadQualityScore / 100).toFixed(2);
      totalLeadCost *= qualityAdjustmentFactor;

      return totalLeadCost;
    } else {
      console.error("Invalid price format:", price);
      return 0;
    }
  }

  return 0;
};

export default calculateLeadCost;

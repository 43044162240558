// List of U.S. States
export const states = [
    { label: 'California' },
    { label: 'Florida' },
    { label: 'New York' },
    { label: 'Texas' },
    { label: 'Illinois' },
    { label: 'Pennsylvania' },
    { label: 'Ohio' },
    { label: 'Georgia' },
    { label: 'North Carolina' },
    { label: 'Michigan' },
    { label: 'New Jersey' },
    { label: 'Virginia' },
    { label: 'Washington' },
    { label: 'Arizona' },
    { label: 'Massachusetts' },
    { label: 'Tennessee' },
    { label: 'Indiana' },
    { label: 'Missouri' },
    { label: 'Maryland' },
    { label: 'Wisconsin' },
    { label: 'Colorado' },
    { label: 'Minnesota' },
    { label: 'South Carolina' },
    { label: 'Alabama' },
    { label: 'Louisiana' },
    { label: 'Kentucky' },
    { label: 'Oregon' },
    { label: 'Oklahoma' },
    { label: 'Connecticut' },
    { label: 'Iowa' },
    { label: 'Mississippi' },
    { label: 'Arkansas' },
    { label: 'Kansas' },
    { label: 'Nevada' },
    { label: 'Utah' },
    { label: 'Nebraska' },
    { label: 'West Virginia' },
    { label: 'New Mexico' },
    { label: 'Hawaii' },
    { label: 'Idaho' },
    { label: 'Maine' },
    { label: 'New Hampshire' },
    { label: 'Montana' },
    { label: 'Rhode Island' },
    { label: 'Delaware' },
    { label: 'South Dakota' },
    { label: 'North Dakota' },
    { label: 'Wyoming' },
    { label: 'Vermont' },
    { label: 'Alaska' }
  ];
  
  // List of cities mapped to states
  export const cities = [
    { label: 'Los Angeles', state: 'California' },
    { label: 'San Francisco', state: 'California' },
    { label: 'San Diego', state: 'California' },
    { label: 'San Jose', state: 'California' },
    { label: 'Sacramento', state: 'California' },
    { label: 'Miami', state: 'Florida' },
    { label: 'Orlando', state: 'Florida' },
    { label: 'Tampa', state: 'Florida' },
    { label: 'Jacksonville', state: 'Florida' },
    { label: 'St. Petersburg', state: 'Florida' },
    { label: 'New York City', state: 'New York' },
    { label: 'Buffalo', state: 'New York' },
    { label: 'Rochester', state: 'New York' },
    { label: 'Syracuse', state: 'New York' },
    { label: 'Albany', state: 'New York' },
    { label: 'Houston', state: 'Texas' },
    { label: 'Dallas', state: 'Texas' },
    { label: 'Austin', state: 'Texas' },
    { label: 'San Antonio', state: 'Texas' },
    { label: 'Fort Worth', state: 'Texas' },
    { label: 'Chicago', state: 'Illinois' },
    { label: 'Aurora', state: 'Illinois' },
    { label: 'Naperville', state: 'Illinois' },
    { label: 'Joliet', state: 'Illinois' },
    { label: 'Elgin', state: 'Illinois' },
    { label: 'Philadelphia', state: 'Pennsylvania' },
    { label: 'Pittsburgh', state: 'Pennsylvania' },
    { label: 'Allentown', state: 'Pennsylvania' },
    { label: 'Erie', state: 'Pennsylvania' },
    { label: 'Reading', state: 'Pennsylvania' },
    { label: 'Columbus', state: 'Ohio' },
    { label: 'Cleveland', state: 'Ohio' },
    { label: 'Cincinnati', state: 'Ohio' },
    { label: 'Toledo', state: 'Ohio' },
    { label: 'Akron', state: 'Ohio' },
    { label: 'Atlanta', state: 'Georgia' },
    { label: 'Augusta', state: 'Georgia' },
    { label: 'Columbus', state: 'Georgia' },
    { label: 'Macon', state: 'Georgia' },
    { label: 'Savannah', state: 'Georgia' },
    { label: 'Charlotte', state: 'North Carolina' },
    { label: 'Raleigh', state: 'North Carolina' },
    { label: 'Greensboro', state: 'North Carolina' },
    { label: 'Durham', state: 'North Carolina' },
    { label: 'Winston-Salem', state: 'North Carolina' },
    { label: 'Detroit', state: 'Michigan' },
    { label: 'Grand Rapids', state: 'Michigan' },
    { label: 'Warren', state: 'Michigan' },
    { label: 'Sterling Heights', state: 'Michigan' },
    { label: 'Ann Arbor', state: 'Michigan' },
    { label: 'Jersey City', state: 'New Jersey' },
    { label: 'Newark', state: 'New Jersey' },
    { label: 'Paterson', state: 'New Jersey' },
    { label: 'Elizabeth', state: 'New Jersey' },
    { label: 'Edison', state: 'New Jersey' },
    { label: 'Virginia Beach', state: 'Virginia' },
    { label: 'Norfolk', state: 'Virginia' },
    { label: 'Chesapeake', state: 'Virginia' },
    { label: 'Richmond', state: 'Virginia' },
    { label: 'Hampton', state: 'Virginia' },
    { label: 'Seattle', state: 'Washington' },
    { label: 'Spokane', state: 'Washington' },
    { label: 'Tacoma', state: 'Washington' },
    { label: 'Vancouver', state: 'Washington' },
    { label: 'Bellevue', state: 'Washington' },
    { label: 'Phoenix', state: 'Arizona' },
    { label: 'Tucson', state: 'Arizona' },
    { label: 'Mesa', state: 'Arizona' },
    { label: 'Chandler', state: 'Arizona' },
    { label: 'Scottsdale', state: 'Arizona' },
    { label: 'Boston', state: 'Massachusetts' },
    { label: 'Worcester', state: 'Massachusetts' },
    { label: 'Springfield', state: 'Massachusetts' },
    { label: 'Cambridge', state: 'Massachusetts' },
    { label: 'Lowell', state: 'Massachusetts' },
    { label: 'Nashville', state: 'Tennessee' },
    { label: 'Memphis', state: 'Tennessee' },
    { label: 'Knoxville', state: 'Tennessee' },
    { label: 'Chattanooga', state: 'Tennessee' },
    { label: 'Jackson', state: 'Tennessee' },
    { label: 'Indianapolis', state: 'Indiana' },
    { label: 'Fort Wayne', state: 'Indiana' },
    { label: 'Evansville', state: 'Indiana' },
    { label: 'South Bend', state: 'Indiana' },
    { label: 'Carmel', state: 'Indiana' },
    { label: 'St. Louis', state: 'Missouri' },
    { label: 'Kansas City', state: 'Missouri' },
    { label: 'Columbia', state: 'Missouri' },
    { label: 'Springfield', state: 'Missouri' },
    { label: 'Independence', state: 'Missouri' },
    { label: 'Baltimore', state: 'Maryland' },
    { label: 'Frederick', state: 'Maryland' },
    { label: 'Rockville', state: 'Maryland' },
    { label: 'Gaithersburg', state: 'Maryland' },
    { label: 'Bowie', state: 'Maryland' },
    { label: 'Milwaukee', state: 'Wisconsin' },
    { label: 'Madison', state: 'Wisconsin' },
    { label: 'Green Bay', state: 'Wisconsin' },
    { label: 'Kenosha', state: 'Wisconsin' },
    { label: 'Racine', state: 'Wisconsin' },
    { label: 'Denver', state: 'Colorado' },
    { label: 'Colorado Springs', state: 'Colorado' },
    { label: 'Aurora', state: 'Colorado' },
    { label: 'Fort Collins', state: 'Colorado' },
    { label: 'Lakewood', state: 'Colorado' },
    { label: 'Minneapolis', state: 'Minnesota' },
    { label: 'St. Paul', state: 'Minnesota' },
    { label: 'Rochester', state: 'Minnesota' },
    { label: 'Duluth', state: 'Minnesota' },
    { label: 'Bloomington', state: 'Minnesota' },
    { label: 'Charleston', state: 'South Carolina' },
    { label: 'Columbia', state: 'South Carolina' },
    { label: 'Greenville', state: 'South Carolina' },
    { label: 'Myrtle Beach', state: 'South Carolina' },
    { label: 'Spartanburg', state: 'South Carolina' },
    { label: 'Birmingham', state: 'Alabama' },
    { label: 'Montgomery', state: 'Alabama' },
    { label: 'Mobile', state: 'Alabama' },
    { label: 'Huntsville', state: 'Alabama' },
    { label: 'Tuscaloosa', state: 'Alabama' },
    { label: 'New Orleans', state: 'Louisiana' },
    { label: 'Baton Rouge', state: 'Louisiana' },
    { label: 'Shreveport', state: 'Louisiana' },
    { label: 'Lafayette', state: 'Louisiana' },
    { label: 'Lake Charles', state: 'Louisiana' },
    { label: 'Louisville', state: 'Kentucky' },
    { label: 'Lexington', state: 'Kentucky' },
    { label: 'Bowling Green', state: 'Kentucky' },
    { label: 'Owensboro', state: 'Kentucky' },
    { label: 'Covington', state: 'Kentucky' },
    { label: 'Portland', state: 'Oregon' },
    { label: 'Salem', state: 'Oregon' },
    { label: 'Eugene', state: 'Oregon' },
    { label: 'Gresham', state: 'Oregon' },
    { label: 'Hillsboro', state: 'Oregon' },
    { label: 'Oklahoma City', state: 'Oklahoma' },
    { label: 'Tulsa', state: 'Oklahoma' },
    { label: 'Norman', state: 'Oklahoma' },
    { label: 'Edmond', state: 'Oklahoma' },
    { label: 'Broken Arrow', state: 'Oklahoma' },
    { label: 'Hartford', state: 'Connecticut' },
    { label: 'New Haven', state: 'Connecticut' },
    { label: 'Stamford', state: 'Connecticut' },
    { label: 'Bridgeport', state: 'Connecticut' },
    { label: 'Waterbury', state: 'Connecticut' },
    { label: 'Des Moines', state: 'Iowa' },
    { label: 'Cedar Rapids', state: 'Iowa' },
    { label: 'Davenport', state: 'Iowa' },
    { label: 'Sioux City', state: 'Iowa' },
    { label: 'Iowa City', state: 'Iowa' },
    { label: 'Jackson', state: 'Mississippi' },
    { label: 'Gulfport', state: 'Mississippi' },
    { label: 'Southaven', state: 'Mississippi' },
    { label: 'Biloxi', state: 'Mississippi' },
    { label: 'Hattiesburg', state: 'Mississippi' },
    { label: 'Little Rock', state: 'Arkansas' },
    { label: 'Fort Smith', state: 'Arkansas' },
    { label: 'Fayetteville', state: 'Arkansas' },
    { label: 'Springdale', state: 'Arkansas' },
    { label: 'Jonesboro', state: 'Arkansas' },
    { label: 'Wichita', state: 'Kansas' },
    { label: 'Overland Park', state: 'Kansas' },
    { label: 'Kansas City', state: 'Kansas' },
    { label: 'Olathe', state: 'Kansas' },
    { label: 'Topeka', state: 'Kansas' },
    { label: 'Las Vegas', state: 'Nevada' },
    { label: 'Reno', state: 'Nevada' },
    { label: 'Henderson', state: 'Nevada' },
    { label: 'Paradise', state: 'Nevada' },
    { label: 'Sparks', state: 'Nevada' },
    { label: 'Salt Lake City', state: 'Utah' },
    { label: 'West Valley City', state: 'Utah' },
    { label: 'Provo', state: 'Utah' },
    { label: 'Ogden', state: 'Utah' },
    { label: 'Sandy', state: 'Utah' },
    { label: 'Omaha', state: 'Nebraska' },
    { label: 'Lincoln', state: 'Nebraska' },
    { label: 'Bellevue', state: 'Nebraska' },
    { label: 'Grand Island', state: 'Nebraska' },
    { label: 'Kearney', state: 'Nebraska' },
    { label: 'Charleston', state: 'West Virginia' },
    { label: 'Huntington', state: 'West Virginia' },
    { label: 'Morgantown', state: 'West Virginia' },
    { label: 'Parkersburg', state: 'West Virginia' },
    { label: 'Weirton', state: 'West Virginia' },
    { label: 'Albuquerque', state: 'New Mexico' },
    { label: 'Santa Fe', state: 'New Mexico' },
    { label: 'Las Cruces', state: 'New Mexico' },
    { label: 'Roswell', state: 'New Mexico' },
    { label: 'Carlsbad', state: 'New Mexico' },
    { label: 'Honolulu', state: 'Hawaii' },
    { label: 'Hilo', state: 'Hawaii' },
    { label: 'Kailua', state: 'Hawaii' },
    { label: 'Waipahu', state: 'Hawaii' },
    { label: 'Pearl City', state: 'Hawaii' },
    { label: 'Boise', state: 'Idaho' },
    { label: 'Meridian', state: 'Idaho' },
    { label: 'Nampa', state: 'Idaho' },
    { label: 'Idaho Falls', state: 'Idaho' },
    { label: 'Pocatello', state: 'Idaho' },
    { label: 'Portland', state: 'Maine' },
    { label: 'Lewiston', state: 'Maine' },
    { label: 'Bangor', state: 'Maine' },
    { label: 'South Portland', state: 'Maine' },
    { label: 'Auburn', state: 'Maine' },
    { label: 'Manchester', state: 'New Hampshire' },
    { label: 'Nashua', state: 'New Hampshire' },
    { label: 'Concord', state: 'New Hampshire' },
    { label: 'Derry', state: 'New Hampshire' },
    { label: 'Keene', state: 'New Hampshire' },
    { label: 'Billings', state: 'Montana' },
    { label: 'Missoula', state: 'Montana' },
    { label: 'Great Falls', state: 'Montana' },
    { label: 'Bozeman', state: 'Montana' },
    { label: 'Butte', state: 'Montana' },
    { label: 'Providence', state: 'Rhode Island' },
    { label: 'Cranston', state: 'Rhode Island' },
    { label: 'Warwick', state: 'Rhode Island' },
    { label: 'Pawtucket', state: 'Rhode Island' },
    { label: 'East Providence', state: 'Rhode Island' },
    { label: 'Dover', state: 'Delaware' },
    { label: 'Wilmington', state: 'Delaware' },
    { label: 'Newark', state: 'Delaware' },
    { label: 'Middletown', state: 'Delaware' },
    { label: 'Georgetown', state: 'Delaware' },
    { label: 'Sioux Falls', state: 'South Dakota' },
    { label: 'Rapid City', state: 'South Dakota' },
    { label: 'Aberdeen', state: 'South Dakota' },
    { label: 'Brookings', state: 'South Dakota' },
    { label: 'Yankton', state: 'South Dakota' },
    { label: 'Fargo', state: 'North Dakota' },
    { label: 'Bismarck', state: 'North Dakota' },
    { label: 'Grand Forks', state: 'North Dakota' },
    { label: 'Minot', state: 'North Dakota' },
    { label: 'West Fargo', state: 'North Dakota' },
    { label: 'Cheyenne', state: 'Wyoming' },
    { label: 'Casper', state: 'Wyoming' },
    { label: 'Laramie', state: 'Wyoming' },
    { label: 'Gillette', state: 'Wyoming' },
    { label: 'Rock Springs', state: 'Wyoming' },
    { label: 'Burlington', state: 'Vermont' },
    { label: 'South Burlington', state: 'Vermont' },
    { label: 'Rutland', state: 'Vermont' },
    { label: 'Barre', state: 'Vermont' },
    { label: 'Stowe', state: 'Vermont' },
    { label: 'Anchorage', state: 'Alaska' },
    { label: 'Fairbanks', state: 'Alaska' },
    { label: 'Juneau', state: 'Alaska' },
    { label: 'Wasilla', state: 'Alaska' },
    { label: 'Sitka', state: 'Alaska' },
    { label: 'Anchorage', state: 'Alaska' },
    { label: 'Fairbanks', state: 'Alaska' },
    { label: 'Juneau', state: 'Alaska' },
    { label: 'Wasilla', state: 'Alaska' },
    { label: 'Sitka', state: 'Alaska' },
    { label: 'Auburn', state: 'Alabama' },
    { label: 'Selma', state: 'Alabama' },
    { label: 'Dothan', state: 'Alabama' },
    { label: 'Phenix City', state: 'Alabama' },
    { label: 'Anniston', state: 'Alabama' },
    { label: 'West Palm Beach', state: 'Florida' },
    { label: 'Fort Lauderdale', state: 'Florida' },
    { label: 'Boca Raton', state: 'Florida' },
    { label: 'Palm Bay', state: 'Florida' },
    { label: 'Lakeland', state: 'Florida' },
    { label: 'Macon', state: 'Georgia' },
    { label: 'Athens', state: 'Georgia' },
    { label: 'Marietta', state: 'Georgia' },
    { label: 'Sandy Springs', state: 'Georgia' },
    { label: 'Roswell', state: 'Georgia' },
    { label: 'Wilmington', state: 'Delaware' },
    { label: 'Middletown', state: 'Delaware' },
    { label: 'Newark', state: 'Delaware' },
    { label: 'Georgetown', state: 'Delaware' },
    { label: 'Dover', state: 'Delaware' },
    { label: 'Savannah', state: 'Georgia' },
    { label: 'Gainesville', state: 'Georgia' },
    { label: 'Macon', state: 'Georgia' },
    { label: 'Tifton', state: 'Georgia' },
    { label: 'Americus', state: 'Georgia' },
    { label: 'Fayetteville', state: 'North Carolina' },
    { label: 'Gastonia', state: 'North Carolina' },
    { label: 'Cary', state: 'North Carolina' },
    { label: 'Mooresville', state: 'North Carolina' },
    { label: 'Huntersville', state: 'North Carolina' },
    { label: 'Greenville', state: 'South Carolina' },
    { label: 'Florence', state: 'South Carolina' },
    { label: 'Hilton Head Island', state: 'South Carolina' },
    { label: 'Sumter', state: 'South Carolina' },
    { label: 'Aiken', state: 'South Carolina' },
    { label: 'Rapid City', state: 'South Dakota' },
    { label: 'Aberdeen', state: 'South Dakota' },
    { label: 'Brookings', state: 'South Dakota' },
    { label: 'Yankton', state: 'South Dakota' },
    { label: 'Sioux Falls', state: 'South Dakota' },
    { label: 'Bismarck', state: 'North Dakota' },
    { label: 'Grand Forks', state: 'North Dakota' },
    { label: 'Minot', state: 'North Dakota' },
    { label: 'Fargo', state: 'North Dakota' },
    { label: 'West Fargo', state: 'North Dakota' },
    { label: 'Cheyenne', state: 'Wyoming' },
    { label: 'Casper', state: 'Wyoming' },
    { label: 'Laramie', state: 'Wyoming' },
    { label: 'Gillette', state: 'Wyoming' },
    { label: 'Rock Springs', state: 'Wyoming' },
    { label: 'Gillette', state: 'Wyoming' },
    { label: 'Rock Springs', state: 'Wyoming' },
    { label: 'Montpelier', state: 'Vermont' },
    { label: 'Middlebury', state: 'Vermont' },
    { label: 'Stowe', state: 'Vermont' },
    { label: 'Shelburne', state: 'Vermont' },
    { label: 'Bennington', state: 'Vermont' },
    { label: 'Dawsonville', state: 'Georgia' },
    { label: 'Dalton', state: 'Georgia' },
    { label: 'Gainesville', state: 'Georgia' },
    { label: 'Rome', state: 'Georgia' },
    { label: 'Athens', state: 'Georgia' },
    { label: 'Newport News', state: 'Virginia' },
    { label: 'Falls Church', state: 'Virginia' },
    { label: 'Alexandria', state: 'Virginia' },
    { label: 'Manassas', state: 'Virginia' },
    { label: 'Lynchburg', state: 'Virginia' },
    { label: 'Springfield', state: 'Missouri' },
    { label: 'Lee’s Summit', state: 'Missouri' },
    { label: 'O’Fallon', state: 'Missouri' },
    { label: 'St. Peters', state: 'Missouri' },
    { label: 'Cape Girardeau', state: 'Missouri' },
    { label: 'Richland', state: 'Washington' },
    { label: 'Puyallup', state: 'Washington' },
    { label: 'Auburn', state: 'Washington' },
    { label: 'Bremerton', state: 'Washington' },
    { label: 'Port Orchard', state: 'Washington' },
    { label: 'Anchorage', state: 'Alaska' },
    { label: 'Wasilla', state: 'Alaska' },
    { label: 'Sitka', state: 'Alaska' },
    { label: 'Juneau', state: 'Alaska' },
    { label: 'Fairbanks', state: 'Alaska' },
    { label: 'Juneau', state: 'Alaska' },
    { label: 'Sitka', state: 'Alaska' },
    { label: 'Fairbanks', state: 'Alaska' },
    { label: 'Wasilla', state: 'Alaska' },
    { label: 'Long Beach', state: 'California' },
  { label: 'Oakland', state: 'California' },
  { label: 'Bakersfield', state: 'California' },
  { label: 'Fresno', state: 'California' },
  { label: 'Irvine', state: 'California' },
  { label: 'Santa Ana', state: 'California' },
  { label: 'Ontario', state: 'California' },
  { label: 'Chula Vista', state: 'California' },
  { label: 'Fontana', state: 'California' },
  { label: 'Riverside', state: 'California' },
  { label: 'San Bernardino', state: 'California' },
  { label: 'Santa Clara', state: 'California' },
  { label: 'Modesto', state: 'California' },
  { label: 'Garden Grove', state: 'California' },
  { label: 'Costa Mesa', state: 'California' },

  // Florida
  { label: 'Hialeah', state: 'Florida' },
  { label: 'Fort Myers', state: 'Florida' },
  { label: 'Gainesville', state: 'Florida' },
  { label: 'Cape Coral', state: 'Florida' },
  { label: 'Palm Coast', state: 'Florida' },
  { label: 'Sarasota', state: 'Florida' },
  { label: 'Deltona', state: 'Florida' },
  { label: 'Homestead', state: 'Florida' },
  { label: 'Melbourne', state: 'Florida' },
  { label: 'Oviedo', state: 'Florida' },
  { label: 'Wellington', state: 'Florida' },
  { label: 'Port St. Lucie', state: 'Florida' },
  { label: 'Naples', state: 'Florida' },
  { label: 'Daytona Beach', state: 'Florida' },
  { label: 'Tallahassee', state: 'Florida' },

  // New York
  { label: 'Schenectady', state: 'New York' },
  { label: 'Saratoga Springs', state: 'New York' },
  { label: 'Ithaca', state: 'New York' },
  { label: 'Yonkers', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Albany', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' },
  { label: 'Syracuse', state: 'New York' }
  ];
  
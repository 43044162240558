import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageContainer: {
    backgroundColor: '#111', // Dark background
    color: '#fff',
    padding: '40px',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '800px',
    margin: 'auto',
    marginTop: '50px',
    boxShadow: '0px 0px 50px 10px rgba(35,213,171,0.8)', // Neon glow effect
    textAlign: 'left',
    '@media (max-width: 768px)': {
      padding: '20px',
      marginTop: '20px',
    },
    '@media (max-width: 480px)': {
      padding: '15px',
      marginTop: '15px',
    },
  },
  title: {
    color: '#23d5ab',
    textShadow: '0 0 15px #23d5ab, 0 0 30px #23d5ab',
    marginBottom: '20px',
    fontSize: '3rem',
    textAlign: 'center',
    '@media (max-width: 768px)': {
      fontSize: '2.5rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '2rem',
    },
  },
  subheading: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
    marginBottom: '10px',
    fontSize: '1.5rem',
    '@media (max-width: 768px)': {
      fontSize: '1.3rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1.2rem',
    },
  },
  paragraph: {
    color: '#fff',
    fontSize: '1.1rem',
    lineHeight: '1.8',
    marginBottom: '20px',
    '@media (max-width: 768px)': {
      fontSize: '1rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.9rem',
    },
  },
  list: {
    paddingLeft: '20px',
    color: '#23d5ab',
    fontSize: '1rem',
    marginTop: '10px',
    '@media (max-width: 480px)': {
      fontSize: '0.9rem',
    },
    '& li': {
      marginBottom: '10px',
    },
  },
  buttonContainer: {
    marginTop: '30px',
    textAlign: 'center',
  },
  acceptButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontSize: '1.2rem',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#1cb993',
    },
    boxShadow: '0px 0px 15px 5px rgba(35,213,171,0.5)', // Neon glow for buttons
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      padding: '8px 16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.9rem',
      padding: '6px 12px',
    },
  },
  logo: {
    width: '150px',  // Adjust size as needed
    marginBottom: '20px',
    display: 'block',
    margin: '0 auto',  // Centers the image
    '@media (max-width: 768px)': {
      width: '120px',  // Adjust size for smaller screens
    },
    '@media (max-width: 480px)': {
      width: '100px',  // Adjust size for mobile screens
    },
  },
}));

export default useStyles;

import React from 'react';
import { Typography, Box, IconButton, Link } from '@mui/material';
import { Facebook, Instagram, Twitter, YouTube } from '@mui/icons-material';
import useStyles from './FooterStyles'; // Import the styles from the separate file
import { Link as RouterLink } from 'react-router-dom'; // For internal routing

const Footer = () => {
  const classes = useStyles();

  return (
    <footer className={classes.footer}>
      {/* Social Media Icons */}
      <Box className={classes.socialIcons}>
        <IconButton aria-label="Facebook" className={classes.iconButton}>
          <Facebook fontSize="large" />
        </IconButton>
        <IconButton aria-label="Instagram" className={classes.iconButton}>
          <Instagram fontSize="large" />
        </IconButton>
        <IconButton aria-label="Twitter" className={classes.iconButton}>
          <Twitter fontSize="large" />
        </IconButton>
        <IconButton aria-label="YouTube" className={classes.iconButton}>
          <YouTube fontSize="large" />
        </IconButton>
      </Box>

      {/* Footer Links */}
      <Box className={classes.footerLinks}>
        <Link component={RouterLink} 
        to="/" 
        className={classes.footerLink}>
          Home
        </Link>
        <Link
          component={RouterLink}
          to="/about-us" // Add the About Us route
          className={classes.footerLink}
        >
          About Us
        </Link>
        <Link
          component={RouterLink}
          to="/terms-and-conditions"
          className={classes.footerLink}
        >
          Terms & Conditions
        </Link>
        <Link
          component={RouterLink}
          to="/blog"
          className={classes.footerLink}
        >
          Blog
        </Link>
      </Box>

      {/* Copyright Text */}
      <Typography variant="body2" className={classes.footerText}>
        © 2024 VICE CITY VIP. All rights reserved.
      </Typography>
    </footer>
  );
};

export default Footer;

// CalendarModalStyles.js
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  calendarModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%', // Ensures it fits on smaller screens
    maxWidth: '600px',
    backgroundColor: '#111',
    border: '2px solid #f72585',
    boxShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
    padding: '20px', // Adjusted for smaller screens
    textAlign: 'center',
    borderRadius: '10px',
    zIndex: 1300,
    animation: '$zoomIn 0.5s ease-in-out',
  },
  neonText: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '1.8rem', // Adjust font size for better fit
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginBottom: '20px',
  },
  dateInputBox: {
    margin: '10px 0', // Adjusted for spacing on small screens
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
    borderRadius: '8px',
    backgroundColor: '#000',
    boxShadow: '0 0 15px #23d5ab',
  },
  dateInput: {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#23d5ab',
    fontSize: '1rem', // Adjust font size for better fit
    outline: 'none',
    textAlign: 'center',
    '&::placeholder': {
      color: '#f72585',
      fontSize: '0.9rem',
    },
  },
  confirmButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontWeight: 700,
    margin: '10px 0', // Reduced margin for smaller screens
    padding: '10px 20px',
    border: '2px solid #f72585',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 40px #23d5ab, 0 0 80px #f72585',
      transform: 'scale(1.05)',
    },
    width: '100%', // Ensure the button is responsive
  },
  skipButton: {
    backgroundColor: '#f72585',
    color: '#fff',
    fontWeight: 700,
    margin: '10px 0', // Reduced margin for smaller screens
    padding: '10px 20px',
    border: '2px solid #23d5ab',
    boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      backgroundColor: '#23d5ab',
      boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab',
      transform: 'scale(1.05)',
    },
    width: '100%', // Ensure the button is responsive
  },
  closeButton: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 5px #23d5ab, 0 0 15px #23d5ab',
    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',
    },
    marginTop: '10px',
    width: '100%', // Ensure the button is responsive
  },
  '@keyframes zoomIn': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },
}));

export default useStyles;


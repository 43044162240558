import { useState, useEffect } from 'react';
import { ref, get } from 'firebase/database';
import { realtimeDb } from '../firebase';
import { states as allStates, cities } from '../components/data/statesAndCities'; // Importing state data

const useSearch = () => {
    const [state, setState] = useState(null);
    const [city, setCity] = useState(null);
    const [filteredCities, setFilteredCities] = useState(cities);
    const [filteredCars, setFilteredCars] = useState([]);
    const [cars, setCars] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showAdvanced, setShowAdvanced] = useState(false);
    const [make, setMake] = useState(null);
    const [model, setModel] = useState(null);
    const [filteredModels, setFilteredModels] = useState([]);
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [carMakes, setCarMakes] = useState([]); // For storing unique car makes
    const [isSearchTriggered, setIsSearchTriggered] = useState(false);

    const states = allStates;

    // Fetch cars from Realtime Database and extract unique car makes
    useEffect(() => {
        const fetchCarsFromFirebase = async () => {
            setLoading(true);
            try {
                const carsRef = ref(realtimeDb, 'listings');
                const snapshot = await get(carsRef);
                if (snapshot.exists()) {
                    const carData = snapshot.val();
                    const carArray = Object.keys(carData).reduce((acc, userId) => {
                        const userListings = Object.keys(carData[userId]).map(listingId => ({
                            id: listingId,
                            ...carData[userId][listingId],
                        }));
                        return [...acc, ...userListings];
                    }, []);
                    
                    setCars(carArray);
                    
                    // Extract unique car makes from the cars data
                    const uniqueMakes = [...new Set(carArray.map(car => car.make))].filter(Boolean);
                    const formattedMakes = uniqueMakes.map(make => ({ label: make }));
                    setCarMakes(formattedMakes); // Set carMakes for the dropdown
                } else {
                    console.error('No car data available');
                }
            } catch (error) {
                console.error('Error fetching car data:', error);
            }
            setLoading(false);
        };

        fetchCarsFromFirebase();
    }, []);

    const handleStateChange = (event, value) => {
        setState(value);
        if (value) {
            const filtered = cities.filter(city => city.state === value.label);
            setFilteredCities(filtered);
        } else {
            setFilteredCities(cities);
        }
        setCity(null);
    };

    const handleMakeChange = (event, value) => {
        setMake(value);
        const models = cars
            .filter(car => car.make && car.make.startsWith(value.label))
            .map(car => car.model || '');
        const uniqueModels = [...new Set(models)].map(model => ({ label: model }));
        setFilteredModels(uniqueModels);
        setModel(null);
    };

    const handleSearch = () => {
        setIsSearchTriggered(true);
    };

    useEffect(() => {
        if (!isSearchTriggered) return;

        setLoading(true);
        let filtered = cars;

        if (state) {
            filtered = filtered.filter(car => car.state && car.state.trim().toLowerCase() === state.label.toLowerCase());
        }
        if (city) {
            filtered = filtered.filter(car => car.city && car.city.trim().toLowerCase() === city.label.toLowerCase());
        }
        if (make) {
            filtered = filtered.filter(car => car.make && car.make.startsWith(make.label));
        }
        if (model) {
            filtered = filtered.filter(car => car.model && car.model.includes(model.label));
        }
        if (minPrice) {
            filtered = filtered.filter(car => car.pricePerDay && car.pricePerDay >= parseFloat(minPrice));
        }
        if (maxPrice) {
            filtered = filtered.filter(car => car.pricePerDay && car.pricePerDay <= parseFloat(maxPrice));
        }

        setFilteredCars(filtered);
        setLoading(false);
        setIsSearchTriggered(false);
    }, [isSearchTriggered, state, city, make, model, minPrice, maxPrice, cars]);

    return {
        state,
        setState,
        city,
        setCity,
        filteredCities,
        filteredCars,
        cars,
        loading,
        showAdvanced,
        setShowAdvanced,
        make,
        setMake,
        model,
        setModel,
        minPrice,
        setMinPrice,
        maxPrice,
        setMaxPrice,
        filteredModels,
        carMakes, // Make sure carMakes is returned
        handleStateChange,
        handleMakeChange,
        handleSearch,
        states,
    };
};

export default useSearch;








// import { useState, useEffect } from 'react';
// import { states as allStates, cities } from '../components/data/statesAndCities'; // Ensure this import is correct

// const useSearch = () => {
//     const [state, setState] = useState(null);
//     const [city, setCity] = useState(null);
//     const [filteredCities, setFilteredCities] = useState(cities);
//     const [filteredCars, setFilteredCars] = useState([]);
//     const [cars, setCars] = useState([]);
//     const [loading, setLoading] = useState(false);
//     const [showAdvanced, setShowAdvanced] = useState(false);
//     const [make, setMake] = useState(null);
//     const [model, setModel] = useState(null);
//     const [filteredModels, setFilteredModels] = useState([]);
//     const [selectedCar, setSelectedCar] = useState(null);
//     const [openModal, setOpenModal] = useState(false);
//     const [minPrice, setMinPrice] = useState(''); // Add minPrice
//     const [maxPrice, setMaxPrice] = useState(''); // Add maxPrice
  
//     useEffect(() => {
//       fetch(`${process.env.PUBLIC_URL}/cars.json`)
//         .then(response => {
//           if (!response.ok) throw new Error('Network response was not ok');
//           return response.json();
//         })
//         .then(data => setCars(data))
//         .catch(error => console.error('Error fetching car data:', error));
//     }, []);
  
//     const handleStateChange = (event, value) => {
//       setState(value);
//       if (value) {
//         const filtered = cities.filter(city => city.state === value.label);
//         setFilteredCities(filtered);
//       } else {
//         setFilteredCities(cities);
//       }
//       setCity(null);
//     };
  
//     const carMakes = [...new Set(cars.map(car => car.name.split(' ')[0]))].map(make => ({ label: make }));
  
//     const handleMakeChange = (event, value) => {
//       setMake(value);
//       const models = cars
//         .filter(car => car.name.startsWith(value.label))
//         .map(car => car.name.split(' ').slice(1).join(' '));
//       const uniqueModels = [...new Set(models)].map(model => ({ label: model }));
//       setFilteredModels(uniqueModels);
//       setModel(null);
//     };
  
//     const handleSearch = () => {
//       setLoading(true);
//       let filtered = cars;
//       if (state) filtered = filtered.filter(car => car.state.trim().toLowerCase() === state.label.toLowerCase());
//       if (city) filtered = filtered.filter(car => car.city.trim().toLowerCase() === city.label.toLowerCase());
//       if (make) filtered = filtered.filter(car => car.name.startsWith(make.label));
//       if (model) filtered = filtered.filter(car => car.name.includes(model.label));
//       setFilteredCars(filtered);
//       setLoading(false);
//     };
  
//     const handleOpenModal = (car) => {
//       setSelectedCar(car);
//       setOpenModal(true);
//     };
  
//     const handleClose = () => {
//         console.log('handleClose type:', typeof handleClose); // Should be 'function'

//         setSelectedCar(null);
//         setOpenModal(false);
//       };

//     return {
//       state,
//       setState,
//       city,
//       setCity,
//       filteredCities,
//       filteredCars,
//       cars,
//       loading,
//       showAdvanced,
//       setShowAdvanced,
//       make,
//       setMake,
//       model,
//       setModel,
//       minPrice,
//       setMinPrice,
//       maxPrice,
//       setMaxPrice,
//       filteredModels,
//       handleStateChange,
//       handleMakeChange,
//       handleSearch,
//       handleOpenModal,
//       handleClose,
//       openModal,
//       selectedCar,
//       carMakes,
//       states: allStates, // Ensure `allStates` is imported correctly
//     };
//   };
  

// export default useSearch;

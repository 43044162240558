import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import useStyles from './HomePageStyles'; // Import the styles

const HeroSection = () => {
  const classes = useStyles();
  const navigate = useNavigate(); // Initialize the navigate function

  const handleExploreCars = () => {
    navigate('/explore-cars'); // Navigate to the explore-cars route
  };

  return (
    <Box className={classes.hero}>
      {/* Overlay */}
      <Box className={classes.overlay} />

      {/* Content */}
      <Box className={classes.content}>
        <Typography className={`${classes.neonText} ${classes.typingEffect}`}>
          Own the Road, Rule the Moment
        </Typography>
        <Typography variant="h6" className={classes.subText}>
          Drive the world's most exotic and luxurious cars — your dream ride is
          just a click away.
        </Typography>

        <Button
          className={`${classes.buttonNeon} ${classes.pulsatingButton}`}
          size="large"
          style={{ marginTop: "20px" }}
          onClick={handleExploreCars} // Attach the handler to navigate
        >
          Explore the Fleet
        </Button>
      </Box>
    </Box>
  );
};

export default HeroSection;



// HomePage.js


import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import useStyles from './HomePageStyles'; // Import the styles from the separate file
import HeroSection from './HeroSection'; // Import the HeroSection component
import Header from './Header.';
import Footer from '../footer/Footer';
import FeaturedCars from './FeaturedCars'; // Import the new FeaturedCars component

const HomePage = () => {
  const classes = useStyles();
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const handleGetStarted = () => {
    navigate('/explore-cars'); // Navigate to the explore-cars page
  };

  return (
    <div className={classes.root}>
      <Header /> {/* Add the Header component */}
      
      <HeroSection />

      {/* Featured Cars Section */}
      <Container maxWidth="lg" style={{ padding: '50px 0' }}>
        <FeaturedCars />
      </Container>

      {/* Call to Action Section */}
      <Box style={{ backgroundColor: '#000', padding: '50px 0', textAlign: 'center' }}>
        <Typography variant="h4" className={classes.neonText}>
          Ready to Ride?
        </Typography>
        <Button 
          className={classes.buttonNeon} 
          size="large" 
          style={{ marginTop: '20px' }} 
          onClick={handleGetStarted} // Add the onClick event to navigate
        >
          Get Started
        </Button>
      </Box>

      <Footer /> {/* Add the Footer component */}
    </div>
  );
};

export default HomePage;

import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { viceCityRealtimeDb } from '../firebase'; // Import Vice City Realtime Database

const useFetchLeads = () => {
  const [leads, setLeads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const leadsRef = ref(viceCityRealtimeDb, 'leads');

    const unsubscribe = onValue(
      leadsRef,
      (snapshot) => {
        const data = snapshot.val();
        if (data) {
          const leadList = Object.values(data); // Convert object of objects to array
          setLeads(leadList);
        } else {
          setLeads([]);
        }
        setLoading(false);
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );

    return () => unsubscribe(); // Cleanup on component unmount
  }, []);

  return { leads, loading, error };
};

export default useFetchLeads;

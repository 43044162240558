import React from 'react';
import { Typography, Box } from '@mui/material';
import useStyles from './PurchasePageStyles'; // Assuming you have common styles

const Disclaimer = () => {
  const classes = useStyles();

  return (
    <Box className={classes.disclaimerBox}>
      <Typography variant="h6" gutterBottom className={classes.disclaimerTitle}>
        Disclaimer
      </Typography>
      <Typography variant="body2" className={classes.disclaimerText}>
        By purchasing this lead, you agree that you will be charged the specified amount based on the pricing structure
        for the rental period. The lead information is accurate to the best of our knowledge, but we do not guarantee the 
        conversion or quality of the lead. Please ensure that you have reviewed all the details before proceeding with 
        the purchase. Refunds for leads are not provided once the lead information has been accessed. 
      </Typography>
    </Box>
  );
};

export default Disclaimer;

import React, { useState } from 'react';
import { Box, Typography, TextField, Button, Switch, FormControlLabel, Grid } from '@mui/material';
import useStyles from './AccountSettingsStyles'; // Import custom styles

const AccountSettings = () => {
  const classes = useStyles();

  // Form state
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  // Additional settings
  const [notificationEnabled, setNotificationEnabled] = useState(true);

  // Save settings
  const handleSave = () => {
    const updatedSettings = {
      firstName,
      lastName,
      email,
      phone,
      address,
      newPassword,
      notificationEnabled,
    };

    // API call to save settings can go here
    console.log('Settings Saved!', updatedSettings);
  };

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.neonText}>
        Account Settings
      </Typography>
      <Grid container spacing={4} className={classes.form}>
        <Grid item xs={12} md={6}>
          <TextField
            label="First Name"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Last Name"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Email Address"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Phone Number"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Address"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={address}
            onChange={(e) => setAddress(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="Current Password"
            type="password"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <TextField
            label="New Password"
            type="password"
            variant="outlined"
            fullWidth
            className={classes.textField}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </Grid>
      </Grid>

      {/* Notification and Dark Mode Switches */}
      <Box className={classes.switches}>
        <FormControlLabel
          control={<Switch checked={notificationEnabled} onChange={() => setNotificationEnabled(!notificationEnabled)} />}
          label="Enable Notifications"
          className={classes.switchLabel}
        />
        
      </Box>

      <Button
        variant="contained"
        className={classes.saveButton}
        onClick={handleSave}
      >
        Save Settings
      </Button>
    </Box>
  );
};

export default AccountSettings;

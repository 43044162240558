import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#111',
    padding: '50px',
    borderRadius: '15px',
    boxShadow: '0 0 60px #f72585, 0 0 80px #23d5ab',
    textAlign: 'center',
    animation: '$fadeInUp 0.8s ease-in-out',
    maxWidth: '1200px',
    margin: 'auto',
    color: '#fff',
    [theme.breakpoints.down('sm')]: {
      padding: '20px', // Adjust padding for smaller screens
    },
  },
  title: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '2.5rem',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab, 0 0 30px #23d5ab',
    marginBottom: '30px',
    animation: '$glowText 2s infinite alternate',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8rem', // Smaller font size for mobile
    },
  },
  tableContainer: {
    backgroundColor: '#222', // Dark background for the table container
    borderRadius: '8px',
    boxShadow: '0 0 30px #23d5ab, 0 0 50px #f72585',
    [theme.breakpoints.down('sm')]: {
      boxShadow: 'none', // Remove box shadow on smaller screens to reduce clutter
    },
  },
  tableHeader: {
    color: '#fff',
    fontWeight: 600,
    fontSize: '1.1rem',
    borderBottom: '1px solid #f72585',
    backgroundColor: '#111', // Background color for header cells
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem', // Smaller font size for mobile
      padding: '10px 5px',
    },
  },
  tableRow: {
    '&:hover': {
      backgroundColor: '#333',
    },
    '& td': {
      color: '#fff',
      borderBottom: '1px solid #23d5ab',
      backgroundColor: '#1a1a1a', // Set background for table rows
      padding: '15px 10px',
      [theme.breakpoints.down('sm')]: {
        padding: '10px 5px', // Reduce padding on smaller screens
        fontSize: '0.9rem', // Smaller font size for mobile
      },
    },
  },
  '@keyframes glowText': {
    '0%': {
      textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    },
    '100%': {
      textShadow: '0 0 30px #f72585, 0 0 50px #23d5ab',
    },
  },
  '@keyframes fadeInUp': {
    '0%': {
      opacity: 0,
      transform: 'translateY(50px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  modalContent: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#111',
    color: '#fff',
    borderRadius: '15px',
    padding: '20px',
    boxShadow: '0 0 40px #23d5ab, 0 0 80px #f72585',
    width: '90%',
    maxWidth: '400px',
    textAlign: 'center',
  },
  closeButton: {
    marginTop: '20px',
    backgroundColor: '#f72585',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#f50057',
    },
  },
}));

export default useStyles;

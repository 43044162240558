// VehicleListingPage.js


import React, { useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { Box, Typography, Button } from '@mui/material';
import { Helmet } from 'react-helmet';
import useStyles from './VehicleListingPageStyles';
import useCarListings from '../../hooks/useCarListings';
import CalendarModal from '../modals/CalendarModal'; // Import CalendarModal

const VehicleListingPage = () => {
  const { id } = useParams(); // Get the vehicle ID from the URL
  const navigate = useNavigate();
  const location = useLocation(); // Access the location state
  const { carListings, loading, error } = useCarListings(); // Use the custom hook to fetch listings
  const classes = useStyles();
  const [isModalOpen, setIsModalOpen] = useState(false); // State for CalendarModal

    // Try to get car data from location state, else fallback to finding the car by ID
    const vehicle = location.state?.car || carListings.find((car) => car.id === id);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return <Typography variant="h6">{error}</Typography>;
  }



  if (!vehicle) {
    return <Typography variant="h4">Vehicle not found</Typography>;
  }

  // Generate the title and description for SEO
  const title = `${vehicle.make} ${vehicle.model} Rentals in ${vehicle.city}, ${vehicle.state} | Luxury & Exotic Cars`;
  const description = `Rent a ${vehicle.make} ${vehicle.model} in ${vehicle.city}, ${vehicle.state}. Enjoy luxury and exotic car rentals with delivery options and more.`;

  const handleGoBack = () => {
    navigate(-1); // Go back to the previous page
  };

  const handleRentNow = () => {
    setIsModalOpen(true); // Open the CalendarModal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the CalendarModal
  };

  return (
    <>
      {/* SEO Optimization with Helmet */}
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={`exotic car rental, luxury car rental, ${vehicle.make} ${vehicle.model} rentals, ${vehicle.city}, ${vehicle.state}, car rentals`} />
      </Helmet>

      <Box className={classes.listingContainer}>
        <Typography variant="h2" className={classes.neonText}>
          {vehicle.make} {vehicle.model}
        </Typography>
        <img src={vehicle.imageUrls[0]} alt={vehicle.make} className={classes.vehicleImage} />

        <Typography variant="h6" className={classes.vehicleDescription}>
          {vehicle.description}
        </Typography>

        <Typography className={classes.vehicleDetails}>
          <strong>Price per day:</strong> ${vehicle.pricePerDay}
        </Typography>
        <Typography className={classes.vehicleDetails}>
          <strong>Location:</strong> {vehicle.city}, {vehicle.state}
        </Typography>
        <Typography className={classes.vehicleDetails}>
          <strong>Delivery:</strong> {vehicle.deliveryOffered ? 'Yes' : 'No'}
        </Typography>

        {/* Buttons */}
        <Box className={classes.buttonContainer}>
          <Button
            className={classes.rentNowButton}
            onClick={handleRentNow}
            size="large"
          >
            Rent Now
          </Button>
          <Button
            className={classes.goBackButton}
            onClick={handleGoBack}
            size="large"
          >
            Go Back
          </Button>
        </Box>
      </Box>

      {/* Calendar Modal */}
      <CalendarModal open={isModalOpen} handleClose={handleCloseModal} selectedCar={vehicle} />
    </>
  );
};

export default VehicleListingPage;


// import React, { useState } from 'react';
// import { useParams, useNavigate } from 'react-router-dom';
// import { Box, Typography, Button } from '@mui/material';
// import { Helmet } from 'react-helmet';
// import useStyles from './VehicleListingPageStyles';
// import useCarListings from '../../hooks/useCarListings'; // Import the custom hook
// import CalendarModal from '../modals/CalendarModal'; // Import CalendarModal

// const VehicleListingPage = () => {
//   const { id } = useParams(); // Get the vehicle ID from the URL
//   const navigate = useNavigate(); // For navigation (go back)
//   const { carListings, loading, error } = useCarListings(); // Use the custom hook
//   const classes = useStyles(); // Always call hooks at the top

//   const [isModalOpen, setIsModalOpen] = useState(false); // State for CalendarModal

//   // If the data is still loading
//   if (loading) {
//     return <Typography variant="h6">Loading...</Typography>;
//   }

//   // If there's an error loading the data
//   if (error) {
//     return <Typography variant="h6">{error}</Typography>;
//   }

//   const vehicle = carListings.find((car) => car.id === parseInt(id)); // Find the car by ID

//   if (!vehicle) {
//     return <Typography variant="h4">Vehicle not found</Typography>;
//   }

//   // Generate the title and description for SEO
//   const title = `${vehicle.name} Rentals in ${vehicle.city}, ${vehicle.state} | Luxury & Exotic Cars`;
//   const description = `Rent a ${vehicle.name} in ${vehicle.city}, ${vehicle.state}. Enjoy luxury and exotic car rentals with unlimited mileage, delivery options, and more.`;

//   // Handle "Go Back" button click
//   const handleGoBack = () => {
//     navigate(-1); // Go back to the previous page
//   };

//   // Handle "Rent Now" button click to open the calendar modal
//   const handleRentNow = () => {
//     setIsModalOpen(true); // Open the CalendarModal
//   };

//   // Handle modal close
//   const handleCloseModal = () => {
//     setIsModalOpen(false); // Close the CalendarModal
//   };

//   return (
//     <>
//       {/* SEO Optimization with Helmet */}
//       <Helmet>
//         <title>{title}</title>
//         <meta name="description" content={description} />
//         <meta name="keywords" content={`exotic car rental, luxury car rental, ${vehicle.name} rentals, ${vehicle.city}, ${vehicle.state}, car rentals`} />
//       </Helmet>

//       <Box className={classes.listingContainer}>
//         <Typography variant="h2" className={classes.neonText}>
//           {vehicle.name}
//         </Typography>
//         <img src={vehicle.image} alt={vehicle.name} className={classes.vehicleImage} />

//         <Typography variant="h6" className={classes.vehicleDescription}>
//           {vehicle.description}
//         </Typography>

//         <Typography className={classes.vehicleDetails}>
//           <strong>Price per day:</strong> {vehicle.price}
//         </Typography>
//         <Typography className={classes.vehicleDetails}>
//           <strong>Mileage:</strong> {vehicle.mileage || 'Unlimited'} miles/day
//         </Typography>
//         <Typography className={classes.vehicleDetails}>
//           <strong>Location:</strong> {vehicle.city}, {vehicle.state}
//         </Typography>
//         <Typography className={classes.vehicleDetails}>
//           <strong>Delivery:</strong> {vehicle.delivery ? 'Included' : 'Not Included'}
//         </Typography>

//         {/* Buttons */}
//         <Box className={classes.buttonContainer}>
//           <Button
//             className={classes.rentNowButton}
//             onClick={handleRentNow}
//             size="large"
//           >
//             Rent Now
//           </Button>
//           <Button
//             className={classes.goBackButton}
//             onClick={handleGoBack}
//             size="large"
//           >
//             Go Back
//           </Button>
//         </Box>
//       </Box>

//       {/* Calendar Modal */}
//       <CalendarModal open={isModalOpen} handleClose={handleCloseModal} />
//     </>
//   );
// };

// export default VehicleListingPage;

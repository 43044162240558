import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#000', // Black background
    color: '#fff', // White text
    padding: '50px',
    textAlign: 'center',
    minHeight: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    width: '150px', // Adjust logo size
    marginBottom: '20px', // Add some space below the logo
  },
  neonText: {
    color: '#23d5ab',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585', // Neon glow
    fontWeight: 700,
    fontSize: '3rem',
    marginBottom: '20px',
  },
  message: {
    fontSize: '1.2rem',
    marginBottom: '20px',
    color: '#fff',
  },
  exploreButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#1cb993',
    },
    padding: '10px 30px',
  },
}));

export default useStyles;

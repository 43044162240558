// NavBar.js

// NavBar.js

import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Badge, Button, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { AppBarStyled, Logo, DrawerToggleButton } from './DashboardStyles';
import logo from '../../assets/logo.png';
import Cart from './Cart'; // Import Cart component
import { useCart } from '../../context/CartContext'; // Import the global CartContext

const NavBar = ({ handleDrawerToggle, mobileOpen, currentUser, setSelectedMenuItem }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { cartItems, removeFromCart } = useCart(); // Use the cart hook from global context
  const [cartOpen, setCartOpen] = useState(false);

  const handleCartClick = () => {
    setCartOpen(true);
  };

  const handleCloseCart = () => {
    setCartOpen(false);
  };

  return (
    <AppBarStyled position="fixed">
      <Toolbar>
        {isMobile && (
          <DrawerToggleButton
            edge="start"
            drawerOpen={mobileOpen}
            onClick={handleDrawerToggle}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </DrawerToggleButton>
        )}
        <Logo src={logo} alt="Company Logo" />

        {currentUser?.userType === 'business' && (
          <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
            <IconButton color="inherit" onClick={handleCartClick}>
              <Badge badgeContent={cartItems.length} color="error">
                <ShoppingCartIcon sx={{ color: '#ffffff' }} />
              </Badge>
            </IconButton>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setSelectedMenuItem('create-listing')}
              sx={{ boxShadow: '0 0 15px #f72585', backgroundColor: '#23d5ab', ml: 2 }}
            >
              Create Listing
            </Button>
          </Box>
        )}

        {/* Cart Modal */}
        <Cart
          cartItems={cartItems}
          onClose={handleCloseCart}
          onRemoveItem={removeFromCart}
          open={cartOpen}
        />
      </Toolbar>
    </AppBarStyled>
  );
};

export default NavBar;

import React from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, useMediaQuery } from '@mui/material';
import useStyles from './DeclinedRentalsStyles'; // Custom styles
import { useTheme } from '@mui/material/styles';

// Sample data for declined rentals
const declinedData = [
  {
    car: 'Lamborghini Aventador',
    duration: '3 days',
    reason: 'Payment Failure',
  },
  {
    car: 'Ferrari 488 GTB',
    duration: '5 days',
    reason: 'Incomplete Documentation',
  },
  {
    car: 'McLaren 720S',
    duration: '2 days',
    reason: 'Credit Check Failed',
  },
];

const DeclinedRentals = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if screen size is mobile

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={classes.title}>
        Declined Rentals
      </Typography>

      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              {!isMobile && <TableCell className={classes.tableHeader}>Customer Name</TableCell>}
              {!isMobile && <TableCell className={classes.tableHeader}>Phone</TableCell>}
              {!isMobile && <TableCell className={classes.tableHeader}>Address</TableCell>}
              {!isMobile && <TableCell className={classes.tableHeader}>Email</TableCell>}
              <TableCell className={classes.tableHeader}>Car</TableCell>
              <TableCell className={classes.tableHeader}>Duration</TableCell>
              <TableCell className={classes.tableHeader}>Reason for Decline</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {declinedData.map((rental, index) => (
              <TableRow key={index} className={classes.tableRow}>
                {!isMobile && <TableCell>***</TableCell>} {/* Blocked out data */}
                {!isMobile && <TableCell>***</TableCell>} {/* Blocked out data */}
                {!isMobile && <TableCell>Blocked</TableCell>} {/* Blocked out data */}
                {!isMobile && <TableCell>Blocked</TableCell>} {/* Blocked out data */}
                <TableCell>{rental.car}</TableCell>
                <TableCell>{rental.duration}</TableCell>
                <TableCell>{rental.reason}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DeclinedRentals;

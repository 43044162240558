import React from 'react';
import { Box, Typography, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const DashboardContainer = styled(Box)(({ theme }) => ({
  backgroundColor: '#111', // Dark background
  padding: theme.spacing(4),
  color: '#fff',
  minHeight: '100vh',
  boxShadow: 'inset 0 0 20px #23d5ab', // Neon shadow effect
}));

const StatCard = styled(Paper)(({ theme }) => ({
  backgroundColor: '#222', // Darker card background
  color: '#fff',
  padding: theme.spacing(3),
  borderRadius: '10px',
  textAlign: 'center',
  boxShadow: '0 0 30px #23d5ab, 0 0 50px #f72585', // Neon effect
  transition: 'transform 0.3s ease',
  '&:hover': {
    transform: 'scale(1.05)', // Hover effect
    boxShadow: '0 0 50px #f72585, 0 0 70px #23d5ab', // Enhanced neon on hover
  },
}));

const StatLabel = styled(Typography)(({ theme }) => ({
  color: '#23d5ab', // Neon cyan for labels
  fontSize: '1.2rem',
  marginBottom: theme.spacing(1),
}));

const StatValue = styled(Typography)(({ theme }) => ({
  color: '#f72585', // Neon pink for values
  fontSize: '2.5rem',
  fontWeight: 'bold',
  textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab', // Neon glow effect
}));

const DashboardOverview = ({ userType }) => {
  return (
    <DashboardContainer>
      <Typography variant="h4" gutterBottom sx={{ textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585' }}>
        Welcome to Your Dashboard
      </Typography>
      <Typography variant="subtitle1" gutterBottom sx={{ color: '#f72585', textShadow: '0 0 10px #f72585' }}>
        {userType === 'business'
          ? 'Manage your rental leads, track performance, and create new listings.'
          : 'Check your current rentals, view history, and manage your account settings.'}
      </Typography>

      {/* Stats Section */}
      <Grid container spacing={4} sx={{ marginTop: 4 }}>
        <Grid item xs={12} sm={4}>
          <StatCard>
            <StatLabel>Total Rentals</StatLabel>
            <StatValue>{userType === 'business' ? '124' : '8'}</StatValue> {/* Example data */}
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard>
            <StatLabel>{userType === 'business' ? 'Pending Leads' : 'Active Rentals'}</StatLabel>
            <StatValue>{userType === 'business' ? '32' : '3'}</StatValue>
          </StatCard>
        </Grid>
        <Grid item xs={12} sm={4}>
          <StatCard>
            <StatLabel>{userType === 'business' ? 'Approved Leads' : 'Past Rentals'}</StatLabel>
            <StatValue>{userType === 'business' ? '74' : '5'}</StatValue>
          </StatCard>
        </Grid>
      </Grid>

      {/* Additional content for business users */}
      {userType === 'business' && (
        <Box mt={6}>
          <Typography variant="h5" gutterBottom sx={{ textShadow: '0 0 10px #f72585' }}>
            Recent Leads
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff' }}>
            Here you can view and manage your most recent leads.
          </Typography>
          {/* Add a table or list for recent leads here */}
        </Box>
      )}

      {/* Additional content for personal users */}
      {userType === 'personal' && (
        <Box mt={6}>
          <Typography variant="h5" gutterBottom sx={{ textShadow: '0 0 10px #23d5ab' }}>
            Your Active Rentals
          </Typography>
          <Typography variant="body1" sx={{ color: '#fff' }}>
            Keep track of your current rentals and upcoming bookings.
          </Typography>
          {/* Add a table or list for active rentals here */}
        </Box>
      )}
    </DashboardContainer>
  );
};

export default DashboardOverview;

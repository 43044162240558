import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: '#111', // Dark footer background
    padding: '20px 0',
    textAlign: 'center',
    borderTop: '2px solid #f72585', // Neon pink border on top
    boxShadow: '0 0 10px #23d5ab', // Neon glow effect
    marginTop: '50px',
    position: 'relative',
    bottom: 0,
    width: '100%',
  },
  socialIcons: {
    marginBottom: '10px',
    '& svg': {
      color: '#23d5ab', // Neon green for the social icons
      transition: '0.3s ease',
    },
    '& svg:hover': {
      color: '#f72585', // Neon pink hover effect
      transform: 'scale(1.2)', // Scale up on hover
    },
  },
  footerLinks: {
    marginBottom: '10px',
    display: 'flex',
    justifyContent: 'center',
    gap: '40px', // Increased gap between links
    '@media (max-width: 600px)': {
      flexDirection: 'column', // Stack links vertically on smaller screens
      gap: '15px',
    },
  },
  footerLink: {
    color: '#23d5ab', // Neon green for links
    textDecoration: 'none',
    fontWeight: 'bold',
    letterSpacing: '1.5px',
    transition: '0.3s ease',
    '&:hover': {
      color: '#f72585', // Neon pink on hover
      textShadow: '0 0 5px #f72585, 0 0 10px #23d5ab',
    },
  },
  footerText: {
    color: '#fff',
    textShadow: '0 0 5px #23d5ab, 0 0 10px #f72585', // Neon glow for the footer text
    fontWeight: 'bold',
    letterSpacing: '2px',
    fontSize: '1rem',
  },
  iconButton: {
    margin: '0 10px', // Space between icons
    '&:hover': {
      backgroundColor: 'transparent', // No background on hover
    },
  },
}));

export default useStyles;

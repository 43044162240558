// Dashboard.js

import React, { useState, useEffect } from 'react';
import { CssBaseline, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CustomDrawer from './CustomDrawer';
import DashboardOverview from './DashboardOverview';
import PendingRentals from './PendingRentals';
import DeclinedRentals from './DeclinedRentals';
import RentalHistory from './RentalHistory';
import AccountSettings from './AccountSettings';
import CreateListing from './CreateListing';
import { useAuth } from '../../hooks/useAuth';
import NavBar from './NavBar'; // Import NavBar component
import { Content, Root } from './DashboardStyles';
import { useCart } from '../../context/CartContext'; // Import the CartContext

const Dashboard = () => {
  const { currentUser } = useAuth();
  const { cartItems } = useCart(); // Get cart items from CartContext
  // const navigate = useNavigate();
  const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
  const [mobileOpen, setMobileOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(true);

  // useEffect(() => {
  //   if (!currentUser) {
  //     navigate('/');
  //   }
  // }, [currentUser, navigate]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const renderContent = () => {
    switch (selectedMenuItem) {
      case 'pending':
        return <PendingRentals />;
      case 'declined':
        return <DeclinedRentals />;
      case 'history':
        return currentUser?.userType === 'business' ? <RentalHistory /> : <DashboardOverview />;
      case 'settings':
        return <AccountSettings />;
      case 'create-listing':
        return currentUser?.userType === 'business' ? <CreateListing /> : <DashboardOverview />;
      default:
        return <DashboardOverview />;
    }
  };

  return (
    <Root>
      <CssBaseline />
      
      {/* Use NavBar for the AppBar and Shopping Cart */}
      <NavBar
        handleDrawerToggle={handleDrawerToggle}
        mobileOpen={mobileOpen}
        cartItems={cartItems} // Pass the cart items from CartContext
        currentUser={currentUser}
        setSelectedMenuItem={setSelectedMenuItem}
      />

      <CustomDrawer
        mobileOpen={mobileOpen}
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        handleDrawerOpenClose={() => setDrawerOpen(!drawerOpen)}
        selectedMenuItem={selectedMenuItem}
        setSelectedMenuItem={setSelectedMenuItem}
        userType={currentUser?.userType}
      />

      <Content>
        <Toolbar /> {/* Needed to prevent content overlap */}
        {renderContent()}
      </Content>
    </Root>
  );
};

export default Dashboard;






// import React, { useState } from 'react';
// import { AppBar, Toolbar, IconButton, CssBaseline, Button, Box, useMediaQuery } from '@mui/material';
// import { styled, useTheme } from '@mui/material/styles';
// import CustomDrawer from './CustomDrawer'; // Import the new CustomDrawer component
// import DashboardOverview from './DashboardOverview';
// import PendingRentals from './PendingRentals';
// import DeclinedRentals from './DeclinedRentals';
// import RentalHistory from './RentalHistory'; // Only for business users
// import AccountSettings from './AccountSettings';
// import CreateListing from './CreateListing'; // Import Create Listing page
// import MenuIcon from '@mui/icons-material/Menu'; // Drawer toggle icon

// // Import the logo
// import logo from '../../assets/logo.png'; // Adjust the path to the logo

// const AppBarStyled = styled(AppBar)(({ theme }) => ({
//   zIndex: theme.zIndex.drawer + 1,
//   backgroundColor: '#000',
//   boxShadow: '0 0 15px #f72585',
//   animation: 'fadeIn 1s ease-in-out',
// }));

// const Logo = styled('img')({
//   height: '50px', // Adjust the height as needed
//   marginRight: 'auto', // Ensures the logo is on the left
// });

// const Content = styled('main')(({ theme }) => ({
//   flexGrow: 1,
//   padding: theme.spacing(3),
//   backgroundColor: '#000',
//   color: '#fff',
//   minHeight: '100vh',
//   boxShadow: 'inset 0 0 20px #23d5ab',
//   animation: 'fadeIn 1s ease-in-out',
// }));

// const Root = styled('div')({
//   display: 'flex',
// });

// const DrawerToggleButton = styled(IconButton)(({ drawerOpen }) => ({
//   color: '#23d5ab',
//   border: '2px solid #f72585',
//   borderRadius: '50%',
//   padding: 10,
//   backgroundColor: '#111',
//   transform: drawerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
//   transition: 'transform 0.5s ease, background-color 0.3s ease',
//   '&:hover': {
//     backgroundColor: '#23d5ab',
//     color: '#000',
//   },
// }));

// const Dashboard = ({ userType = 'personal' }) => {
//   const [selectedMenuItem, setSelectedMenuItem] = useState('overview');
//   const [mobileOpen, setMobileOpen] = useState(false);
//   const [drawerOpen, setDrawerOpen] = useState(true); // State to manage drawer visibility for larger screens
//   const theme = useTheme();
//   const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Media query to check if it's a mobile screen

//   const handleDrawerToggle = () => {
//     setMobileOpen(!mobileOpen);
//   };

//   const handleDrawerOpenClose = () => {
//     setDrawerOpen(!drawerOpen); // Toggles the drawer for larger screens
//   };

//   const renderContent = () => {
//     switch (selectedMenuItem) {
//       case 'pending':
//         return <PendingRentals />;
//       case 'declined':
//         return <DeclinedRentals />;
//       case 'history':
//         return userType === 'business' ? <RentalHistory /> : <DashboardOverview />; // Only show for business
//       case 'settings':
//         return <AccountSettings />;
//       case 'create-listing':
//         return userType === 'business' ? <CreateListing /> : <DashboardOverview />; // Only show for business
//       default:
//         return <DashboardOverview />;
//     }
//   };

//   return (
//     <Root>
//       <CssBaseline />
//       <AppBarStyled position="fixed">
//         <Toolbar>
//           {/* Stylish button to open/close the drawer (mobile only) */}
//           {isMobile && (
//             <DrawerToggleButton
//               edge="start"
//               drawerOpen={mobileOpen}
//               onClick={handleDrawerToggle}
//               sx={{ mr: 2 }}
//             >
//               <MenuIcon />
//             </DrawerToggleButton>
//           )}
//           {/* Replace the text with the logo */}
//           <Logo src={logo} alt="Company Logo" />

//           {/* Show Create Listing Button for Business Users */}
//           {userType === 'business' && (
//             <Box sx={{ marginLeft: 'auto' }}>
//               <Button
//                 variant="contained"
//                 color="secondary"
//                 onClick={() => setSelectedMenuItem('create-listing')}
//                 sx={{ boxShadow: '0 0 15px #f72585', backgroundColor: '#23d5ab' }}
//               >
//                 Create Listing
//               </Button>
//             </Box>
//           )}
//         </Toolbar>
//       </AppBarStyled>

//       <CustomDrawer
//         mobileOpen={mobileOpen}
//         drawerOpen={drawerOpen}
//         handleDrawerToggle={handleDrawerToggle}
//         handleDrawerOpenClose={handleDrawerOpenClose}
//         selectedMenuItem={selectedMenuItem}
//         setSelectedMenuItem={setSelectedMenuItem}
//         userType={userType}
//       />

//       <Content>
//         <Toolbar /> {/* Add toolbar spacing to prevent content overlap */}
//         {renderContent()}
//       </Content>
//     </Root>
//   );
// };

// export default Dashboard;

import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const PWAInstallButton = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [isPWAInstallable, setIsPWAInstallable] = useState(false);
  const [isVisible, setIsVisible] = useState(true); // State to control visibility
  const [isMobile, setIsMobile] = useState(false); // State to check if it's a mobile device

  // Detect if the screen size is mobile
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768); // Set mobile threshold, 768px width
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  // Listen for beforeinstallprompt event
  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setIsPWAInstallable(true);
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  // Trigger the PWA installation prompt
  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === 'accepted') {
        console.log('PWA installed');
      } else {
        console.log('PWA installation dismissed');
      }
      setDeferredPrompt(null);
      setIsPWAInstallable(false);
      setIsVisible(false); // Hide after install
    }
  };

  // Listen for the app being installed
  useEffect(() => {
    const handleAppInstalled = () => {
      console.log('PWA has been installed');
      setIsPWAInstallable(false);
      setIsVisible(false); // Hide when installed
    };

    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  // Close button handler
  const handleClose = () => {
    setIsVisible(false); // Close the button
  };

  // Only show the install button if PWA is installable, visible, and on a mobile device
  if (!isPWAInstallable || !isVisible || !isMobile) return null;

  return (
    <div
      style={{
        position: 'fixed',
        top: '20%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 1000,
        animation: 'slideDown 1s ease-in-out',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        background: 'rgba(0, 0, 0, 0.8)',
        borderRadius: '15px',
        boxShadow: '0 0 30px 10px rgba(23, 213, 171, 0.7), 0 0 60px 20px #f72585',
      }}
    >
      <Button
        variant="contained"
        onClick={handleInstallClick}
        style={{
          backgroundColor: 'transparent',
          padding: '0',
          borderRadius: '50%',
          boxShadow: '0 0 15px #23d5ab, 0 0 30px #f72585',
          transition: 'transform 0.3s ease-in-out',
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Install Logo" style={{ width: '60px', height: '60px' }} />
      </Button>
      <span
        style={{
          fontSize: '12px',
          color: '#23d5ab',
          marginTop: '10px',
          textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
        }}
      >
        Install our app
      </span>
      <IconButton
        style={{
          position: 'absolute',
          top: '-10px',
          right: '-10px',
          backgroundColor: '#000',
          color: '#f72585',
          boxShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
        }}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </div>
  );
};

export default PWAInstallButton;

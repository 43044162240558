// SinglePostPageStyles.js
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  postContainer: {
    backgroundColor: '#000', // Dark background
    padding: '20px',
    minHeight: '100vh',
    color: '#fff',
    textAlign: 'center',
  },
  bannerImage: {
    width: '100%', // Full width on smaller screens
    maxWidth: '800px', // Limit maximum width for larger screens
    height: 'auto',
    objectFit: 'cover', // Ensure the banner image covers the area nicely
    marginBottom: '40px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585', // Neon glow around the banner image
    borderRadius: '8px', // Optional: Adds rounded corners to the image
    '@media (max-width: 600px)': {
      width: '100%', // Full width on mobile devices
    },
  },
  postTitle: {
    fontSize: '3.5rem',
    color: '#f72585', // Neon pink title
    textShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
    marginBottom: '20px',
    fontFamily: '"Orbitron", sans-serif',
    '@media (max-width: 600px)': {
      fontSize: '2rem', // Smaller font size for mobile
    },
  },
  postDate: {
    fontSize: '1.2rem',
    color: '#aaa', // Light grey for date
    fontStyle: 'italic',
    marginBottom: '30px',
    '@media (max-width: 600px)': {
      fontSize: '1rem', // Adjust font size on smaller screens
    },
  },
  postContent: {
    fontSize: '1.5rem',
    color: '#fff', // White for content readability
    marginBottom: '50px',
    textAlign: 'left',
    lineHeight: '1.8',
    letterSpacing: '0.05em',
    padding: '0 20px', // Padding for better readability on smaller screens
    '@media (max-width: 600px)': {
      fontSize: '1rem', // Smaller font size for mobile
      padding: '0 10px', // Less padding on mobile
    },
  },
  goBackButton: {
    backgroundColor: '#23d5ab', // Neon cyan
    color: '#000',
    padding: '10px 30px',
    boxShadow: '0 0 15px #23d5ab',
    fontSize: '1.2rem',
    marginTop: '10px', // Move button down
    '&:hover': {
      backgroundColor: '#f72585',
      color: '#fff',
      boxShadow: '0 0 25px #f72585',
    },
    '@media (max-width: 600px)': {
      fontSize: '1rem', // Smaller button size for mobile
      padding: '8px 20px', // Adjust padding for smaller screens
    },
    // Make sure these styles are not overridden elsewhere
    position: 'relative', // Optional: If positioning is a concern
  },
  socialIconsContainer: {
    marginTop: '40px',
    textAlign: 'center',
  },
  socialIcons: {
    marginTop: '40px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  shareText: {
    marginRight: '10px',
    color: '#23d5ab',
  },
  iconButton: {
    margin: '0 10px',
    '&:hover': {
      color: '#23d5ab',
    },
  },
  icon: {
    color: '#f72585', // Neon pink color for icons
    fontSize: '7rem',
    '&:hover': {
      color: '#23d5ab', // Cyan on hover
    },
  },
  
}));

export default useStyles;

// CustomerInfoPage.jsx


import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogActions, CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom'; // Import useLocation to retrieve passed data
import useStyles from './CustomerInfoPageStyles'; // Import custom styles for the page
import logo from '../../assets/logo.png'; // Import your logo
import useSubmitLead from '../../hooks/useSubmitLead'; // Import the hook to submit the lead

const CustomerInfoPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation(); // Retrieve state passed from previous page
  const { selectedCar, startDate, endDate } = location.state || {}; // Destructure data from the location state
  const { submitLead } = useSubmitLead(); // Use the submitLead hook
  
  const [customerInfo, setCustomerInfo] = useState({
    name: '',
    phone: '',
    email: '',
    address: '',
    city: '',  // City field
    state: '', // State field
    country: '', // Country field
    requireInsurance: true, // Checkbox for insurance
    hasValidLicense: true, // Checkbox for valid license
    isLicenseSuspended: false, // Checkbox for suspended license
    deliveryPickup: { pickup: false, delivery: false }, // Checkboxes for delivery/pickup
  });

  const [acceptTerms, setAcceptTerms] = useState(false); // Checkbox for agreeing to terms
  const [openDialog, setOpenDialog] = useState(false); // State for handling the popup dialog
  const [loading, setLoading] = useState(false); // Loading state to track form submission

  // Handle change for checkboxes
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCustomerInfo({
      ...customerInfo,
      deliveryPickup: {
        ...customerInfo.deliveryPickup,
        [name]: checked,
      },
    });
  };

  // Handle text field changes
  const handleChange = (e) => {
    setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value });
  };

  // Handle confirm button
  const handleConfirm = async () => {
    if (acceptTerms) {
      setLoading(true); // Set loading to true
      try {
        // Submit the lead data to Firebase Realtime Database
        await submitLead(customerInfo, selectedCar, startDate, endDate);
        // Navigate to the thank you page on successful submission
        navigate('/thank-you');
      } catch (error) {
        console.error('Error while submitting lead:', error);
        // Handle any error if necessary
      } finally {
        setLoading(false); // Set loading to false after the submission process completes
      }
    }
  };

  // Handle closing the dialog
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <Box className={classes.pageContainer}>
      <img src={logo} alt="Company Logo" className={classes.logo} />{" "}
      {/* Logo */}
      <Typography variant="h4" className={classes.neonText}>
        Enter Your Information
      </Typography>

      {/* Display selected car and rental details */}
      {selectedCar && (
        <Box className={classes.carDetails}>
          <Typography variant="h6" className={classes.neonText}>
            {selectedCar.make} {selectedCar.model}
          </Typography>
          <Typography variant="body1" className={classes.neonText}>
            Daily Rate: ${selectedCar.pricePerDay}
          </Typography>
          <Typography variant="body1" className={classes.neonText}>
            Location: {selectedCar.city}, {selectedCar.state}
          </Typography>
        </Box>
      )}

      {/* Display rental dates if selected */}
      {startDate && endDate && (
        <Box className={classes.dateDisplay}>
          <Typography variant="body1" className={classes.neonText}>
            Rental Period: {new Date(startDate).toLocaleDateString()} to {new Date(endDate).toLocaleDateString()}
          </Typography>
        </Box>
      )}

      {/* Form Fields */}
      <TextField
        label="Name"
        name="name"
        variant="outlined"
        fullWidth
        value={customerInfo.name}
        onChange={handleChange}
        className={classes.textField}
      />
      <TextField
        label="Phone"
        name="phone"
        variant="outlined"
        fullWidth
        value={customerInfo.phone}
        onChange={handleChange}
        className={classes.textField}
      />
      <TextField
        label="Email"
        name="email"
        variant="outlined"
        fullWidth
        value={customerInfo.email}
        onChange={handleChange}
        className={classes.textField}
      />
      <TextField
        label="Address"
        name="address"
        variant="outlined"
        fullWidth
        value={customerInfo.address}
        onChange={handleChange}
        className={classes.textField}
      />
      <Box className={classes.addressFields}>
        <TextField
          label="City"
          name="city"
          variant="outlined"
          fullWidth
          value={customerInfo.city}
          onChange={handleChange}
          className={classes.textFieldSmall}
        />
        <TextField
          label="State"
          name="state"
          variant="outlined"
          fullWidth
          value={customerInfo.state}
          onChange={handleChange}
          className={classes.textFieldSmall}
        />
        <TextField
          label="Country"
          name="country"
          variant="outlined"
          fullWidth
          value={customerInfo.country}
          onChange={handleChange}
          className={classes.textFieldSmall}
        />
      </Box>
      {/* Insurance Checkbox */}
      <FormControlLabel
        control={
          <Checkbox
            checked={customerInfo.requireInsurance}
            onChange={handleCheckboxChange}
            name="requireInsurance"
            className={classes.checkbox}
          />
        }
        label="Require Insurance"
        className={classes.formControlLabel}
      />
      {/* License Status Checkboxes */}
      <FormControlLabel
        control={
          <Checkbox
            checked={customerInfo.hasValidLicense}
            onChange={handleCheckboxChange}
            name="hasValidLicense"
            className={classes.checkbox}
            disabled={customerInfo.isLicenseSuspended} // Disable "Valid License" if suspended
          />
        }
        label="Valid License"
        className={classes.formControlLabel}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={customerInfo.isLicenseSuspended}
            onChange={handleCheckboxChange}
            name="isLicenseSuspended"
            className={classes.checkbox}
          />
        }
        label="Suspended License"
        className={classes.formControlLabel}
      />
      {/* Pickup and Delivery Checkboxes */}
      <Box className={classes.checkboxGroup}>
        <FormControlLabel
          control={
            <Checkbox
              checked={customerInfo.deliveryPickup.pickup}
              onChange={handleCheckboxChange}
              name="pickup"
              className={classes.checkbox}
            />
          }
          label="Pickup"
          className={classes.formControlLabel}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={customerInfo.deliveryPickup.delivery}
              onChange={handleCheckboxChange}
              name="delivery"
              className={classes.checkbox}
            />
          }
          label="Delivery"
          className={classes.formControlLabel}
        />
      </Box>
      {/* Terms and Conditions Agreement */}
      <FormControlLabel
        control={
          <Checkbox
            checked={acceptTerms}
            onChange={() => setAcceptTerms(!acceptTerms)}
            className={classes.checkbox}
          />
        }
        label={
          <Typography variant="body2" className={classes.formControlLabel}>
            I agree to the{" "}
            <a
              href="/terms-and-conditions"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              Terms and Conditions
            </a>
          </Typography>
        }
      />
      {/* Confirm Button */}
      <Box className={classes.buttonContainer}>
        <Button
          className={classes.confirmButton}
          size="large"
          onClick={handleConfirm}
          disabled={!acceptTerms || loading} // Disable button when loading or terms not accepted
        >
          {loading ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </Box>
      {/* Suspended License Dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          Sorry, You Can't Rent with a Suspended License
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CustomerInfoPage;


// import React, { useState } from 'react';
// import { Box, Typography, Button, TextField, Checkbox, FormControlLabel, Dialog, DialogTitle, DialogActions } from '@mui/material';
// import { useNavigate } from 'react-router-dom'; // Import useNavigate to handle navigation
// import useStyles from './CustomerInfoPageStyles'; // Import custom styles for the page
// import logo from '../../assets/logo.png'; // Import your logo

// const CustomerInfoPage = () => {
//   const classes = useStyles();
//   const navigate = useNavigate(); // Hook for navigating to the Thank You page
//   const [customerInfo, setCustomerInfo] = useState({
//     name: '',
//     phone: '',
//     email: '',
//     address: '',
//     city: '',  // City field
//     state: '', // State field
//     country: '', // Country field
//     requireInsurance: true, // Checkbox for insurance
//     hasValidLicense: true, // Checkbox for valid license
//     isLicenseSuspended: false, // Checkbox for suspended license
//     deliveryPickup: { pickup: false, delivery: false }, // Checkboxes for delivery/pickup
//   });

//   const [acceptTerms, setAcceptTerms] = useState(false); // Checkbox for agreeing to terms
//   const [openDialog, setOpenDialog] = useState(false); // State for handling the popup dialog

//   // Handle change for checkboxes
//   const handleCheckboxChange = (e) => {
//     const { name, checked } = e.target;
//     if (name === 'pickup' || name === 'delivery') {
//       setCustomerInfo({
//         ...customerInfo,
//         deliveryPickup: {
//           ...customerInfo.deliveryPickup,
//           [name]: checked,
//         },
//       });
//     } else if (name === 'isLicenseSuspended') {
//       setCustomerInfo({
//         ...customerInfo,
//         isLicenseSuspended: checked,
//         hasValidLicense: !checked, // Automatically uncheck "Valid License" if suspended
//       });

//       if (checked) {
//         setOpenDialog(true); // Open the dialog when suspended license is selected
//       }
//     } else {
//       setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value });
//     }
//   };

//   // Handle text field changes
//   const handleChange = (e) => {
//     setCustomerInfo({ ...customerInfo, [e.target.name]: e.target.value });
//   };

//   // Handle confirm button
//   const handleConfirm = () => {
//     if (acceptTerms) {
//       navigate('/thank-you');
//     }
//   };

//   // Handle closing the dialog
//   const handleCloseDialog = () => {
//     setOpenDialog(false); // Close the dialog when the user acknowledges it
//   };

//   return (
//     <Box className={classes.pageContainer}>
//       <img src={logo} alt="Company Logo" className={classes.logo} />{" "}
//       {/* Logo */}
//       <Typography variant="h4" className={classes.neonText}>
//         Enter Your Information
//       </Typography>
//       {/* Form Fields */}
//       <TextField
//         label="Name"
//         name="name"
//         variant="outlined"
//         fullWidth
//         value={customerInfo.name}
//         onChange={handleChange}
//         className={classes.textField}
//       />
//       <TextField
//         label="Phone"
//         name="phone"
//         variant="outlined"
//         fullWidth
//         value={customerInfo.phone}
//         onChange={handleChange}
//         className={classes.textField}
//       />
//       <TextField
//         label="Email"
//         name="email"
//         variant="outlined"
//         fullWidth
//         value={customerInfo.email}
//         onChange={handleChange}
//         className={classes.textField}
//       />
//       <TextField
//         label="Address"
//         name="address"
//         variant="outlined"
//         fullWidth
//         value={customerInfo.address}
//         onChange={handleChange}
//         className={classes.textField}
//       />
//       <Box className={classes.addressFields}>
//         <TextField
//           label="City"
//           name="city"
//           variant="outlined"
//           fullWidth
//           value={customerInfo.city}
//           onChange={handleChange}
//           className={classes.textFieldSmall}
//         />
//         <TextField
//           label="State"
//           name="state"
//           variant="outlined"
//           fullWidth
//           value={customerInfo.state}
//           onChange={handleChange}
//           className={classes.textFieldSmall}
//         />
//         <TextField
//           label="Country"
//           name="country"
//           variant="outlined"
//           fullWidth
//           value={customerInfo.country}
//           onChange={handleChange}
//           className={classes.textFieldSmall}
//         />
//       </Box>
//       {/* Insurance Checkbox */}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={customerInfo.requireInsurance}
//             onChange={handleCheckboxChange}
//             name="requireInsurance"
//             className={classes.checkbox}
//           />
//         }
//         label="Require Insurance"
//         className={classes.formControlLabel}
//       />
//       {/* License Status Checkboxes */}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={customerInfo.hasValidLicense}
//             onChange={handleCheckboxChange}
//             name="hasValidLicense"
//             className={classes.checkbox}
//             disabled={customerInfo.isLicenseSuspended} // Disable "Valid License" if suspended
//           />
//         }
//         label="Valid License"
//         className={classes.formControlLabel}
//       />
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={customerInfo.isLicenseSuspended}
//             onChange={handleCheckboxChange}
//             name="isLicenseSuspended"
//             className={classes.checkbox}
//           />
//         }
//         label="Suspended License"
//         className={classes.formControlLabel}
//       />
//       {/* Pickup and Delivery Checkboxes */}
//       <Box className={classes.checkboxGroup}>
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={customerInfo.deliveryPickup.pickup}
//               onChange={handleCheckboxChange}
//               name="pickup"
//               className={classes.checkbox}
//             />
//           }
//           label="Pickup"
//           className={classes.formControlLabel}
//         />
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={customerInfo.deliveryPickup.delivery}
//               onChange={handleCheckboxChange}
//               name="delivery"
//               className={classes.checkbox}
//             />
//           }
//           label="Delivery"
//           className={classes.formControlLabel}
//         />
//       </Box>
//       {/* Terms and Conditions Agreement */}
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={acceptTerms}
//             onChange={() => setAcceptTerms(!acceptTerms)}
//             className={classes.checkbox}
//           />
//         }
//         label={
//           <Typography variant="body2" className={classes.formControlLabel}>
//             I agree to the{" "}
//             <a
//               href="/terms-and-conditions"
//               target="_blank"
//               rel="noopener noreferrer"
//               className={classes.link}
//             >
//               Terms and Conditions
//             </a>
//           </Typography>
//         }
//       />
//       {/* Confirm Button */}
//       <Box className={classes.buttonContainer}>
//         <Button
//           className={classes.confirmButton}
//           size="large"
//           onClick={handleConfirm}
//           disabled={!acceptTerms} // Disable button if terms not accepted
//         >
//           Confirm
//         </Button>
//       </Box>
//       {/* Suspended License Dialog */}
//       <Dialog open={openDialog} onClose={handleCloseDialog}>
//         <DialogTitle>
//           Sorry, You Can't Rent with a Suspended License
//         </DialogTitle>
//         <DialogActions>
//           <Button onClick={handleCloseDialog} color="primary">
//             OK
//           </Button>
//         </DialogActions>
//       </Dialog>
//     </Box>
//   );
// };

// export default CustomerInfoPage;

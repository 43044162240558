import { styled } from '@mui/material/styles';
import { AppBar, Box, IconButton } from '@mui/material';

// AppBar Styled
export const AppBarStyled = styled(AppBar)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#000',
  boxShadow: '0 0 15px #f72585',
  animation: 'fadeIn 1s ease-in-out',
}));

// Logo Styled
export const Logo = styled('img')({
  height: '50px',
  marginRight: 'auto',
});

// Content Styled
export const Content = styled('main')(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  backgroundColor: '#000',
  color: '#fff',
  minHeight: '100vh',
  boxShadow: 'inset 0 0 20px #23d5ab',
  animation: 'fadeIn 1s ease-in-out',
}));

// Root Styled
export const Root = styled('div')({
  display: 'flex',
});

// DrawerToggleButton Styled (Updated for better visibility)
export const DrawerToggleButton = styled(IconButton)(({ drawerOpen }) => ({
  color: '#f72585',  // Change the color to neon pink
  border: '2px solid #f72585', // Neon border
  borderRadius: '50%',
  padding: 10,
  backgroundColor: '#111', // Background color of the button
  transform: drawerOpen ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.5s ease, background-color 0.3s ease, color 0.3s ease',
  '&:hover': {
    backgroundColor: '#23d5ab',
    color: '#000',  // On hover, change the text color for visibility
  },
}));



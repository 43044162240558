// SinglePostPage.js
import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, IconButton } from '@mui/material';
import { Helmet } from 'react-helmet';
import useStyles from './SinglePostPageStyles';
import { ref, get } from 'firebase/database';
import { thirdRealtimeDb } from '../../firebase'; // Import your Firebase configuration
import MediumIcon from '@mui/icons-material/Article'; // Using Article icon as a placeholder for Medium
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import Footer from '../footer/Footer';

const SinglePostPage = () => {
  const { id } = useParams(); // Extract the Firebase key from the URL
  const navigate = useNavigate();
  const classes = useStyles();
  const defaultBanner = `${process.env.PUBLIC_URL}/news.webp`; // Use banner image for all blog posts

  // State to hold the blog post data
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch the post by Firebase key
  useEffect(() => {
    const fetchPost = async () => {
      const postRef = ref(thirdRealtimeDb, `articles/${id}`); // Reference to the article in Firebase using the key
      const snapshot = await get(postRef);
      if (snapshot.exists()) {
        setPost(snapshot.val());
      } else {
        setPost(null); // Post not found
      }
      setLoading(false);
    };

    fetchPost();
  }, [id]);

  if (loading) {
    return <Typography variant="h4">Loading...</Typography>;
  }

  if (!post) {
    return <Typography variant="h4">Blog post not found</Typography>;
  }

  const handleGoBack = () => {
    navigate('/blog'); // Navigate back to the blog list
  };

  return (
    <Box className={classes.postContainer}>
      {/* React Helmet for SEO */}
      <Helmet>
        <title>
          {post.title.replace(/"/g, "")} {/* Strip quotes */} | Vice City Blog
        </title>
        <meta name="description" content={post.content.substring(0, 150)} />
      </Helmet>

      {/* Banner Image */}
      <img
        src={defaultBanner}
        alt="Blog Banner"
        className={classes.bannerImage}
      />

      <Typography variant="h2" className={classes.postTitle}>
        {post.title.replace(/"/g, "")} {/* Strip quotes */}
      </Typography>

      {/* Render HTML content */}
      <Typography
        variant="body1"
        className={classes.postContent}
        dangerouslySetInnerHTML={{
          __html: post.content.replace(/(>#)/g, ">"), // Remove leading # after any HTML tag
        }}
      />

      {/* Social Icons */}
      <Box className={classes.socialIconsContainer}>
        <Typography variant="h6" className={classes.shareText}>
          Share this post:
        </Typography>
        <Box className={classes.socialIcons}>
          <IconButton
            href="https://medium.com"
            target="_blank"
            className={classes.iconButton}
          >
            <MediumIcon className={classes.icon} />
          </IconButton>
          <IconButton
            href="https://instagram.com"
            target="_blank"
            className={classes.iconButton}
          >
            <InstagramIcon className={classes.icon} />
          </IconButton>
          <IconButton
            href="https://twitter.com"
            target="_blank"
            className={classes.iconButton}
          >
            <TwitterIcon className={classes.icon} />
          </IconButton>
        </Box>
      </Box>

      <Button className={classes.goBackButton} onClick={handleGoBack}>
        Go Back to Blog
      </Button>

      <Footer />
    </Box>
  );
};

export default SinglePostPage;

// CarDetailsModalStyles.js
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  badassModal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    maxWidth: '600px',   // Limit the modal width
    backgroundColor: '#111',
    border: '2px solid #f72585',
    boxShadow: '0 0 40px #f72585, 0 0 60px #23d5ab',
    padding: '30px',
    textAlign: 'center',
    borderRadius: '10px',  // Rounded corners
    zIndex: 1300,
    animation: '$zoomIn 0.5s ease-in-out',  // Zoom-in effect
  },
  modalCarImage: {
    width: '100%',
    maxHeight: '300px',
    objectFit: 'cover',
    borderRadius: '10px',
    marginBottom: '15px',
    boxShadow: '0 0 20px #23d5ab',
  },
  neonText: {
    color: '#f72585',
    fontWeight: 700,
    fontSize: '2rem',  // Adjust the font size for readability
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    marginBottom: '10px',  // Add some spacing after the title
  },
  modalDescription: {
    color: '#fff',
    fontSize: '1.2rem',  // Slightly larger text for readability
    marginBottom: '10px',  // Add some space between description and other elements
  },
  modalPrice: {
    color: '#23d5ab',
    fontSize: '1.5rem',
    fontWeight: 700,
    marginTop: '10px',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
  },
  buttonNeon: {
    color: '#23d5ab',
    border: '2px solid #23d5ab',
    textShadow: '0 0 5px #23d5ab, 0 0 15px #23d5ab',
    '&:hover': {
      backgroundColor: '#f72585',
      borderColor: '#f72585',
      color: '#fff',
    },
    marginTop: '20px',
  },
  '@keyframes zoomIn': {
    '0%': {
      transform: 'scale(0.8)',
      opacity: 0,
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1,
    },
  },

   // Rent Now Button Style
   rentNowButton: {
    backgroundColor: '#f72585',
    color: '#fff',
    fontWeight: 700,
    border: '2px solid #23d5ab',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
    margin: '20px 0',
    padding: '10px 20px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      backgroundColor: '#23d5ab',
      boxShadow: '0 0 40px #23d5ab, 0 0 80px #f72585',
      transform: 'scale(1.05)',  // Slight zoom on hover
    },
  },

}));

export default useStyles;

// AuthPage.jsx
import React, { useEffect, useState } from 'react';
import { Container, Box, Typography, TextField, Button, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import useStyles from './FormStyles';
import logo from '../../assets/logo.png';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

const AuthPage = () => {
  const classes = useStyles();
  const [isLogin, setIsLogin] = useState(true);
  const [isBusiness, setIsBusiness] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    name: '',
    phone: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    businessName: '',
    businessPhone: '',
    businessCity: '',
    businessState: '',
    businessZip: '',
    businessCountry: '',
  });

  const { signup, login, currentUser,loading, authError } = useAuth();
  const navigate = useNavigate();

   // Monitor currentUser state, and if the user is authenticated, navigate to dashboard
   useEffect(() => {
    if (currentUser) {
      navigate('/dashboard');
    }
  }, [currentUser, navigate]);

  const handleFormSwitch = () => setIsLogin(!isLogin);

  const handleUserTypeChange = (event) => setIsBusiness(event.target.value === 'business');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleAuth = async (e) => {
    e.preventDefault();
  
    try {
      if (isLogin) {
        await login(formData.email, formData.password);
        navigate('/dashboard'); // Navigate immediately after login
      } else {
        await signup(formData.email, formData.password, {
          userType: isBusiness ? 'business' : 'personal',
          name: formData.name,
          phone: formData.phone,
          address: {
            city: formData.city,
            state: formData.state,
            zip: formData.zip,
            country: formData.country,
          },
          businessAddress: isBusiness
            ? {
                businessName: formData.businessName,
                businessPhone: formData.businessPhone,
                businessCity: formData.businessCity,
                businessState: formData.businessState,
                businessZip: formData.businessZip,
                businessCountry: formData.businessCountry,
              }
            : null,
        });
        navigate('/dashboard'); // Navigate after successful signup
      }
    } catch (error) {
      console.error('Authentication error:', error);
    }
  };
  

  return (
    <Container className={classes.container}>
      <Box className={classes.formBox}>
        <Box className={classes.logoContainer}>
          <img src={logo} alt="Vice City VIP" className={classes.logo} />
        </Box>

        <Typography variant="h4" className={classes.formTitle}>
          {isLogin ? 'Login' : 'Register'}
        </Typography>

        {!isLogin && (
          <RadioGroup row onChange={handleUserTypeChange} value={isBusiness ? 'business' : 'personal'} className={classes.radioGroup}>
            <FormControlLabel value="personal" control={<Radio />} label="Personal" />
            <FormControlLabel value="business" control={<Radio />} label="Business" />
          </RadioGroup>
        )}

        <form className={classes.form} onSubmit={handleAuth}>
          {!isLogin && (
            <>
              {isBusiness && (
                <>
                  <Typography variant="h6" className={classes.sectionTitle}>Business Information</Typography>
                  <TextField name="businessName" label="Business Name" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
                  <TextField name="businessPhone" label="Business Phone" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
                  <TextField name="businessCity" label="Business City" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
                  <TextField name="businessState" label="Business State" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
                  <TextField name="businessZip" label="Business Zip" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
                  <TextField name="businessCountry" label="Business Country" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
                </>
              )}

              <Typography variant="h6" className={classes.sectionTitle}>Personal Information</Typography>
              <TextField name="name" label="Name" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
              <TextField name="phone" label="Phone" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
              <TextField name="city" label="City" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
              <TextField name="state" label="State" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
              <TextField name="zip" label="Zip" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
              <TextField name="country" label="Country" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
            </>
          )}

          <Typography variant="h6" className={classes.sectionTitle}>Account Information</Typography>
          <TextField name="email" label="Email" onChange={handleInputChange} variant="outlined" fullWidth margin="normal" className={classes.input} />
          <TextField name="password" label="Password" onChange={handleInputChange} type="password" variant="outlined" fullWidth margin="normal" className={classes.input} />

          {authError && <Typography color="error" className={classes.errorText}>{authError}</Typography>}

          <Button type="submit" variant="contained" color="primary" fullWidth className={classes.submitButton}>
            {isLogin ? 'Login' : 'Register'}
          </Button>
        </form>

        <Button color="secondary" className={classes.switchButton} onClick={handleFormSwitch}>
          {isLogin ? "Don't have an account? Register" : 'Already have an account? Login'}
        </Button>
      </Box>
    </Container>
  );
};

export default AuthPage;

// context/CartContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';

// Create context
const CartContext = createContext();

export const CartProvider = ({ children }) => {
  // Initialize cart from localStorage or empty array
  const [cartItems, setCartItems] = useState(() => {
    const savedCart = localStorage.getItem('cartItems');
    return savedCart ? JSON.parse(savedCart) : [];
  });

  // Update localStorage whenever cartItems change
  useEffect(() => {
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
  }, [cartItems]);

  // Function to add an item to the cart only if it's not already present
  const addToCart = (item) => {
    setCartItems((prevItems) => {
      if (prevItems.some(cartItem => cartItem.id === item.id)) {
        return prevItems; // If the item already exists, return the current state (no change)
      }
      return [...prevItems, item]; // Add the item if it doesn't exist
    });
  };

  const removeFromCart = (id) => {
    setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
  };

  const clearCart = () => {
    setCartItems([]);
    localStorage.removeItem('cartItems');
  };

  return (
    <CartContext.Provider
      value={{
        cartItems,
        addToCart,
        removeFromCart,
        clearCart, // Function to clear cart (e.g., after checkout)
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

// Hook to use the CartContext
export const useCart = () => {
  return useContext(CartContext);
};





// import React, { createContext, useContext, useState, useEffect } from 'react';

// // Sample pending rentals data with lead quality score (0-1) and daily rate
// const pendingRentalsData = [
//     {
//       id: 1,
//       customerName: 'John Doe',
//       state: 'California',
//       phone: '1234567890',
//       fromDate: '2024-09-10',
//       toDate: '2024-09-15',
//       numberOfDays: 15,
//       unlockedCount: 0,
//       brand: 'Ferrari',
//       model: '488 Spider',
//       leadQualityScore: 100,
//       dailyRate: 1200,
//       createdAt: new Date(),
//       rentingCity: 'Los Angeles', // Added renting city
//       rentingState: 'California', // Added renting state
//     },
//     {
//       id: 2,
//       customerName: 'Jane Smith',
//       state: 'Texas',
//       phone: '0987654321',
//       fromDate: '2024-09-20',
//       toDate: '2024-09-23',
//       numberOfDays: 3,
//       unlockedCount: 2,
//       brand: 'Lamborghini',
//       model: 'Huracan',
//       leadQualityScore: 65,
//       dailyRate: 1500,
//       createdAt: new Date(Date.now() - 4 * 86400000),
//       rentingCity: 'Dallas', // Added renting city
//       rentingState: 'Texas', // Added renting state
//     },
//     {
//       id: 3,
//       customerName: 'Michael Johnson',
//       state: 'New York',
//       phone: '1239874561',
//       fromDate: '2024-09-12',
//       toDate: '2024-09-18',
//       numberOfDays: 6,
//       unlockedCount: 1,
//       brand: 'Porsche',
//       model: '911 Turbo S',
//       leadQualityScore: 19,
//       dailyRate: 1000,
//       createdAt: new Date(Date.now() - 1 * 86400000),
//       rentingCity: 'New York City', // Added renting city
//       rentingState: 'New York', // Added renting state
//     },
//     {
//       id: 4,
//       customerName: 'Emily Clark',
//       state: 'Florida',
//       phone: '3216549870',
//       fromDate: '2024-09-08',
//       toDate: '2024-09-10',
//       numberOfDays: 2,
//       unlockedCount: 0,
//       brand: 'McLaren',
//       model: '720S',
//       leadQualityScore: 85,
//       dailyRate: 2000,
//       createdAt: new Date(),
//       rentingCity: 'Miami', // Added renting city
//       rentingState: 'Florida', // Added renting state
//     },
//     {
//       id: 5,
//       customerName: 'George Taylor',
//       state: 'Nevada',
//       phone: '5551234567',
//       fromDate: '2024-09-18',
//       toDate: '2024-09-21',
//       numberOfDays: 3,
//       unlockedCount: 3,
//       brand: 'Aston Martin',
//       model: 'DBS',
//       leadQualityScore: 75,
//       dailyRate: 1800,
//       createdAt: new Date(Date.now() - 5 * 86400000),
//       rentingCity: 'Las Vegas', // Added renting city
//       rentingState: 'Nevada', // Added renting state
//     },
//     {
//       id: 6,
//       customerName: 'Sarah Brown',
//       state: 'New Jersey',
//       phone: '4569871230',
//       fromDate: '2024-09-11',
//       toDate: '2024-09-13',
//       numberOfDays: 2,
//       unlockedCount: 0,
//       brand: 'Bugatti',
//       model: 'Chiron',
//       leadQualityScore: 90,
//       dailyRate: 5000,
//       createdAt: new Date(Date.now() - 2 * 86400000),
//       rentingCity: 'Atlantic City', // Added renting city
//       rentingState: 'New Jersey', // Added renting state
//     },
//     {
//       id: 7,
//       customerName: 'Mark Lee',
//       state: 'Colorado',
//       phone: '2223334444',
//       fromDate: '2024-09-22',
//       toDate: '2024-09-24',
//       numberOfDays: 2,
//       unlockedCount: 0,
//       brand: 'Bentley',
//       model: 'Continental GT',
//       leadQualityScore: 55,
//       dailyRate: 3000,
//       createdAt: new Date(Date.now() - 6 * 86400000),
//       rentingCity: 'Denver', // Added renting city
//       rentingState: 'Colorado', // Added renting state
//     },
//   ];
  
// // Create context
// const CartContext = createContext();

// export const CartProvider = ({ children }) => {
//   // Initialize cart from localStorage or empty array
//   const [cartItems, setCartItems] = useState(() => {
//     const savedCart = localStorage.getItem('cartItems');
//     return savedCart ? JSON.parse(savedCart) : [];
//   });

//   // Update localStorage whenever cartItems change
//   useEffect(() => {
//     localStorage.setItem('cartItems', JSON.stringify(cartItems));
//   }, [cartItems]);

//   // Function to add an item to the cart only if it's not already present
//   const addToCart = (item) => {
//     setCartItems((prevItems) => {
//       if (prevItems.some(cartItem => cartItem.id === item.id)) {
//         return prevItems; // If the item already exists, return the current state (no change)
//       }
//       return [...prevItems, item]; // Add the item if it doesn't exist
//     });
//   };

//   const removeFromCart = (id) => {
//     setCartItems((prevItems) => prevItems.filter((item) => item.id !== id));
//   };

//   const clearCart = () => {
//     setCartItems([]);
//     localStorage.removeItem('cartItems');
//   };

//   return (
//     <CartContext.Provider
//       value={{
//         cartItems,
//         addToCart,
//         removeFromCart,
//         clearCart, // Function to clear cart (e.g., after checkout)
//         pendingRentalsData, // Provide pendingRentalsData in the context
//       }}
//     >
//       {children}
//     </CartContext.Provider>
//   );
// };

// // Hook to use the CartContext
// export const useCart = () => {
//   return useContext(CartContext);
// };

import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#000',
    minHeight: '100vh', // Full viewport height
    width: '100vw', // Full viewport width
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start', // Align form to start at the top
    padding: '20px',
    boxSizing: 'border-box',
    zIndex: 1,
    overflowY: 'auto', // Allow page-level scrolling
    '-ms-overflow-style': 'none',  // Hide scrollbar for IE and Edge
    'scrollbar-width': 'none',     // Hide scrollbar for Firefox
    '&::-webkit-scrollbar': {
      display: 'none',             // Hide scrollbar for Chrome, Safari, and Opera
    },
  },
  formBox: {
    backgroundColor: '#111',
    padding: '30px',
    borderRadius: '15px',
    boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585', // Neon glow effect
    maxWidth: '500px',
    width: '100%',
    textAlign: 'center',
    zIndex: 2,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    width: '150px',
    height: 'auto',
    animation: 'neon-glow 2s infinite alternate',
  },
  formTitle: {
    color: '#f72585',
    marginBottom: '20px',
    fontWeight: 'bold',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },
  sectionTitle: {
    color: '#23d5ab',
    marginTop: '15px',
    fontWeight: 'bold',
  },
  input: {
    backgroundColor: '#000',
    color: '#fff',
    marginBottom: '15px',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-outlined': {
      color: '#23d5ab',
    },
    '& .MuiInputLabel-outlined.Mui-focused': {
      color: '#23d5ab',
    },
  },
  submitButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    marginTop: '20px',
    boxShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
    '&:hover': {
      backgroundColor: '#f72585',
      boxShadow: '0 0 20px #f72585, 0 0 30px #23d5ab',
    },
  },
  switchButton: {
    marginTop: '20px',
    color: '#23d5ab',
    textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab',
  },
  errorText: {
    marginTop: '10px',
    color: '#ff0033', // Use a more visible color for error messages
  },
  '@keyframes neon-glow': {
    '0%': {
      filter: 'drop-shadow(0 0 10px #f72585) drop-shadow(0 0 20px #23d5ab)',
    },
    '100%': {
      filter: 'drop-shadow(0 0 20px #f72585) drop-shadow(0 0 40px #23d5ab)',
    },
  },
  '@media (max-width: 600px)': {
    formBox: {
      padding: '20px',
      overflowY: 'visible', // Prevent internal scrollbars in mobile view
    },
    input: {
      marginBottom: '10px',
    },
  },
}));

export default useStyles;


import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '50px',
    textAlign: 'center',
    backgroundColor: '#111',
    color: '#fff',
    borderRadius: '15px',
    boxShadow: '0 0 60px #f72585, 0 0 80px #23d5ab',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '50px',
  },
  title: {
    color: '#23d5ab',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
  },
  leadCost: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
  },
  textField: {
    backgroundColor: '#222',
    '& .MuiInputBase-input': {
      color: '#fff',
    },
    '& .MuiInputLabel-root': {
      color: '#23d5ab',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#f72585',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#23d5ab',
      },
    },
  },
  confirmButton: {
    marginTop: '20px',
    backgroundColor: '#23d5ab',
    boxShadow: '0 0 20px #23d5ab',
    '&:hover': { backgroundColor: '#1cb993' },
    padding: '10px 20px',
    fontSize: '1.2rem',
  },
  loader: {
    color: '#23d5ab',
    marginTop: '20px',
  },
  modalBox: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '450px',
    backgroundColor: '#141414',
    color: '#fff',
    borderRadius: '15px',
    padding: '40px',
    boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab',
    textAlign: 'center',
    animation: '$modalGlow 2s infinite alternate',
  },
  modalTitle: {
    color: '#23d5ab',
    fontSize: '1.8rem',
    fontWeight: 'bold',
    textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585',
  },
  modalText: {
    color: '#fff',
    marginBottom: '10px',
    fontSize: '1.1rem',
    textShadow: '0 0 10px rgba(255, 255, 255, 0.2)',
  },
  modalLeadCost: {
    color: '#f72585',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    textShadow: '0 0 15px #f72585, 0 0 30px #23d5ab',
  },
  copyButton: {
    backgroundColor: '#23d5ab',
    marginTop: '20px',
    boxShadow: '0 0 20px #23d5ab',
    '&:hover': { backgroundColor: '#1cb993' },
    padding: '12px 20px',
    fontSize: '1.1rem',
    borderRadius: '8px',
  },
  closeButton: {
    backgroundColor: '#f72585',
    marginTop: '10px',
    marginLeft: '10px',
    boxShadow: '0 0 20px #f72585',
    '&:hover': { backgroundColor: '#f50057' },
    padding: '12px 20px',
    fontSize: '1.1rem',
    borderRadius: '8px',
  },
  '@keyframes modalGlow': {
    '0%': { boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585' },
    '100%': { boxShadow: '0 0 40px #f72585, 0 0 80px #23d5ab' },
  },
  disclaimerBox: {
    marginTop: '30px',
    padding: '20px',
    backgroundColor: '#222',
    borderRadius: '10px',
    boxShadow: '0 0 20px #f72585, 0 0 40px #23d5ab',
  },
  disclaimerTitle: {
    color: '#f72585',
    textShadow: '0 0 10px #f72585',
  },
  disclaimerText: {
    color: '#fff',
    textShadow: '0 0 10px #23d5ab',
  },
  exclusiveOption: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(1),
  },
  infoIcon: {
    marginLeft: theme.spacing(1), // Adds some space between the checkbox label and info icon
  },
}));

export default useStyles;

// CalendarModal.js



import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Backdrop, Fade } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useNavigate } from 'react-router-dom';
import useStyles from './CalendarModalStyles';

const CalendarModal = ({ open, handleClose, selectedCar }) => {
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const navigate = useNavigate();

  const handleConfirm = () => {
    // Pass selected car and rental period data to the CustomerInfoPage
    navigate('/customer-info', {
      state: {
        selectedCar,  // Pass selected car details
        startDate,    // Pass selected start date
        endDate,      // Pass selected end date
      },
    });
    handleClose();
  };

  const handleSkip = () => {
    // Skip selecting dates and pass only the car details to the customer info page
    navigate('/customer-info', {
      state: {
        selectedCar,  // Pass only selected car details
      },
    });
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <Box className={classes.calendarModal}>
          <Typography variant="h4" className={classes.neonText}>
            Select Your Rental Period
          </Typography>

          <Box className={classes.dateInputBox}>
            <Typography className={classes.neonText} variant="h6">
              From
            </Typography>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              className={classes.dateInput}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select start date"
            />
          </Box>

          <Box className={classes.dateInputBox}>
            <Typography className={classes.neonText} variant="h6">
              To
            </Typography>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              className={classes.dateInput}
              dateFormat="MMMM d, yyyy"
              placeholderText="Select end date"
              minDate={startDate} // Ensure the end date is after the start date
            />
          </Box>

          <Button
            className={classes.confirmButton}
            size="large"
            onClick={handleConfirm}
            disabled={!startDate || !endDate} // Disable if dates aren't selected
          >
            Confirm Dates
          </Button>

          <Button
            className={classes.skipButton}
            size="large"
            onClick={handleSkip}
          >
            Skip Dates
          </Button>

          <Button className={classes.closeButton} size="large" onClick={handleClose}>
            Close
          </Button>
        </Box>
      </Fade>
    </Modal>
  );
};

export default CalendarModal;

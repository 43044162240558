import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  pageContainer: {
    backgroundColor: '#111', // Dark background
    color: '#fff',
    padding: '40px',
    borderRadius: '12px',
    textAlign: 'center',
    width: '100%',
    maxWidth: '600px',
    margin: 'auto',
    marginTop: '50px',
    boxShadow: '0px 0px 50px 10px rgba(35,213,171,0.8)', // Stronger neon glow effect
    '@media (max-width: 768px)': {
      padding: '20px',
      marginTop: '20px',
    },
    '@media (max-width: 480px)': {
      padding: '15px',
      marginTop: '15px',
    },
  },
  neonText: {
    color: '#23d5ab',
    textShadow: '0 0 15px #23d5ab, 0 0 30px #23d5ab', // Stronger neon glow for text
    marginBottom: '20px',
    fontSize: '2rem',
    '@media (max-width: 768px)': {
      fontSize: '1.5rem',
    },
    '@media (max-width: 480px)': {
      fontSize: '1.2rem',
    },
  },
  textField: {
    marginBottom: '20px',
    width: '100%',
    '& label': {
      color: '#23d5ab',
    },
    '& input': {
      color: '#fff',
      backgroundColor: '#222', // Dark input background for better contrast
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab', // Neon border
      },
      '&:hover fieldset': {
        borderColor: '#1cb993', // Darker neon on hover
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1cb993', // Neon color when focused
      },
    },
    '@media (max-width: 768px)': {
      marginBottom: '15px',
    },
    '@media (max-width: 480px)': {
      marginBottom: '10px',
    },
  },
  formControlLabel: {
    color: '#23d5ab',
    '& .MuiFormControlLabel-label': {
      color: '#23d5ab', // Neon color for the labels
    },
    '@media (max-width: 480px)': {
      fontSize: '0.8rem',
    },
  },
  checkboxGroup: {
    display: 'flex',
    justifyContent: 'center',
    gap: '30px',
    marginBottom: '20px',
    flexWrap: 'wrap',
    '@media (max-width: 768px)': {
      gap: '20px',
    },
    '@media (max-width: 480px)': {
      gap: '10px',
    },
  },
  checkbox: {
    color: '#23d5ab', // Neon for checkboxes
    '&.Mui-checked': {
      color: '#1cb993', // Change color when checked
    },
  },
  buttonContainer: {
    marginTop: '30px',
    '@media (max-width: 768px)': {
      marginTop: '20px',
    },
    '@media (max-width: 480px)': {
      marginTop: '15px',
    },
  },
  confirmButton: {
    backgroundColor: '#23d5ab',
    color: '#fff',
    fontSize: '1.2rem',
    padding: '10px 20px',
    '&:hover': {
      backgroundColor: '#1cb993',
    },
    boxShadow: '0px 0px 15px 5px rgba(35,213,171,0.5)', // Neon glow for buttons
    '@media (max-width: 768px)': {
      fontSize: '1rem',
      padding: '8px 16px',
    },
    '@media (max-width: 480px)': {
      fontSize: '0.9rem',
      padding: '6px 12px',
    },
  },
  addressFields: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: '15px',
    marginBottom: '20px',
    '@media (max-width: 480px)': {
      flexDirection: 'column',
      gap: '10px',
    },
  },
  textFieldSmall: {
    flex: 1,
    '& label': {
      color: '#23d5ab',
    },
    '& input': {
      color: '#fff',
      backgroundColor: '#222', // Matching dark input background
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#23d5ab',
      },
      '&:hover fieldset': {
        borderColor: '#1cb993',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#1cb993',
      },
    },
    '@media (max-width: 768px)': {
      marginBottom: '15px',
    },
    '@media (max-width: 480px)': {
      marginBottom: '10px',
    },
  },
  link: {
    color: '#23d5ab', // Neon color for the link
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: '#1cb993', // Darker neon on hover
    },
  },
  logo: {
    display: 'block',
    margin: '0 auto 30px auto', // Center the logo and add margin at the bottom
    width: '150px', // Set a base width for the logo
    height: 'auto', // Ensure the logo scales correctly
    '@media (max-width: 768px)': {
      width: '120px', // Adjust the size for tablets
    },
    '@media (max-width: 480px)': {
      width: '100px', // Adjust the size for mobile
      marginBottom: '20px', // Reduce bottom margin for smaller screens
    },
  },
  
}));

export default useStyles;

import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { thirdRealtimeDb } from '../firebase'; // Import Firebase configuration

const useBlogPosts = () => {
  const [blogPosts, setBlogPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const postsRef = ref(thirdRealtimeDb, 'articles'); // Fetch blog posts from the 'articles' node

    const unsubscribe = onValue(postsRef, (snapshot) => {
      const data = snapshot.val();
      const postsArray = data
        ? Object.keys(data).map((key) => ({ key, ...data[key] })) // Use the Firebase key for routing
        : [];
      setBlogPosts(postsArray);
      setLoading(false);
    });

    return () => unsubscribe(); // Clean up listener when component unmounts
  }, []);

  return { blogPosts, loading };
};

export default useBlogPosts;

import React from 'react';
import { Box, Typography, Grid, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Navigation hook
import useStyles from './AboutUsStyles'; // Custom styles for the screen

const AboutUsScreen = () => {
  const classes = useStyles();
  const navigate = useNavigate(); // Initialize navigation

  const handleNavigateToHowWeWork = () => {
    navigate('/how-we-work'); // Navigate to the "How We Work" page
  };

  return (
    <Box className={classes.root}>
      <Typography variant="h3" className={classes.neonText}>
        About Vice City VIP
      </Typography>

      {/* Expanded Content */}
      <Grid container spacing={4} className={classes.contentContainer}>
        {/* Our Mission */}
        <Grid item xs={12} md={6}>
          <Box className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Our Mission
            </Typography>
            <Typography variant="body1" className={classes.sectionText}>
              At Vice City VIP, we provide an exclusive luxury experience for individuals who live life in the fast lane. With access to the world’s most exotic cars, yachts, and luxury properties, we ensure that you make a lasting impression, wherever you go.
            </Typography>
          </Box>
        </Grid>

        {/* Our Vision */}
        <Grid item xs={12} md={6}>
          <Box className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Our Vision
            </Typography>
            <Typography variant="body1" className={classes.sectionText}>
              We envision a world where luxury is accessible to those who seek it. At Vice City VIP, we are driven to be the leading platform for exclusive luxury rentals, revolutionizing the way people access the most luxurious experiences around the globe.
            </Typography>
          </Box>
        </Grid>

        {/* Our Values */}
        <Grid item xs={12} md={6}>
          <Box className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Our Values
            </Typography>
            <Typography variant="body1" className={classes.sectionText}>
              Integrity, Innovation, and Excellence. These are the core values that define Vice City VIP. We strive to deliver exceptional experiences to every client, staying at the forefront of luxury and technological innovation.
            </Typography>
          </Box>
        </Grid>

        {/* Join the Experience */}
        <Grid item xs={12} md={6}>
          <Box className={classes.section}>
            <Typography variant="h5" className={classes.sectionTitle}>
              Join the Experience
            </Typography>
            <Typography variant="body1" className={classes.sectionText}>
              Whether you're looking to rent or lease your own luxury vehicles, properties, or yachts, Vice City VIP offers an unparalleled platform to succeed. We connect you with an elite network, empowering you to rule the moment.
            </Typography>
          </Box>
        </Grid>
      </Grid>

      {/* Navigate to How We Work */}
      <Button className={classes.ctaButton} onClick={handleNavigateToHowWeWork}>
        How We Work
      </Button>
    </Box>
  );
};

export default AboutUsScreen;

// PendingRentalsStyles.js
import { styled, keyframes } from '@mui/system';
import { Box, Typography, ListItem, Divider, Paper } from '@mui/material';

// Custom styles using Material-UI's `styled`
export const StyledBox = styled(Box)({
  backgroundColor: '#111', // Dark background
  padding: '20px',
  borderRadius: '10px',
  boxShadow: '0 0 20px #23d5ab, 0 0 40px #f72585', // Neon shadow
  color: '#fff',
  marginTop: '20px',
});

export const StyledListItem = styled(ListItem)({
  backgroundColor: '#1a1a1a',
  borderRadius: '8px',
  boxShadow: '0 0 10px #23d5ab',
  marginBottom: '10px',
  transition: 'transform 0.3s ease',
  cursor: 'pointer', // Mouse pointer for clickable items
  '&:hover': {
    transform: 'scale(1.05)', // Slight hover effect
    boxShadow: '0 0 20px #f72585', // Hover neon effect
  },
});

export const StyledDivider = styled(Divider)({
  backgroundColor: '#23d5ab',
});

export const NeonText = styled(Typography)({
  color: '#23d5ab', // Neon green
  textShadow: '0 0 10px #23d5ab, 0 0 20px #f72585', // Neon glow effect
  marginBottom: '10px',
});

export const SubText = styled(Typography)({
  color: '#f72585', // Neon pink
  textShadow: '0 0 10px #f72585, 0 0 20px #23d5ab', // Subheading neon effect
  marginBottom: '20px',
});

export const ModalContent = styled(Paper)({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '400px',
  backgroundColor: '#111',
  border: '2px solid #f72585',
  boxShadow: '0 0 20px #23d5ab',
  padding: '20px',
  textAlign: 'center',
  color: '#fff',
});


// Keyframe animation for glowing effect
const glowAnimation = keyframes`
  0% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
  }
  100% {
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.2);
  }
`;

// Lead quality badge styling with dynamic colors and glow effect
export const LeadQualityBadge = styled(Typography)(({ quality }) => ({
  fontWeight: 'bold',
  textTransform: 'uppercase',
  padding: '5px 10px',
  borderRadius: '5px',
  display: 'inline-block',
  animation: `${glowAnimation} 1.5s ease-in-out infinite`,
  ...(quality === 'High' && {
    backgroundColor: '#23d5ab', // Neon green
    color: '#000',
    textShadow: '0 0 10px #23d5ab',
    boxShadow: '0 0 15px #23d5ab',
  }),
  ...(quality === 'Medium' && {
    backgroundColor: '#f7b500', // Neon yellow
    color: '#000',
    textShadow: '0 0 10px #f7b500',
    boxShadow: '0 0 15px #f7b500',
  }),
  ...(quality === 'Low' && {
    backgroundColor: '#f72585', // Neon pink
    color: '#fff',
    textShadow: '0 0 10px #f72585',
    boxShadow: '0 0 15px #f72585',
  }),
  transition: 'transform 0.2s ease-in-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));
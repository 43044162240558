// CarDetailsModal.js


import React, { useState } from 'react';
import { Modal, Box, Typography, Button, Backdrop, Fade } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './CarDetailsModalStyles';
import CalendarModal from './CalendarModal';

const CarDetailsModal = ({ open, handleClose, selectedCar }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [calendarOpen, setCalendarOpen] = useState(false);

  const defaultImage = `${process.env.PUBLIC_URL}/logo.png`;

  // Function to open the calendar modal
  const handleCalendarOpen = () => {
    setCalendarOpen(true);
  };

  // Function to close the calendar modal
  const handleCalendarClose = () => {
    setCalendarOpen(false);
  };

  
//   // Navigate to the vehicle listing page
  const navigateToListing = (e) => {
    e.stopPropagation();
    if (!e.target.classList.contains('rentNowButton')) {
      navigate(`/vehicle/${selectedCar.id}`, { state: { car: selectedCar } }); // Pass car details via state
    }
  };

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
        <Box className={classes.badassModal} onClick={navigateToListing}>
            <Typography variant="h4" className={classes.neonText}>
              {selectedCar?.make || 'Car'} {selectedCar?.model || 'Details'}
            </Typography>
            <img
              src={selectedCar?.imageUrls?.[0] || defaultImage}
              alt={`${selectedCar?.make || 'Car'} ${selectedCar?.model || 'Image'}`}
              className={classes.modalCarImage}
            />
            <Typography className={classes.modalDescription}>
              {selectedCar?.description || 'Description not available'}
            </Typography>
            <Typography className={classes.modalPrice}>
              {selectedCar?.pricePerDay ? `$${selectedCar?.pricePerDay} / day` : 'Price not available'}
            </Typography>

            <Box className={classes.additionalInfo}>
              <Typography variant="body1" className={classes.neonText}>
                Delivery Offered: {selectedCar?.deliveryOffered ? 'Yes' : 'No'}
              </Typography>
              <Typography variant="body1" className={classes.neonText}>
                Location: {selectedCar?.city || 'Unknown city'}, {selectedCar?.state || 'Unknown state'}
              </Typography>
            </Box>

            <Button
              className={classes.rentNowButton}
              size="large"
              onClick={(e) => {
                e.stopPropagation();
                handleCalendarOpen();
              }}
            >
              Rent Now
            </Button>

            <Button
              className={classes.buttonNeon}
              size="large"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              Close
            </Button>
          </Box>
        </Fade>
      </Modal>

      {/* Pass car details to CalendarModal */}
      <CalendarModal
        open={calendarOpen}
        handleClose={handleCalendarClose}
        selectedCar={selectedCar} // Pass car details to the CalendarModal
      />
    </>
  );
};

export default CarDetailsModal;



// import React, { useState } from 'react';
// import { Modal, Box, Typography, Button, Backdrop, Fade } from '@mui/material';
// import { useNavigate } from 'react-router-dom'; // For navigation to vehicle listing
// import useStyles from './CarDetailsModalStyles'; // Import new styles
// import CalendarModal from './CalendarModal'; // Import CalendarModal

// const CarDetailsModal = ({ open, handleClose, selectedCar }) => {
//   const classes = useStyles();
//   const navigate = useNavigate(); // Use navigate to handle routing
//   const [calendarOpen, setCalendarOpen] = useState(false); // State to handle calendar modal

//   // Function to open the calendar modal
//   const handleCalendarOpen = () => {
//     setCalendarOpen(true);
//   };

//   // Function to close the calendar modal
//   const handleCalendarClose = () => {
//     setCalendarOpen(false);
//   };

//   // Navigate to the vehicle listing page
//   const navigateToListing = () => {
//     if (selectedCar) {
//       navigate(`/vehicle/${selectedCar.id}`); // Navigate to vehicle listing page
//       handleClose(); // Close the modal after navigating
//     }
//   };

//   console.log('Selected Car:', selectedCar); // Debugging line

//   return (
//     <>
//       <Modal
//         open={open}
//         onClose={handleClose}
//         closeAfterTransition
//         BackdropComponent={Backdrop}
//         BackdropProps={{
//           timeout: 500,
//         }}
//       >
//         <Fade in={open}>
//           <Box className={classes.badassModal} onClick={navigateToListing}>
//             <Typography variant="h4" className={classes.neonText}>
//               {selectedCar?.name || 'Car Name'}
//             </Typography>
//             <img src={selectedCar?.image || `${process.env.PUBLIC_URL}/logo.png`} alt={selectedCar?.name || 'Car Image'} className={classes.modalCarImage} />
//             <Typography className={classes.modalDescription}>{selectedCar?.description || 'Description not available'}</Typography>
//             <Typography className={classes.modalPrice}>{selectedCar?.price || 'Price not available'}</Typography>

//             {/* Additional Information */}
//             <Box className={classes.additionalInfo}>
//               <Typography variant="body1" className={classes.neonText}>
//                 Allowed Mileage: {selectedCar?.mileage || 'Unlimited'} miles/day
//               </Typography>
//               <Typography variant="body1" className={classes.neonText}>
//                 Delivery/Pickup: {selectedCar?.delivery ? 'Included' : 'Not Included'}
//               </Typography>
//               <Typography variant="body1" className={classes.neonText}>
//                 Location: {selectedCar?.city || 'Unknown'}, {selectedCar?.state || 'Unknown'}
//               </Typography>
//             </Box>

//             {/* Rent Now Button to open the calendar modal */}
//             <Button className={classes.rentNowButton} size="large" onClick={(e) => { 
//               e.stopPropagation(); // Prevents navigation on button click
//               handleCalendarOpen(); 
//             }}>
//               Rent Now
//             </Button>

//             {/* Close Button */}
//             <Button className={classes.buttonNeon} size="large" onClick={(e) => { 
//               e.stopPropagation(); // Prevents navigation on button click
//               handleClose(); 
//             }}>
//               Close
//             </Button>
//           </Box>
//         </Fade>
//       </Modal>

//       {/* Calendar Modal */}
//       <CalendarModal open={calendarOpen} handleClose={handleCalendarClose} />
//     </>
//   );
// };

// export default CarDetailsModal;

import React from 'react';
import { Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, Toolbar } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import MenuIcon from '@mui/icons-material/Menu'; // Icon for opening the drawer
import { useMediaQuery } from '@mui/material';

// Icons for leads
import DashboardIcon from '@mui/icons-material/Dashboard';
import PendingIcon from '@mui/icons-material/HourglassEmpty'; // Pending Leads icon
import CancelIcon from '@mui/icons-material/Cancel'; // Declined Leads icon
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'; // Approved Leads icon
import SettingsIcon from '@mui/icons-material/Settings'; // Account Settings icon

const drawerWidth = 240;

const DrawerStyled = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    backgroundColor: '#111', // Dark background for the drawer
    color: '#fff', // White text for the drawer
    boxShadow: '0 0 15px #f72585', // Neon pink shadow
    transition: 'transform 0.5s ease', // Smooth open/close transition
  },
}));

const CustomDrawer = ({
  mobileOpen,
  drawerOpen,
  handleDrawerToggle,
  handleDrawerOpenClose,
  selectedMenuItem,
  setSelectedMenuItem,
  userType,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); // Check if it's mobile screen

  const menuItems = [
    { text: 'Dashboard Overview', value: 'overview', icon: <DashboardIcon sx={{ fontSize: 24, color: '#23d5ab' }} /> },
    ...(userType === 'business'
      ? [
          { text: 'Pending Leads', value: 'pending', icon: <PendingIcon sx={{ fontSize: 24, color: '#23d5ab' }} /> },
          { text: 'Declined Leads', value: 'declined', icon: <CancelIcon sx={{ fontSize: 24, color: '#23d5ab' }} /> },
          { text: 'Approved Leads', value: 'history', icon: <AssignmentTurnedInIcon sx={{ fontSize: 24, color: '#23d5ab' }} /> },
        ]
      : []), // Only business users will see the Pending, Declined, and Approved Leads
    { text: 'Account Settings', value: 'settings', icon: <SettingsIcon sx={{ fontSize: 24, color: '#23d5ab' }} /> },
  ];

  const drawerContent = (
    <List>
      {menuItems.map((item) => (
        <ListItem
          button
          key={item.value}
          onClick={() => setSelectedMenuItem(item.value)}
          selected={selectedMenuItem === item.value}
          sx={{
            color: selectedMenuItem === item.value ? '#f72585' : '#23d5ab', // Highlight color for selected menu item
            textShadow: '0 0 10px #23d5ab', // Neon glow for non-selected items
            cursor: 'pointer', // Highlight cursor for mouse over
            '&:hover': {
              backgroundColor: '#333', // Darker background on hover
              color: '#f72585', // Pink neon glow on hover
              textShadow: '0 0 10px #f72585', // Glow for hovered items
            },
            transition: 'all 0.3s ease', // Smooth transition effect
          }}
        >
          <ListItemIcon>
            {React.cloneElement(item.icon, {
              sx: { fontSize: 24, color: selectedMenuItem === item.value ? '#f72585' : '#23d5ab' },
            })}
          </ListItemIcon>
          <ListItemText primary={item.text} />
        </ListItem>
      ))}
    </List>
  );

  return (
    <>
      {/* Only show this button on mobile to open the drawer */}
      {isMobile && (
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: '#f72585', position: 'absolute', top: 10, left: 10, zIndex: 2000 }}
        >
          <MenuIcon />
        </IconButton>
      )}

      {/* Temporary Drawer for Mobile */}
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: drawerWidth,
            backgroundColor: '#111', // Apply dark theme for mobile
            color: '#fff', // White text
            boxShadow: '0 0 15px #f72585', // Neon pink shadow
          },
        }}
      >
        <IconButton
          onClick={handleDrawerToggle}
          sx={{ color: '#f72585', position: 'absolute', top: 10, right: 10 }}
        >
          <ChevronLeftIcon />
        </IconButton>
        {drawerContent}
      </Drawer>

      {/* Persistent Drawer for larger screens */}
      <DrawerStyled
        variant="persistent"
        open={drawerOpen}
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { width: drawerWidth },
        }}
      >
        <Toolbar /> {/* This ensures the content starts after the AppBar height */}
        {drawerContent}
      </DrawerStyled>
    </>
  );
};

export default CustomDrawer;


// BlogPage.js
import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Pagination } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useStyles from './BlogPageStyles';
import useBlogPosts from '../../hooks/useBlogPosts'; // Custom hook to fetch blog posts from Firebase
import Footer from '../footer/Footer';

const BlogPage = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const defaultImage = `${process.env.PUBLIC_URL}/banner.webp`; // Default image for blog posts

  // Fetch blog posts using the custom hook
  const { blogPosts, loading } = useBlogPosts();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 3;

  // Handle pagination page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle "Read More" navigation using the Firebase key
  const handleReadMore = (postKey) => {
    navigate(`/blog/${postKey}`); // Use the Firebase key for routing
  };

  if (loading) {
    return <Typography variant="h4">Loading...</Typography>; // Show a loading message
  }

  // Calculate the current posts for pagination
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = blogPosts.slice(indexOfFirstPost, indexOfLastPost);

  return (
    <Box className={classes.blogContainer}>
      <Typography variant="h2" className={classes.blogTitle}>
        Welcome to the Vice City Blog
      </Typography>
      <Typography variant="h5" className={classes.blogSubtitle}>
        Dive into the world of luxury and exotic cars
      </Typography>

      {/* Blog Posts Grid */}
      <Box className={classes.blogGrid}>
        {currentPosts.map((post) => (
          <Card
            key={post.key} // Use Firebase key
            sx={{ backgroundColor: "#555", cursor: "pointer" }} // Add cursor to indicate the card is clickable
            onClick={() => handleReadMore(post.key)} // Use the Firebase key for routing
            classes={{ root: classes.blogCard }}
          >
            <img src={defaultImage} alt={post.title} className={classes.blogImage} />
            <CardContent>
              <Typography variant="h5" className={classes.cardTitle}>
              {post.title.replace(/"/g, '')} {/* Strip quotes */}
              </Typography>

              <Typography variant="caption" className={classes.cardDate}>
                {post.date}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>

      {/* Pagination */}
      <Box mt={4} display="flex" justifyContent="center">
        <Pagination
          count={Math.ceil(blogPosts.length / postsPerPage)}
          page={currentPage}
          onChange={handlePageChange}
          color="primary"
          sx={{
            "& .MuiPaginationItem-root": {
              color: "#f72585", // Set pagination item color
            },
            "& .Mui-selected": {
              backgroundColor: "#23d5ab", // Set selected page background color
              color: "#000", // Set selected page text color
            },
          }}
        />
      </Box>

      <Footer />
    </Box>
  );
};

export default BlogPage;


